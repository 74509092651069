const initialState = () => {
  return {
    oppId: null,
    accountId: null,
    crm: 'sf'
  }
}

export default {
  state: initialState(),

  getters: {
    crm (state, getters, rootState) {
      return rootState.account.crm
    }
  },

  mutations: {
    SET_OPP_ID (state, oppId) {
      state.oppId = oppId
    },

    SET_ACCOUNT_ID (state, accountId) {
      state.accountId = accountId
    },

    SET_CRM (state) {
      state.crm = 'odoo'
    }
  },

  actions: {
    setOppId ({ commit }, oppId) {
      commit('SET_OPP_ID', oppId)
    },

    setAccountId ({ commit }, accountId) {
      commit('SET_ACCOUNT_ID', accountId)
    },

    setCRM ({ commit }) {
      commit('SET_CRM')
    }
  }
}
