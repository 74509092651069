<template>
  <div class="app-progress-circles">
    <div
      v-for="(step, stepIndex) in circles"
      :key="stepIndex"
      class="app-progress-circles__step"
      :class="{'app-progress-circles__step--filled': stepIndex === index}"
    />
  </div>
</template>

<script>
// import postAppProgress from '@/mixins/post-app-progress'

export default {
  // Name
  name: 'AppProgressCircles',

  // Props
  props: {
    index: {
      type: Number,
      default: 0
    },
    circles: {
      type: Number,
      default: 2
    }
  }
}
</script>

<style scoped lang="scss">
.app-progress-circles {
  display: flex;
  justify-content: center;
  margin: 45px 0;

  @screen sm {
    margin: 50px 0;
  }

  &__step {
    width: 7px;
    height: 7px;
    margin: 0 4px;
    background-color: theme('colors.white');
    border-radius: 50%;
    border: 1px solid theme('colors.gray.300');
    transition: all 150ms ease-in;

    &--filled {
      border-color: transparent;
      background-color: theme('colors.green.400');
    }
  }
}
</style>
