import AppShellLoans from '@/components/application/AppShellLoans'
import preventRefresh from '@/router/utils/prevent-refresh'
import * as ROUTE_PATHS from '../../constants/route-paths'
import * as PROGRESS_LABELS from '../../constants/progress-label'

const IsFoundationCustomer = () => import(
  /* webpackChunkName: "evergreen-v1" */
  '@/components/steps/evergreen/IsFoundationCustomer'
)

const ApplicationSuccessful = () => import(
  /* webpackChunkName: "evergreen-v1" */
  '@/components/steps/evergreen/ApplicationSuccessful'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */
const appName = 'springevergreen'
const leadType = 'Evergreen'
const variation = 'v1'
const basePath = '/evergreen-loan'

const footerActionSettings = {
  showFooter: true,
  showAppButton: true
}

const routes = [
  {
    path: basePath,
    component: AppShellLoans,
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    props: {
      theme: 'green',
      module: 'evergreen-loan'
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: IsFoundationCustomer,
        meta: {
          nextStep: ROUTE_PATHS.APPLY,
          index: 1,
          progressLabel: PROGRESS_LABELS.THREE_MINUTES,
          ...footerActionSettings
        }
      },
      {
        path: ROUTE_PATHS.APPLY,
        component: ApplicationSuccessful,
        meta: {
          index: 2,
          disableGridLayout: true,
          progressLabel: PROGRESS_LABELS.FINISHED
        },
        props: {
          title: 'You’re well on your way to securing an Evergreen Loan.'
        }
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
