function getElementY (scroller, element, offset = 0) {
  if (!element) return
  const pos = element.getBoundingClientRect().top
  let scrollY

  if (scroller === window) {
    scrollY = window.scrollY
  } else {
    scrollY = scroller.scrollTop
  }

  return scrollY + pos - offset
}

const easing = (t) => {
  // cubic bezier function copied from https://gist.github.com/gre/1650294
  return t < 0.5 ? 4 * t * t * t
    : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

const step = (timestamp, scroller, duration, startingY, diff) => {
  let start

  (function func (timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    const time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)
    percent = easing(percent)
    scroller.scrollTo(0, startingY + diff * percent)

    if (time < duration) {
      window.requestAnimationFrame(func)
    }
  })(timestamp)
}

const scroll = {
  methods: {
    $_scroll_toElement (query, scroller = window, duration = 1000) {
      let startingY, targetY, diff

      const offset = scroller === window ? 200 : 0
      const element = document.querySelector(query)
      const elementY = getElementY(scroller, element, offset)

      if (scroller === window) {
        const scrollHeight = document.body.scrollHeight
        const innerHeight = window.innerHeight
        startingY = window.scrollY
        // If element is close to page's bottom then window will
        // scroll only to some position above the element.
        targetY = scrollHeight - elementY < innerHeight
          ? scrollHeight - innerHeight
          : elementY
        diff = targetY - startingY
      } else {
        const scrollerPos = scroller.getBoundingClientRect().top
        startingY = scroller.scrollTop
        diff = elementY - startingY - scrollerPos
      }

      if (!diff) return

      // Bootstrap our animation - it will get called right
      // before next frame shall be rendered.
      window.requestAnimationFrame(timestamp => step(timestamp, scroller, duration, startingY, diff))
    },

    $_scroll_toActiveElement (duration = 1000) {
      let startingY, targetY, diff

      const offset = 100
      const element = document.activeElement
      const elementY = getElementY(window, element, offset)

      const scrollHeight = document.body.scrollHeight
      const innerHeight = window.innerHeight
      startingY = window.scrollY
      // If element is close to page's bottom then window will
      // scroll only to some position above the element.
      targetY = scrollHeight - elementY < innerHeight
        ? scrollHeight - innerHeight
        : elementY
      diff = targetY - startingY

      if (!diff) return

      // Bootstrap our animation - it will get called right
      // before next frame shall be rendered.
      window.requestAnimationFrame(timestamp => step(timestamp, window, duration, startingY, diff))
    },

    $_scroll_elementIntoView (query) {
      const element = document.querySelector(query)
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }
  }
}

export default scroll
