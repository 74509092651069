<template>
  <div class="app-shell">
    <AppBackground :module="module" />
    <!-- Hidden Fields (SID, AID, app_source, etc) -->
    <AppHiddenFields />

    <div class="app-shell__main">
      <!-- App Nav -->
      <SpringNav
        :logo="require('@/assets/img/nav/logo.svg')"
        :easy-logo="require('@/assets/img/nav/easy-spring-logos.svg')"
        target="_blank"
        update-lendful-disclosure
      />

      <!-- Progress Bar -->
      <AppProgressBar
        v-if="$_progress_visible"
        :theme="theme"
        :progress-label="$_progress_label"
        :total-steps="$_progress_totalSteps"
        :current-step-index="$_progress_currentStepIndex"
      />
      <!-- Router View -->
      <AppRouterViewContainer>
        <transition
          :name="transitionName"
          mode="out-in"
          @after-enter="$_stickyCTA_afterEnter"
          @before-leave="$_stickyCTA_beforeLeave"
        >
          <router-view :theme="theme" />
        </transition>

        <!-- Post-Submit Progress Circles -->
        <AppProgressCircles
          v-if="progressCirclesVisiblie"
          :index="postSubmitIndex"
          :circles="progressCircleCount"
        />
        <!-- Sticky CTA -->
        <StepFooter
          v-if="$_stickyCTA_showFooter"
          fixed
        >
          <StepStickyCTA
            :theme="theme"
            :continue-short-circuit="preventContinue"
          />
        </StepFooter>
      </AppRouterViewContainer>
    </div>

    <!-- App Footer -->
    <AppFooter :theme="theme" />
  </div>
</template>

<script>
import AppFooter from '@/components/layout/AppFooter'
import { SpringNav } from 'spring-ui'
import AppProgressBar from '@/components/common/progress/AppProgressBar'
import AppProgressCircles from '@/components/common/progress/AppProgressCircles'
import AppHiddenFields from '@/components/common/fields/AppHiddenFields'
import progress from '@/mixins/progress'
import stickyCTA from '@/mixins/sticky-cta'
import confirmationNumber from '@/mixins/confirmation-number'
import tracking from '@/mixins/tracking'
import transitions from '@/mixins/transitions'
import navigation from '@/mixins/navigation'
import AppBackground from '@/components/common/background/AppBackground'
import StepFooter from '@/components/common/step-layout/StepFooter'
import AppRouterViewContainer from '@/components/common/AppRouterViewContainer'
import StepStickyCTA from '@/components/common/step-layout/StepStickyCTA'

export default {
  // Name
  name: 'AppShell',

  // Components
  components: {
    AppFooter,
    SpringNav,
    AppProgressBar,
    AppProgressCircles,
    AppHiddenFields,
    AppBackground,
    StepFooter,
    AppRouterViewContainer,
    StepStickyCTA
  },

  // Mixins
  mixins: [
    confirmationNumber,
    progress,
    transitions,
    stickyCTA,
    tracking,
    navigation
  ],

  props: {
    theme: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      default: ''
    },
    canonicalModule: {
      type: String,
      default: ''
    }
  },

  head () {
    return {
      link: [
        { rel: 'canonical', href: this.$_tracking_canonicalURL(this.canonicalModule || this.module) }
      ]
    }
  },

  // Computed
  computed: {
    askForConfirmIncome () {
      return false
    },

    progressCirclesVisiblie () {
      return this.$route.meta.postSubmitIndex ||
      this.$route.meta.postSubmitIndex === 0
    },

    postSubmitIndex () {
      if (!this.progressCirclesVisiblie) return
      return Math.min(this.$route.meta.postSubmitIndex, this.progressCircleCount - 1)
    },

    progressCircleCount () {
      return this.askForConfirmIncome ? 4 : 3
    },

    applicationStarted () {
      return this.$store.state.mortgages.application_in_progress
    }
  },

  mounted () {
    window.addEventListener('beforeunload', (event) => this.confirmPageBounce(event), { capture: true })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/step-transitions';

.app-shell {
  @apply overflow-x-hidden relative flex flex-col;

  &__main {
    @apply pt-60 min-h-[100vh];

    @screen sm {
      @apply pt-90;
    }
  }
}
</style>
