import store from '@/store'
import * as ROUTE_PATHS from '@/constants/route-paths'

/**
* Returns the next employment route
*/
function nextEmploymentRoute () {
  const sourceOfIncomeA = store.state.employment.source_of_income_a

  switch (sourceOfIncomeA) {
    case 'Employed':
    case 'Self Employed':
      return ROUTE_PATHS.INCOME_DETAILS_FREQUENCY

    case 'Retired/Pension':
    case 'Long Term Disability':
      store.dispatch('setIncomeFrequency', 'Monthly')
      return ROUTE_PATHS.INCOME_DETAILS_AMOUNT

    case 'Employment Insurance':
      return employmentInsuranceRoute()

    case 'Other':
      return employmentOtherRoute()

    case 'No Current Income':
      store.dispatch('setMonthlyIncome', 0)
      store.dispatch('setAnnualIncome', 0)
      return ROUTE_PATHS.HOME_ADDRESS

    default:
      return ROUTE_PATHS.INCOME_DETAILS_FREQUENCY
  }
}

function employmentInsuranceRoute () {
  const sourceOfIncomeB = store.state.employment.source_of_income_b

  if (!sourceOfIncomeB) {
    return ROUTE_PATHS.EMPLOYMENT_STATUS_INSURANCE
  }

  store.dispatch('setIncomeFrequency', 'Monthly')
  return ROUTE_PATHS.INCOME_DETAILS_AMOUNT
}

function employmentOtherRoute () {
  const sourceOfIncomeB = store.state.employment.source_of_income_b

  if (!sourceOfIncomeB) {
    return ROUTE_PATHS.EMPLOYMENT_STATUS_OTHER
  }

  store.dispatch('setIncomeFrequency', 'Monthly')
  return ROUTE_PATHS.INCOME_DETAILS_AMOUNT
}

export default nextEmploymentRoute
