/**
* Returns the next route
* @param {Array} nextRoutes possible next routes
* @param {String} successPath successul path if all next routes are verified
* @param {String} currentPath current path
* @returns next route
*/
function nextRoute (nextRoutes, successPath, currentPath = null) {
  const routes = nextRoutes.filter(route => route.enabled())

  // Enable the next route immediately if currentPath is null
  // so that next route will be the first incomplete one in
  // the list
  let enableNextRoute = !currentPath

  for (const route of routes) {
    if (!route.verified() && enableNextRoute) return route.path

    // Enable next route on the next iterations
    if (route.path === currentPath) enableNextRoute = true
  }

  return successPath
}

export default nextRoute
