import AppShellOffers from '@/components/application/AppShellOffers'
import preventRefresh from '@/router/utils/prevent-refresh'
import * as ROUTE_PATHS from '../../constants/route-paths'
import OFFER_STATUS from '../../constants/offer-status'
import store from '@/store'

const SPLAgreementLanding = () => import(
  /* webpackChunkName: "spl-agreement-v1" */
  '@/components/steps/spl-agreement/SPLAgreementLanding'
)

const LegalAgreement = () => import(
  /* webpackChunkName: "spl-agreement-v1" */
  '@/components/steps/spl-agreement/LegalAgreement'
)
const ApplicationSuccessful = () => import(
  /* webpackChunkName: "spl-agreement-v1" */
  '@/components/steps/spl-agreement/ApplicationSuccessful'
)

const Expired = () => import(
  /* webpackChunkName: "spl-agreement-v1" */
  '@/components/steps/foundation-agreement/Expired'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */

const appName = 'spring-personal-loans-agreement'
const variation = 'v1'
const basePath = '/spl-agreement'
const leadType = 'SPL Agreement'

const props = {
  eventPrefix: 'spl-agreement'
}

const nextStepStatusResolver = (nextStep, status) => {
  switch (status) {
    case OFFER_STATUS.EXPIRED: return ROUTE_PATHS.EXPIRED
    case OFFER_STATUS.COMPLETED: return ROUTE_PATHS.APPLICATION_SUCCESS
    default: return nextStep
  }
}

const routes = [
  {
    path: basePath,
    component: AppShellOffers,
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    props: {
      theme: 'blue',
      hideMenu: true,
      module: 'spl-agreement'
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: SPLAgreementLanding,
        beforeEnter: (to, from, next) => {
          const eContract = to.query.id
          const token = to.query.token
          if (!eContract && !token) return next({ path: '/error' })
          next()
        },
        meta: {
          nextStep: () => nextStepStatusResolver(ROUTE_PATHS.LEGAL_AGREEMENT, store.state.offers.opportunity.status),
          index: 1,
          progressLabel: 'Step 1 of 2'
        },
        props
      },
      {
        path: ROUTE_PATHS.LEGAL_AGREEMENT,
        component: LegalAgreement,
        meta: {
          nextStep: ROUTE_PATHS.APPLICATION_SUCCESS,
          index: 4,
          progressLabel: 'Final Step'
        },
        props
      },
      {
        path: ROUTE_PATHS.APPLICATION_SUCCESS,
        component: ApplicationSuccessful,
        meta: {
          index: 5,
          progress: false
        },
        props
      },
      {
        path: ROUTE_PATHS.EXPIRED,
        component: Expired,
        meta: {
          index: 2,
          progress: false
        },
        props
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
