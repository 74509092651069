export const initialState = () => {
  return {
    app_source: '',
    financing_type: '',
    ad_source: '',
    lead_source: '',
    variation: '',
    device_type: '',
    referrer_url: '',
    country_code: '',
    user_agent: '',
    AID: '',
    AID2: '',
    SID: '',
    SID2: '',
    SUB_ID: '',
    consent_CEMs: 0,
    gclid: '',
    lead_type__c: '',
    fbc: '', // Facebook Click ID
    fbp: '', // Facebook Browser ID
    utm_campaign: '',
    utm_content: '',
    TTP__c: '',
    TTCLID__c: ''
  }
}

export default {
  state: initialState(),

  getters: {
    trackingParams ({ AID, AID2, SID, SID2, utmCampaign, utmContent }) {
      return { AID, AID2, SID, SID2, utmCampaign, utmContent }
    }
  },

  mutations: {
    SET_VARIATION (state, variation) {
      state.variation = variation
    },

    SET_SOURCE (state, appSource) {
      state.app_source = appSource
    },

    SET_FINANCING_TYPE (state, financingType) {
      state.financing_type = financingType
    },

    SET_AD_SOURCE (state, adSource) {
      state.ad_source = getLastItem(adSource)
    },

    SET_UTM_CAMPAIGN (state, utmCampaign) {
      state.utm_campaign = getLastItem(utmCampaign)
    },

    SET_UTM_CONTENT (state, utmContent) {
      state.utm_content = getLastItem(utmContent)
    },

    SET_LEAD_SOURCE (state, leadSource) {
      state.lead_source = getLastItem(leadSource)
    },

    SET_DEVICE_TYPE (state, deviceType) {
      state.device_type = deviceType
    },

    SET_COUNTRY_CODE (state, countryCode) {
      state.country_code = countryCode
    },

    SET_USER_AGENT (state, userAgent) {
      state.user_agent = userAgent
    },

    SET_REFERRER_URL (state, referrerUrl) {
      state.referrer_url = referrerUrl
    },

    SET_AID (state, AID) {
      state.AID = getLastItem(AID)
    },

    SET_AID2 (state, AID2) {
      state.AID2 = getLastItem(AID2)
    },

    SET_SID (state, SID) {
      state.SID = getLastItem(SID)
    },

    SET_SID2 (state, SID2) {
      state.SID2 = getLastItem(SID2)
    },

    SET_SUB_ID (state, SUB_ID) {
      state.SUB_ID = getLastItem(SUB_ID)
    },

    SET_CONSENT_CEM (state, consent) {
      state.consent_CEMs = +consent
    },

    RESET_TRACKING_STATE (state, initialState) {
      Object.keys(initialState).forEach(key => (state[key] = initialState[key]))
    },

    SET_GCLID (state, gclid) {
      state.gclid = gclid
    },

    SET_LEAD_TYPE (state, leadType) {
      state.lead_type__c = leadType
    },

    SET_FBC (state, fbc) {
      state.fbc = fbc
    },

    SET_FBP (state, fbp) {
      state.fbp = fbp
    },

    SET_TTP (state, ttp) {
      state.TTP__c = ttp
    },

    SET_TTCLID (state, ttclid) {
      state.TTCLID__c = ttclid
    }
  },

  actions: {
    setDeviceType ({ commit }, deviceType) {
      commit('SET_DEVICE_TYPE', deviceType)
    },

    setCountryCode ({ commit }, countryCode) {
      commit('SET_COUNTRY_CODE', countryCode)
    },

    setSource ({ commit }, appSource) {
      commit('SET_SOURCE', appSource)
    },

    setFinancingType ({ commit }, financingType) {
      commit('SET_FINANCING_TYPE', financingType)
    },

    setAdSource ({ commit }, addSource) {
      commit('SET_AD_SOURCE', addSource)
    },

    setUtmCampaign ({ commit }, utmCampaign) {
      commit('SET_UTM_CAMPAIGN', utmCampaign)
    },

    setUtmContent ({ commit }, utmContent) {
      commit('SET_UTM_CONTENT', utmContent)
    },

    setLeadSource ({ commit }, leadSource) {
      commit('SET_LEAD_SOURCE', leadSource)
    },

    setReferrerUrl ({ commit }, referrerUrl) {
      commit('SET_REFERRER_URL', referrerUrl)
    },

    setVariation ({ commit }, variation) {
      commit('SET_VARIATION', variation)
    },

    setUserAgent ({ commit }, userAgent) {
      commit('SET_USER_AGENT', userAgent)
    },

    setAID ({ commit }, AID) {
      commit('SET_AID', AID)
    },

    setAID2 ({ commit }, AID2) {
      commit('SET_AID2', AID2)
    },

    setSID ({ commit }, SID) {
      commit('SET_SID', SID)
    },

    setSubId ({ commit }, subId) {
      commit('SET_SUB_ID', subId)
    },

    setSID2 ({ commit }, SID2) {
      commit('SET_SID2', SID2)
    },

    setConsentCEM ({ commit }, consent) {
      commit('SET_CONSENT_CEM', consent)
    },

    resetTrackingState ({ commit }) {
      commit('RESET_TRACKING_STATE', initialState())
    },

    setGclid ({ commit }, gclid) {
      commit('SET_GCLID', gclid)
    },

    setLeadType ({ commit }, leadType) {
      commit('SET_LEAD_TYPE', leadType)
    },

    setFbc ({ commit }, fbc) {
      commit('SET_FBC', fbc)
    },

    setFbp ({ commit }, fbp) {
      commit('SET_FBP', fbp)
    },

    setTtp ({ commit }, ttp) {
      commit('SET_TTP', ttp)
    },

    setTtclid ({ commit }, ttclid) {
      commit('SET_TTCLID', ttclid)
    }
  }
}

const getLastItem = (value) => {
  let item
  if (Array.isArray(value)) item = value[0]
  else item = value
  return item ? item.split(',').slice(-1)[0] : ''
}
