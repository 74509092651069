import AppShell from '@/components/application/AppShell'
import preventRefresh from '@/router/utils/prevent-refresh'
import * as ROUTE_PATHS from '../../constants/route-paths'
import * as PROGRESS_LABELS from '../../constants/progress-label'
import * as MORTGAGE from '../../constants/mortgages'
import store from '@/store'

const LivingInProvinces = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/LivingInProvinces'
)
const HomeValue = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/HomeValue'
)
const AmountOwing = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/AmountOwing'
)
const CurrentInterestRate = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/CurrentInterestRate'
)
const HomeOwnershipDuration = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/HomeOwnershipDuration'
)
const HomeAddress = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/common/HomeAddress'
)
const DateOfBirth = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/common/DateOfBirth'
)
const ContactPhone = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/common/ContactPhone'
)
const ContactCode = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/common/ContactCode'
)
const NameDetails = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/NameDetails'
)
const ApplicationSuccessful = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/mortgages/ApplicationSuccessful'
)
const Sorry = () => import(
  /* webpackChunkName: "mortgage-v1" */
  '@/components/steps/common/Sorry'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */
const appName = 'springmortgage'
const variation = 'v2'
const basePath = '/mortgage'
const financingType = 'New Mortgage'

const routes = [
  {
    path: basePath,
    component: AppShell,
    meta: {
      basePath,
      appName,
      variation,
      financingType
    },
    props: {
      theme: 'pink',
      module: 'mortgages',
      canonicalModule: 'mortgage'
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [{
      path: '',
      component: LivingInProvinces,
      meta: {
        index: 1,
        nextStep: ROUTE_PATHS.HOME_OWNERSHIP_DURATION,
        progressLabel: PROGRESS_LABELS.TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      }
    }, {
      path: ROUTE_PATHS.HOME_OWNERSHIP_DURATION,
      component: HomeOwnershipDuration,
      meta: {
        index: 2,
        nextStep: () => {
          // If the user did not pick “I do not own a home”, the next step is HOME_VALUE
          // Otherwise the next step is DATE_OF_BIRTH
          if (store.state.mortgages.home_ownership_duration !== MORTGAGE.DOES_NOT_OWN_HOME) {
            return ROUTE_PATHS.HOME_VALUE
          } else {
            return ROUTE_PATHS.DATE_OF_BIRTH
          }
        },
        progressLabel: PROGRESS_LABELS.TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      }
    }, {
      path: ROUTE_PATHS.HOME_VALUE,
      component: HomeValue,
      meta: {
        index: 3,
        nextStep: ROUTE_PATHS.AMOUNT_OWING,
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      }
    }, {
      path: ROUTE_PATHS.AMOUNT_OWING,
      component: AmountOwing,
      meta: {
        index: 4,
        nextStep: ROUTE_PATHS.CURRENT_INTEREST_RATE,
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      }
    }, {
      path: ROUTE_PATHS.CURRENT_INTEREST_RATE,
      component: CurrentInterestRate,
      meta: {
        index: 5,
        nextStep: ROUTE_PATHS.HOME_ADDRESS,
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      },
      props: {
        skipUpdateLead: true
      }
    }, {
      path: ROUTE_PATHS.HOME_ADDRESS,
      component: HomeAddress,
      meta: {
        index: 5,
        nextStep: ROUTE_PATHS.DATE_OF_BIRTH,
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      },
      props: {
        skipUpdateLead: true,
        eventPrefix: 'mortgage',
        searchVersion: process.env.VUE_APP_CPOST_SEARCH_VERSION
      }
    }, {
      path: ROUTE_PATHS.DATE_OF_BIRTH,
      component: DateOfBirth,
      meta: {
        index: 6,
        nextStep: ROUTE_PATHS.NAME_DETAILS,
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      },
      props: {
        skipUpdateLead: true,
        eventPrefix: 'mortgage'
      }
    }, {
      path: ROUTE_PATHS.NAME_DETAILS,
      component: NameDetails,
      meta: {
        index: 7,
        nextStep: ROUTE_PATHS.CONTACT_PHONE,
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      },
      props: {
        title: 'Almost Done!\nWhat Is Your Name and Email?'
      }
    }, {
      path: ROUTE_PATHS.CONTACT_PHONE,
      component: ContactPhone,
      meta: {
        index: 8,
        nextStep: () => {
          return store.state.application.twilioFailed ? ROUTE_PATHS.APPLICATION_SUCCESS : ROUTE_PATHS.CONTACT_CODE
        },
        progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
        showFooter: true,
        showAppButton: true
      },
      props: {
        eventPrefix: 'mortgage',
        checkbox: true,
        skipUpdateLead: true
      }
    }, {
      path: ROUTE_PATHS.CONTACT_CODE,
      component: ContactCode,
      meta: {
        index: 9,
        nextStep: ROUTE_PATHS.APPLICATION_SUCCESS,
        progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
        showFooter: true,
        showAppButton: true
      },
      props: {
        skipUpdateLead: true,
        eventPrefix: 'mortgage'
      }
    }, {
      path: ROUTE_PATHS.APPLICATION_SUCCESS,
      component: ApplicationSuccessful,
      meta: {
        index: 11,
        progressLabel: PROGRESS_LABELS.FINISHED
      },
      props: {
        offerLink: 'https://spgfl.ca/Vwxb'
      }
    }, {
      path: ROUTE_PATHS.SORRY,
      component: Sorry,
      meta: {
        progress: false,
        showFooter: false,
        showSkipStep: false,
        disableGridLayout: true
      },
      props: {
        sorryMessage: 'Currently our mortgage products are only available in Ontario, Alberta, and British Colombia.',
        displaySorry: true
      }
    }]
  }
]

export default routes
