import * as MORTGAGE from '../../constants/mortgages'

const basePath = '/home-equity-line-of-credit'

const routes = [
  {
    path: basePath,
    redirect: to => {
      return {
        path: '/mortgage',
        query: {
          financing_type: MORTGAGE.HOME_EQUITY_PARAM,
          ...to.query
        }
      }
    }
  },
  {
    path: `${basePath}/*`,
    redirect: to => {
      return {
        path: '/mortgage',
        query: {
          financing_type: MORTGAGE.HOME_EQUITY_PARAM,
          ...to.query
        }
      }
    }
  }
]

export default routes
