<template>
  <div
    class="step-footer"
    :class="[fixedMobileButtonStyles, hideMobileView]"
  >
    <div
      class="step-footer__inner"
      :class="innerClass"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import scroll from '@/mixins/scroll'
import { screens } from '../../../../theme/screens'

export default {
  // Name
  name: 'StepFooter',

  mixins: [
    scroll
  ],

  // Props
  props: {
    fixed: {
      type: Boolean,
      default: false
    },

    fixedMobile: {
      type: Boolean,
      default: false
    },

    split: {
      type: Boolean,
      default: false
    },

    hideMobile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    fixedMobileButtonStyles () {
      return {
        'step-footer--fixed': this.fixed,
        'step-footer--fixed-mobile': this.fixedMobile
      }
    },

    hideMobileView () {
      return this.hideMobile && this.$route.meta?.showFooter ? 'xs-only:hidden' : ''
    },

    innerClass () {
      return [
        this.split ? 'step-footer__inner--split' : ''
      ]
    },

    isInputFocused () {
      return this.$store.state.application.inputFocused
    }
  },

  watch: {
    async isInputFocused (focused) {
      if (window.innerWidth > parseInt(screens['xs-only'].max)) return
      if (!this.fixed) return

      if (!focused) {
        // Remove event listeners and reset the original position of the step footer.
        window.visualViewport.removeEventListener('resize', this.reposition)
        window.removeEventListener('scroll', this.reposition)
        // setTimeout seems necessary so that the element style can be reset
        // as well as the click event be triggered on the button.
        setTimeout(() => {
          this.resetPosition()
        }, 0)
        return
      }

      window.visualViewport.addEventListener('resize', this.reposition)

      // Wait for the window to stop auto scrolling
      // when something like an input is focused.
      await (async () => {
        let scrollPosition = -1
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            const newScrollPosition = window.scrollY
            if (newScrollPosition !== scrollPosition) {
              scrollPosition = newScrollPosition
              return
            }

            clearInterval(interval)
            this.reposition()
            resolve()
          }, 100)
        })
      })()

      // Listen to scroll event to reposition the 'absolute'
      // positioned footer.
      window.addEventListener('scroll', this.reposition)

      // Add a quick timeout to fix bug in some Android devices where
      // the visual viewport is not updated yet.
      setTimeout(() => {
        // If the step footer is covering the focused element, scroll to it.
        if (this.isOverlappingActiveElement()) this.$_scroll_toActiveElement(200)
      }, 50)
    }
  },

  methods: {
    // Repositions the step footer with 'position: absolute', because
    // iOS doesn't support fixed position when the keyboard is open.
    // This forces the step footer to always be positioned above the keyboard.
    reposition () {
      const offsetTop = window.visualViewport.height - this.$el.offsetHeight + window.scrollY
      this.$el.style.cssText = `
        bottom: auto;
        position: absolute;
        top: 0;
        transform: translateY(${offsetTop}px) scale(${1 / window.visualViewport.scale})
      `
    },

    resetPosition () {
      this.$el.removeAttribute('style')
    },

    isOverlappingActiveElement () {
      const activeElementTop = document.activeElement.getBoundingClientRect().top
      const activeElementHeight = document.activeElement.offsetHeight
      const footerTop = window.visualViewport.height - this.$el.offsetHeight

      return activeElementTop + activeElementHeight >= footerTop
    }
  }
}
</script>

<style scoped lang="scss">
.step-footer {
  @apply container mb-100;

  &:last-child {
    @apply mb-0;
  }

  &__inner {
    @apply max-w-500 mx-auto pb-15;

    @screen lg {
      @apply max-w-400;
    }

    &--split {
      @apply flex items-center justify-center;
    }
  }

  &--fixed {
    @apply shadow-top;

    @screen xs-only {
      @apply bottom-0 bg-white fixed left-0 px-20 py-15 w-full z-10;
      @apply border border-gray-300;
    }

    @screen sm {
      @apply hidden mb-16;
    }
  }

  &--fixed-mobile {
    @screen xs-only {
      @apply bottom-0 bg-white fixed left-0 px-20 py-15 w-full z-10;
      @apply border border-gray-300;
    }

    @screen sm {
      @apply p-0 m-0;
    }

    .step-footer__inner {
      @apply max-w-none;
    }
  }
}
</style>
