const minisiteUrl = process.env.VUE_APP_MINISITE_URL

export default {
  methods: {
    addTrackingParams (link) {
      return this.joinedTrackingParams
        ? `${link}?${this.joinedTrackingParams}`
        : link
    },

    $_tracking_canonicalURL (module) {
      return `${minisiteUrl}apply/${module}`
    }
  },

  computed: {
    joinedTrackingParams () {
      return Object.entries(this.$route.query).map(([key, val]) => `${key}=${val}`).join('&')
    }

  }
}
