const initialState = () => {
  return {
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    monthlyIncomeError: '',
    annualIncomeError: '',
    hourlyIncomeError: '',
    hoursPerWeekError: '',
    employerYearsError: '',
    employerMonthsError: '',
    cPostError: '',
    fallBackStreetAddressError: '',
    fallBackCityError: '',
    fallBackProvinceError: '',
    fallBackPostalCodeError: '',
    dateOfBirthError: '',
    phoneNumberError: '',
    phoneCodeError: '',
    identityFileError: '',
    addressFileError: '',
    incomeFile1Error: '',
    incomeFile2Error: '',
    homeValueError: '',
    amountOwingError: ''
  }
}

export default {
  state: initialState(),

  mutations: {
    SET_EMAIL_ERROR (state, val) {
      state.emailError = val
    },

    SET_FIRST_NAME_ERROR (state, val) {
      state.firstNameError = val
    },

    SET_LAST_NAME_ERROR (state, val) {
      state.lastNameError = val
    },

    SET_MONTHLY_INCOME_ERROR (state, val) {
      state.monthlyIncomeError = val
    },

    SET_ANNUAL_INCOME_ERROR (state, val) {
      state.annualIncomeError = val
    },

    SET_HOURLY_INCOME_ERROR (state, val) {
      state.hourlyIncomeError = val
    },

    SET_HOURS_PER_WEEK_ERROR (state, val) {
      state.hoursPerWeekError = val
    },

    SET_EMPLOYER_YEARS_ERROR (state, val) {
      state.employerYearsError = val
    },

    SET_EMPLOYER_MONTHS_ERROR (state, val) {
      state.employerMonthsError = val
    },

    SET_CPOST_ERROR (state, val) {
      state.cPostError = val
    },

    SET_FALL_BACK_STREET_ADDRESS_ERROR (state, val) {
      state.fallBackStreetAddressError = val
    },

    SET_FALL_BACK_CITY_ERROR (state, val) {
      state.fallBackCityError = val
    },

    SET_FALL_BACK_PROVINCE_ERROR (state, val) {
      state.fallBackProvinceError = val
    },

    SET_FALL_BACK_POSTAL_CODE_ERROR (state, val) {
      state.fallBackPostalCodeError = val
    },

    SET_DATE_OF_BIRTH_ERROR (state, val) {
      state.dateOfBirthError = val
    },

    SET_PHONE_NUMBER_ERROR (state, val) {
      state.phoneNumberError = val
    },

    SET_PHONE_CODE_ERROR (state, val) {
      state.phoneCodeError = val
    },

    SET_IDENTITY_FILE_ERROR (state, val) {
      state.identityFileError = val
    },

    SET_ADDRESS_FILE_ERROR (state, val) {
      state.addressFileError = val
    },

    SET_INCOME_FILE_1_ERROR (state, val) {
      state.incomeFile1Error = val
    },

    SET_INCOME_FILE_2_ERROR (state, val) {
      state.incomeFile2Error = val
    },

    SET_HOME_VALUE_ERROR (state, val) {
      state.homeValueError = val
    },

    SET_AMOUNT_OWING_ERROR (state, val) {
      state.amountOwingError = val
    }
  },

  actions: {
    setEmailError ({ commit }, val) {
      commit('SET_EMAIL_ERROR', val)
    },

    setFirstNameError ({ commit }, val) {
      commit('SET_FIRST_NAME_ERROR', val)
    },

    setLastNameError ({ commit }, val) {
      commit('SET_LAST_NAME_ERROR', val)
    },

    setMonthlyIncomeError ({ commit }, val) {
      commit('SET_MONTHLY_INCOME_ERROR', val)
    },

    setAnnualIncomeError ({ commit }, val) {
      commit('SET_ANNUAL_INCOME_ERROR', val)
    },

    setHourlyIncomeError ({ commit }, val) {
      commit('SET_HOURLY_INCOME_ERROR', val)
    },

    setHoursPerWeekError ({ commit }, val) {
      commit('SET_HOURS_PER_WEEK_ERROR', val)
    },

    setEmployerYearsError ({ commit }, val) {
      commit('SET_EMPLOYER_YEARS_ERROR', val)
    },

    setEmployerMonthsError ({ commit }, val) {
      commit('SET_EMPLOYER_MONTHS_ERROR', val)
    },

    setCPostError ({ commit }, val) {
      commit('SET_CPOST_ERROR', val)
    },

    setFallBackCityError ({ commit }, val) {
      commit('SET_FALL_BACK_CITY_ERROR', val)
    },

    setFallBackProvinceError ({ commit }, val) {
      commit('SET_FALL_BACK_PROVINCE_ERROR', val)
    },

    setFallBackStreetAddressError ({ commit }, val) {
      commit('SET_FALL_BACK_STREET_ADDRESS_ERROR', val)
    },

    setFallBackPostalCodeError ({ commit }, val) {
      commit('SET_FALL_BACK_POSTAL_CODE_ERROR', val)
    },

    setDateOfBirthError ({ commit }, val) {
      commit('SET_DATE_OF_BIRTH_ERROR', val)
    },

    setPhoneNumberError ({ commit }, val) {
      commit('SET_PHONE_NUMBER_ERROR', val)
    },

    setPhoneCodeError ({ commit }, val) {
      commit('SET_PHONE_CODE_ERROR', val)
    },

    setIdentityFileError ({ commit }, val) {
      commit('SET_IDENTITY_FILE_ERROR', val)
    },

    setAddressFileError ({ commit }, val) {
      commit('SET_ADDRESS_FILE_ERROR', val)
    },

    setIncomeFile1Error ({ commit }, val) {
      commit('SET_INCOME_FILE_1_ERROR', val)
    },

    setIncomeFile2Error ({ commit }, val) {
      commit('SET_INCOME_FILE_2_ERROR', val)
    },

    setHomeValueError ({ commit }, val) {
      commit('SET_HOME_VALUE_ERROR', val)
    },

    setAmountOwingError ({ commit }, val) {
      commit('SET_AMOUNT_OWING_ERROR', val)
    }
  }
}
