import AppShellOffers from '@/components/application/AppShellOffers'
import preventRefresh from '@/router/utils/prevent-refresh'
import * as ROUTE_PATHS from '../../constants/route-paths'
import OFFER_STATUS from '../../constants/offer-status'
import store from '@/store'

const FoundationGradLanding = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/foundation-grad/FoundationGradLanding'
)
const LegalAgreement = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/foundation-grad/LegalAgreement'
)
const ApplicationSuccessful = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/foundation-grad/ApplicationSuccessful'
)

const VerifyBank = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/common/VerifyBank'
)

const Qualified = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/foundation-grad/Qualified'
)

const Expired = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/foundation-grad/Expired'
)
const Refused = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/foundation-grad/Refused'
)

const FinancialSelector = () => import(
  /* webpackChunkName: "foundation-grad-v1" */
  '@/components/steps/common/FinancialSelector'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */

const appName = 'springoffer-foundation-grad'
const variation = 'v1'
const basePath = '/foundation-grad'
const leadType = 'Foundation Grad'

const props = {
  eventPrefix: 'foundation-grad'
}

const nextStepStatusResolver = (nextStep, status) => {
  switch (status) {
    case OFFER_STATUS.EXPIRED: return ROUTE_PATHS.EXPIRED
    case OFFER_STATUS.REJECTED: return ROUTE_PATHS.REFUSED
    case OFFER_STATUS.COMPLETED: return ROUTE_PATHS.APPLICATION_SUCCESS
    default: return nextStep
  }
}

const routes = [
  {
    path: basePath,
    component: AppShellOffers,
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    props: {
      theme: 'green',
      hideMenu: true,
      module: 'foundation-grad'
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: FoundationGradLanding,
        beforeEnter: (to, from, next) => {
          const eContract = to.query.id
          const token = to.query.token
          if (!eContract && !token) return next({ path: '/error' })
          next()
        },
        meta: {
          nextStep: () => nextStepStatusResolver(ROUTE_PATHS.QUALIFIED, store.state.offers.opportunity.status),
          index: 1,
          progressLabel: 'Step 1 of 4'
        },
        props
      },
      {
        path: ROUTE_PATHS.QUALIFIED,
        component: Qualified,
        meta: {
          index: 2,
          nextStep: () => nextStepStatusResolver(process.env.VUE_APP_DISABLE_FI_SELECTOR ? ROUTE_PATHS.VERIFY_BANK : ROUTE_PATHS.FINANCIAL_SELECTOR, store.state.offers.opportunity.status),
          progressLabel: 'Step 2 of 4',
          showFooter: true
        },
        props
      },
      {
        path: ROUTE_PATHS.EXPIRED,
        component: Expired,
        meta: {
          progress: false
        },
        props
      },
      {
        path: ROUTE_PATHS.REFUSED,
        component: Refused,
        meta: {
          progress: false
        },
        props
      },
      {
        path: ROUTE_PATHS.FINANCIAL_SELECTOR,
        component: FinancialSelector,
        meta: {
          index: 3,
          nextStep: ROUTE_PATHS.VERIFY_BANK,
          showFooter: false,
          progressLabel: 'Step 3 of 4'
        },
        props: {
          ...props
        }
      },
      {
        path: ROUTE_PATHS.VERIFY_BANK,
        component: VerifyBank,
        meta: {
          nextStep: ROUTE_PATHS.LEGAL_AGREEMENT,
          index: 4,
          progress: true,
          progressLabel: 'Step 3 of 4'
        },
        props: {
          ...props,
          title: 'Please Verify Your Bank',
          subtitle: 'Please enter the banking information of your Foundation product.',
          showFooter: true,
          docsStandAlone: true
        }
      },
      {
        path: ROUTE_PATHS.LEGAL_AGREEMENT,
        component: LegalAgreement,
        meta: {
          nextStep: ROUTE_PATHS.APPLICATION_SUCCESS,
          index: 5,
          progressLabel: 'Final Step'
        },
        props
      },
      {
        path: ROUTE_PATHS.APPLICATION_SUCCESS,
        component: ApplicationSuccessful,
        meta: {
          progress: false
        },
        props
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
