export default {
  state: {
    isFoundationGold: true,
    isBloomFlow: true,
    returningDeclinedCustomer: false
  },

  mutations: {
    SET_IS_FOUNDATION_GOLD (state, isFoundationGold) {
      state.isFoundationGold = isFoundationGold
    },

    SET_IS_BLOOM_FLOW (state, isBloomFlow) {
      // if isBloomFlow is false, then the Cx has already seen the non bloom version of the A/B test and should be shown the same version,
      // if isBloomFlow is undefined, keep it as true by default
      if (isBloomFlow === false) {
        state.isBloomFlow = isBloomFlow
      }
    },

    SET_RETURNING_FOUNDATION_DECLINED_CUSTOMER (state) {
      state.returningDeclinedCustomer = true
    }
  },

  actions: {
    setIsFoundationGold ({ commit }, isFoundationGold) {
      commit('SET_IS_FOUNDATION_GOLD', isFoundationGold)
    },

    setIsBloomFlow ({ commit }, isBloomFlow) {
      commit('SET_IS_BLOOM_FLOW', isBloomFlow)
    },

    setReturningDeclinedCustomer ({ commit }) {
      commit('SET_RETURNING_FOUNDATION_DECLINED_CUSTOMER')
    }
  }
}
