<template>
  <div class="app-loans-background">
    <img
      :src="shapes.blob_yellow_left"
      alt=""
      class="shape app-loans-background__blob--yellow"
    >
    <img
      :src="shapes.lines_yellow_left"
      alt=""
      class="shape app-loans-background__line--yellow"
    >
    <img
      :src="shapes.blob_blue_right"
      alt=""
      class="shape app-loans-background__blob--blue"
    >
    <img
      :src="shapes.lines_green_right"
      alt=""
      class="shape app-loans-background__line--green"
    >
  </div>
</template>

<script>
export default {
  name: 'AppBackgroundMortgages',
  data () {
    return {
      shapes: {
        lines_yellow_left: require('@/assets/svg/backgrounds/lines_yellow_left.svg'),
        blob_yellow_left: require('@/assets/svg/backgrounds/blob_yellow_left.svg'),
        lines_green_right: require('@/assets/svg/backgrounds/lines_green_right.svg'),
        blob_blue_right: require('@/assets/svg/backgrounds/blob_blue_right.svg')

      }
    }
  }
}
</script>

<style scoped lang="scss">
.app-loans-background {
  @apply relative w-full h-full;

  &__blob {
    &--blue {
      @apply top-[450px] w-[10%] right-0 scale-y-[-1];

      @screen xxs {
        @apply top-[430px];

        @media (min-height: 700px) {
          @apply top-[410px];
        }

        @media (min-width: 500px) and (max-height: 730px) {
          @screen portrait {
            @apply w-[7%] top-[390px];
          }
        }
      }

      @screen sm {
        @apply top-[300px] w-[10%];
      }

      @screen md {
        @apply top-[400px] w-[8%];

        @screen portrait {
          @apply top-[300px];
        }
      }

      @screen lg {
        @apply w-[17%] top-[450px] scale-y-[1];
      }

      @screen xl {
        @apply w-[13%];
      }
    }

    &--yellow {
      @apply top-[130px] w-[9%] left-0;

      @screen sm {
        @apply top-[150px] w-[12%];
      }

      @screen md {
        @apply top-[130px] w-[11%];
      }
    }
  }

  &__line {
    &--green {
      @apply top-[470px] w-[11%] -right-5;

      @screen xxs {
        @apply top-[450px];

        @media (min-height: 700px) {
          @apply top-[430px];
        }

        @media (min-width: 500px) and (max-height: 730px) {
          @screen portrait {
            @apply w-[10%] top-[400px];
          }
        }
      }

      @screen sm {
        @apply top-[380px] w-[8%] right-0;
      }

      @screen md {
        @apply top-[460px];

        @screen portrait {
          @apply top-[380px];
        }
      }

      @screen lg {
        @apply top-[350px] w-[12%] right-0;
      }

      @screen xl {
        @apply w-[10%];
      }
    }

    &--yellow {
      @apply top-[100px] w-[6%] left-0;

      @screen sm {
        @apply top-[110px] w-[8%];
      }

      @screen md {
        @apply top-[70px] w-[7%];
      }

      @screen lg {
        @apply top-[300px] w-[9%];
      }
    }
  }
}
</style>
