<template>
  <div class="app-router-view-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppRouterViewContainer'
}
</script>

<style scoped lang="scss">
.app-router-view-container {
  @apply flex-1;
}
</style>
