import { colors } from '../../theme/colors'

export default {
  props: {
    theme: {
      type: String,
      default: 'purple'
    }
  },

  methods: {
    bgColorClass (colorWeight = 500, pseudoClass) {
      return `${pseudoClass ? `${pseudoClass}:` : ''}bg-${this.theme}-${colorWeight}`
    },

    borderColorClass (colorWeight = 500, pseudoClass) {
      return `${pseudoClass ? `${pseudoClass}:` : ''}border-${this.theme}-${colorWeight}`
    },

    textColorClass (colorWeight = 500, pseudoClass) {
      return `${pseudoClass ? `${pseudoClass}:` : ''}text-${this.theme}-${colorWeight}`
    },

    caretColorClass (colorWeight = 500, pseudoClass) {
      return `${pseudoClass ? `${pseudoClass}:` : ''}caret-${this.theme}-${colorWeight}`
    },

    hexColor (colorWeight = 500) {
      return colors[this.theme][colorWeight]
    }
  }
}
