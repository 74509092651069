<template>
  <div class="app-loans-background">
    <img
      :src="shapes.blob_purple_left"
      alt=""
      class="shape app-loans-background__blob--purple"
    >
    <img
      :src="shapes.lines_purple_left"
      alt=""
      class="shape flip-horizontal flip-vertical app-loans-background__line--purple"
    >
    <img
      :src="shapes.blob_green_right"
      alt=""
      class="shape app-loans-background__blob--green"
    >
    <img
      :src="shapes.lines_green_right"
      alt=""
      class="shape app-loans-background__line--green"
    >
  </div>
</template>

<script>
export default {
  name: 'AppBackgroundPersonalLoans',
  data () {
    return {
      shapes: {
        lines_purple_left: require('@/assets/svg/backgrounds/lines_purple_right2.svg'),
        blob_purple_left: require('@/assets/svg/backgrounds/blob_purple_left.svg'),
        lines_green_right: require('@/assets/svg/backgrounds/lines_green_right.svg'),
        blob_green_right: require('@/assets/svg/backgrounds/blob_green_right.svg')

      }
    }
  }
}
</script>

<style scoped lang="scss">
.app-loans-background {
  @apply relative w-full h-full;

  &__blob {
    &--green {
      @apply top-[450px] w-[9%] right-0 scale-y-[-1];

      @screen xxs {
        @media (min-height: 700px) {
          @apply top-[500px];
        }

        @media (min-height: 900px) {
          @apply top-[520px];
        }

        @media (min-width: 500px) and (max-height: 730px) {
          @screen portrait {
            @apply w-[7%] top-[500px];
          }
        }
      }

      @screen sm {
        @apply top-[400px] w-[10%];
      }

      @screen md {
        @apply top-[660px] w-[8%];
      }

      @screen lg {
        @apply w-[15%] top-[500px] scale-y-[1];
      }

      @screen xl {
        @apply w-[13%];
      }
    }

    &--purple {
      @apply top-[130px] w-[10%] left-0;

      @screen sm {
        @apply top-[150px] w-[12%];
      }

      @screen md {
        @apply top-[130px] w-[11%];
      }
    }
  }

  &__line {
    &--green {
      @apply top-[460px] w-[13%] -right-5;

      @screen xxs {
        @media (min-height: 700px) {
          @apply top-[520px];
        }

        @media (min-height: 900px) {
          @apply top-[540px] w-[11%];
        }

        @media (min-width: 500px) and (max-height: 730px) {
          @screen portrait {
            @apply w-[10%] top-[500px];
          }
        }
      }

      @screen sm {
        @apply top-[450px] w-[12%] right-[-5%];
      }

      @screen md {
        @apply top-[700px];
      }

      @screen lg {
        @apply top-[350px] w-[13%] right-0;
      }

      @screen xl {
        @apply w-[10%];
      }
    }

    &--purple {
      @apply top-[110px] w-[7%] left-0;

      @screen sm {
        @apply top-[110px] w-[8%];
      }

      @screen md {
        @apply top-[70px] w-[7%];
      }

      @screen lg {
        @apply top-[300px];
      }
    }
  }
}
</style>
