export const initialState = () => {
  // get the initial state of the abTest module based on ENV
  const abTestingEnabled = process.env.VUE_APP_AB_TESTING_ENABLED === 'true'

  // get modules from env and convert to array
  let abTestEnabledModules = process.env.VUE_APP_AB_TESTING_MODULES
    ? process.env.VUE_APP_AB_TESTING_MODULES.split(',')
    : []

  let enabledModules = new Map(abTestEnabledModules.map((module) => {
    let [moduleName, rate] = module.split(':')

    // if no rate is provided, default to 50%
    rate = rate ? Number(rate) : 50

    return [moduleName, { rate: rate, module: moduleName }]
  }))

  return {
    ab_testing_enabled: abTestingEnabled,
    ab_tests_enabled_modules: enabledModules,
    ab_tests: new Map()
  }
}

export default {
  state: initialState(),

  getters: {
    abTests (state) {
      return state.ab_tests
    },

    getTestByModule: (state) => (module) => {
      return state.ab_tests.get(module)
    }

  },

  mutations: {
    SET_AB_TESTS (state, abTests) {
      state.ab_tests = abTests
    },

    SET_ACTIVE_AB_TEST (state, abTest) {
      state.ab_tests.set(abTest.module, abTest)
    }
  },

  actions: {
    setAbTests ({ commit }, abTests) {
      commit('SET_AB_TESTS', abTests)
    },

    setActiveAbTest ({ commit }, abTest) {
      commit('SET_ACTIVE_AB_TEST', abTest)
    }
  }
}
