<template>
  <div
    class="app-link"
    :class="[textColorClass()]"
  >
    <!-- render anchor for external URLs -->
    <a
      v-if="external"
      :href="to"
      :target="isNewTab"
    >
      <font-awesome-icon
        v-if="icon && iconPosition === 'left'"
        :icon="icon"
        class="app-link__icon app-link__icon--left"
        size="xs"
      />

      <slot />

      <font-awesome-icon
        v-if="icon && iconPosition === 'right'"
        :icon="icon"
        class="app-link__icon app-link__icon--right"
        size="xs"
      />
    </a>

    <!-- use smart prefetching nuxt-link for SPA-like behaviour -->
    <router-link
      v-else
      :to="to"
      :target="isNewTab"
    >
      <font-awesome-icon
        v-if="icon && iconPosition === 'left'"
        :icon="icon"
        class="app-link__icon app-link__icon--left"
        size="xs"
      />

      <slot />

      <font-awesome-icon
        v-if="icon && iconPosition === 'right'"
        :icon="icon"
        class="app-link__icon app-link__icon--right"
        size="xs"
      />
    </router-link>
  </div>
</template>

<script>
import theme from '@/mixins/theme'

export default {
  name: 'AppLink',

  mixins: [
    theme
  ],

  props: {
    to: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      default: ''
    },

    iconPosition: {
      type: String,
      validator (value) {
        return ['left', 'right'].includes(value)
      },
      default: 'right'
    },

    external: {
      type: Boolean,
      default: false
    },

    newTab: {
      type: Boolean,
      default: false
    },

    theme: {
      type: String,
      default: 'black'
    }
  },

  computed: {
    isNewTab () {
      return this.newTab ? '_blank' : ''
    }
  }
}
</script>

<style scoped lang="scss">
.app-link {
  @apply font-semibold inline-flex items-center text-lg;

  a {
    @apply flex items-center;
  }

  &__icon {
    &--left {
      @apply mr-15;
    }

    &--right {
      @apply ml-15;
    }
  }
}
</style>
