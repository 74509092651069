import * as MORTGAGE from '../../constants/mortgages'

const basePath = '/refinance-mortgage'

const routes = [
  {
    path: basePath,
    redirect: to => {
      return {
        path: '/mortgage',
        query: {
          financing_type: MORTGAGE.REFINANCE_PARAM,
          ...to.query
        }
      }
    }
  },
  {
    path: `${basePath}/*`,
    redirect: to => {
      return {
        path: '/mortgage',
        query: {
          financing_type: MORTGAGE.REFINANCE_PARAM,
          ...to.query
        }
      }
    }
  }
]

export default routes
