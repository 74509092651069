/**
 * Transition Mixin
 * @desc sets the transition when navigating between steps
 */
export default {
  // Data
  data () {
    return {
      transitionName: 'step-next'
    }
  },

  // Mounted
  mounted () {
    this.$router.beforeEach((to, from, next) => {
      // If the base path changed
      if (to.matched[0].path !== from.matched[0].path) {
        this.transitionName = 'step-next'
      } else {
        this.transitionName = to.meta.index >= from.meta.index
          ? 'step-next'
          : 'step-prev'
      }

      next()
    })
  }
}
