module.exports = {
  colors: {
    transparent: 'transparent',

    current: 'currentColor',

    white: '#FFF',

    gray: {
      50: '#FBFBFD',
      100: '#F9F9FE',
      200: '#E6E6E6',
      300: '#DEDEDE',
      350: '#707070',
      400: '#4A4A4A',
      500: '#58595B'
    },

    black: {
      200: '#C1C2BF',
      300: '#868785',
      350: '#6E6E6E',
      400: '#51524F',
      500: '#242621',
      DEFAULT: '#242621'
    },

    green: {
      50: '#E6F8F5',
      100: '#CCEBE3',
      200: '#9CD8C8',
      300: '#6DC4AF',
      400: '#06BE9E',
      500: '#08A187',
      DEFAULT: '#06BE9E'
    },

    pinegreen: {
      DEFAULT: '#219653'
    },

    success: {
      DEFAULT: '#84BC66'
    },

    blue: {
      50: '#EAF4F9',
      100: '#CCEBF4',
      200: '#9AD8E7',
      300: '#6BC6DC',
      400: '#00BED9',
      500: '#009DBD',
      DEFAULT: '#00BED9'
    },

    purple: {
      50: '#EDEDF8',
      75: '#F4F5FF',
      100: '#D7DAF2',
      200: '#AFB4E5',
      300: '#8A91D8',
      400: '#7378D8',
      500: '#5957C1',
      DEFAULT: '#7378D8'
    },

    pink: {
      50: '#F9EFF1',
      100: '#FCDEDC',
      200: '#FBBEBB',
      300: '#F79F9A',
      400: '#FF8583',
      500: '#E26666',
      DEFAULT: '#FF8583'
    },

    yellow: {
      50: '#FFF9F1',
      100: '#FEEED6',
      200: '#FDDCAF',
      300: '#FACD89',
      400: '#FFC261',
      450: '#E7AD3E',
      500: '#C18515',
      DEFAULT: '#FFC261'
    },

    red: {
      400: '#DA3025',
      DEFAULT: '#DA3025'
    },

    teal: {
      500: '#B5FFF1'
    }
  }
}
