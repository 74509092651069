export default {
  state: {
    source_of_income: '',
    source_of_income_a: '',
    source_of_income_b: '',
    income_frequency: '',
    income_frequency_updated: '',
    monthly_income: '',
    hourly_income: '',
    annual_income: '',
    monthly_income_updated: '',
    hourly_income_updated: '',
    annual_income_updated: '',
    weekly_income_updated: '',
    biweekly_income_updated: '',
    semimonthly_income_updated: '',
    hours_per_week: '',
    hours_per_week_updated: '',
    employer_years: null,
    employer_months: null,
    company_name: '',
    job_title: ''
  },

  getters: {
    isEmployed (state) {
      switch (state.source_of_income) {
        case ('Employed'):
        case ('Self Employed'):
          return true
        default:
          return false
      }
    },

    askForConfirmIncome (state) {
      const monthlyIncome = state.monthly_income
      const incomeThresholdMin = 1800
      const incomeThresholdMax = 20000

      return monthlyIncome < incomeThresholdMin || monthlyIncome > incomeThresholdMax
    }
  },

  mutations: {
    SET_SOURCE_OF_INCOME (state, sourceOfIncome) {
      state.source_of_income = sourceOfIncome
    },

    SET_SOURCE_OF_INCOME_A (state, sourceOfIncome) {
      state.source_of_income_a = sourceOfIncome
    },

    SET_SOURCE_OF_INCOME_B (state, sourceOfIncome) {
      state.source_of_income_b = sourceOfIncome
    },

    SET_INCOME_FREQUENCY (state, incomeFrequency) {
      state.income_frequency = incomeFrequency
    },

    SET_INCOME_FREQUENCY_UPDATED (state, incomeFrequency) {
      state.income_frequency_updated = incomeFrequency
    },

    SET_MONTHLY_INCOME (state, monthlyIncome) {
      state.monthly_income = monthlyIncome
    },

    SET_MONTHLY_INCOME_UPDATED (state, monthlyIncome) {
      state.monthly_income_updated = monthlyIncome
    },

    SET_HOURLY_INCOME (state, hourlyIncome) {
      state.hourly_income = hourlyIncome
    },

    SET_HOURLY_INCOME_UPDATED (state, hourlyIncome) {
      state.hourly_income_updated = hourlyIncome
    },

    SET_HOURS_PER_WEEK (state, hoursPerWeek) {
      state.hours_per_week = hoursPerWeek
    },

    SET_HOURS_PER_WEEK_UPDATED (state, hoursPerWeek) {
      state.hours_per_week_updated = hoursPerWeek
    },

    SET_ANNUAL_INCOME (state, annualIncome) {
      state.annual_income = annualIncome
    },

    SET_ANNUAL_INCOME_UPDATED (state, annualIncome) {
      state.annual_income_updated = annualIncome
    },

    SET_WEEKLY_INCOME_UPDATED (state, weeklyIncome) {
      state.weekly_income_updated = weeklyIncome
    },

    SET_BIWEEKLY_INCOME_UPDATED (state, biweeklyIncome) {
      state.biweekly_income_updated = biweeklyIncome
    },

    SET_SEMIMONTHLY_INCOME_UPDATED (state, semimonthlyIncome) {
      state.semimonthly_income_updated = semimonthlyIncome
    },

    SET_EMPLOYER_YEARS (state, employerYears) {
      state.employer_years = employerYears
    },

    SET_EMPLOYER_MONTHS (state, employerMonths) {
      state.employer_months = employerMonths
    },

    SET_COMPANY_NAME (state, companyName) {
      state.company_name = companyName
    },

    SET_JOB_TITLE (state, jobTitle) {
      state.job_title = jobTitle
    },

    RESET_SOURCE_OF_INCOME_B (state) {
      state.source_of_income_b = ''
    },

    RESET_INCOME (state) {
      state.annual_income = ''
      state.monthly_income = ''
      state.hourly_income = ''
      state.hours_per_week = ''
    },

    RESET_INCOME_UPDATED (state) {
      state.annual_income_updated = ''
      state.weekly_income_updated = ''
      state.biweekly_income_updated = ''
      state.semimonthly_income_updated = ''
      state.monthly_income_updated = ''
      state.hourly_income_updated = ''
      state.hours_per_week_updated = ''
    }
  },

  actions: {
    setSourceOfIncome ({ commit }, sourceOfIncome) {
      commit('SET_SOURCE_OF_INCOME', sourceOfIncome)
      commit('SET_SOURCE_OF_INCOME_A', sourceOfIncome)
      commit('SET_INCOME_FREQUENCY', '')
      commit('RESET_INCOME')

      if (sourceOfIncome !== 'Other') {
        commit('SET_JOB_TITLE', '')
        commit('SET_COMPANY_NAME', '')
      }
    },

    setSourceOfIncomeOther ({ commit }, sourceOfIncome) {
      commit('SET_SOURCE_OF_INCOME', sourceOfIncome)
      commit('SET_SOURCE_OF_INCOME_B', sourceOfIncome)
      commit('SET_COMPANY_NAME', sourceOfIncome)
      commit('SET_JOB_TITLE', sourceOfIncome)
    },

    resetSourceOfIncomeB ({ commit }) {
      commit('RESET_SOURCE_OF_INCOME_B')
    },

    setIncomeFrequency ({ commit }, incomeFrequency) {
      commit('SET_INCOME_FREQUENCY', incomeFrequency)
    },

    setIncomeFrequencyUpdated ({ commit }, incomeFrequency) {
      commit('SET_INCOME_FREQUENCY_UPDATED', incomeFrequency)
    },

    resetIncome ({ commit }) {
      commit('RESET_INCOME')
    },

    resetIncomeUpdated ({ commit }) {
      commit('RESET_INCOME_UPDATED')
    },

    setMonthlyIncome ({ commit }, monthlyIncome) {
      commit('SET_MONTHLY_INCOME', monthlyIncome)
    },

    setMonthlyIncomeUpdated ({ commit }, monthlyIncome) {
      commit('SET_MONTHLY_INCOME_UPDATED', monthlyIncome)
    },

    setHourlyIncome ({ commit, state }, hourlyIncome) {
      const hoursPerWeek = state.hours_per_week || 0
      const monthlyIncome = convertHourlyToMonthlyIncome(hoursPerWeek, hourlyIncome)
      commit('SET_HOURLY_INCOME', hourlyIncome)
      commit('SET_MONTHLY_INCOME', monthlyIncome)
    },

    setHourlyIncomeUpdated ({ commit, state }, hourlyIncome) {
      const hoursPerWeek = state.hours_per_week_updated || 0
      const monthlyIncome = convertHourlyToMonthlyIncome(hoursPerWeek, hourlyIncome)
      commit('SET_HOURLY_INCOME_UPDATED', hourlyIncome)
      commit('SET_MONTHLY_INCOME_UPDATED', monthlyIncome)
    },

    setHoursPerWeek ({ commit, state }, hoursPerWeek) {
      const hourlyIncome = state.hourly_income
      const monthlyIncome = convertHourlyToMonthlyIncome(hoursPerWeek, hourlyIncome)
      commit('SET_HOURS_PER_WEEK', hoursPerWeek)
      commit('SET_MONTHLY_INCOME', monthlyIncome)
    },

    setHoursPerWeekUpdated ({ commit, state }, hoursPerWeek) {
      const hourlyIncome = state.hourly_income_updated
      const monthlyIncome = convertHourlyToMonthlyIncome(hoursPerWeek, hourlyIncome)
      commit('SET_HOURS_PER_WEEK_UPDATED', hoursPerWeek)
      commit('SET_MONTHLY_INCOME_UPDATED', monthlyIncome)
    },

    setAnnualIncome ({ commit }, annualIncome) {
      commit('SET_ANNUAL_INCOME', annualIncome)
      commit('SET_MONTHLY_INCOME', convertAnnualToMonthlyIncome(annualIncome))
    },

    setAnnualIncomeUpdated ({ commit }, annualIncome) {
      commit('SET_ANNUAL_INCOME_UPDATED', annualIncome)
      commit('SET_MONTHLY_INCOME_UPDATED', convertAnnualToMonthlyIncome(annualIncome))
    },

    setWeeklyIncomeUpdated ({ commit }, weeklyIncome) {
      commit('SET_WEEKLY_INCOME_UPDATED', weeklyIncome)
      commit('SET_MONTHLY_INCOME_UPDATED', convertWeeklyToMonthlyIncome(weeklyIncome))
    },

    setBiweeklyIncomeUpdated ({ commit }, weeklyIncome) {
      commit('SET_BIWEEKLY_INCOME_UPDATED', weeklyIncome)
      commit('SET_MONTHLY_INCOME_UPDATED', convertBiweeklyToMonthlyIncome(weeklyIncome))
    },

    setSemimonthlyIncomeUpdated ({ commit }, weeklyIncome) {
      commit('SET_SEMIMONTHLY_INCOME_UPDATED', weeklyIncome)
      commit('SET_MONTHLY_INCOME_UPDATED', convertSemimonthlyToMonthlyIncome(weeklyIncome))
    },

    setEmployerYears ({ commit }, employerYears) {
      commit('SET_EMPLOYER_YEARS', employerYears)
    },

    setEmployerMonths ({ commit }, employerMonths) {
      commit('SET_EMPLOYER_MONTHS', employerMonths)
    },

    setCompanyName ({ commit }, companyName) {
      commit('SET_COMPANY_NAME', companyName)
    },

    setJobTitle ({ commit }, jobTitle) {
      commit('SET_JOB_TITLE', jobTitle)
    }
  }
}

function convertAnnualToMonthlyIncome (annualIncome) {
  return +(+annualIncome / 12).toFixed(2)
}

function convertHourlyToMonthlyIncome (hoursPerWeek, hourlyIncome) {
  return +(+hoursPerWeek * +hourlyIncome * 52 / 12).toFixed(2)
}

function convertWeeklyToMonthlyIncome (weeklyIncome) {
  return +(+weeklyIncome * 52 / 12).toFixed(2)
}

function convertBiweeklyToMonthlyIncome (biweeklyIncome) {
  return +(+biweeklyIncome * 26 / 12).toFixed(2)
}

function convertSemimonthlyToMonthlyIncome (semiMonthlyIncome) {
  return +(+semiMonthlyIncome * 2).toFixed(2)
}
