import OFFER_STATUS from '@/constants/offer-status'

export default {
  namespaced: true,

  state: {
    opportunity: {},
    loan: {}
  },

  getters: {
    userData (state, getters, rootState) {
      return rootState.offers.opportunity
    },

    loanData (state, getters, rootState) {
      return rootState.offers.loan
    },

    isOffer (state, getters, rootState) {
      const { tracking } = rootState
      const appSources = ['springoffer']
      return appSources.some(src => tracking.app_source.includes(src))
    },

    bloomValue (state, getters, rootState) {
      const crm = rootState.account.crm
      const offerData = rootState.offers.loan

      return crm === 'odoo' ? offerData.bloomSubscription : offerData.bloomPayment
    }
  },

  mutations: {
    SET_OFFER_DATA (state, offer) {
      const { opportunity, loan } = offer
      state.opportunity = opportunity
      state.loan = loan
    },

    SET_OFFER_STATUS (state, status) {
      state.opportunity.status = status
    },

    SET_LOAN_DATA (state, loan) {
      state.loan = loan
    },

    SET_OFFER_FINISHED (state) {
      state.opportunity.status = OFFER_STATUS.COMPLETED
    },

    SET_OFFER_AGREEMENT_DATA (state, contract) {
      state.loan.contract = contract
    }
  },

  actions: {
    setOfferData ({ commit }, opportunity) {
      commit('SET_OFFER_DATA', opportunity)
    },

    setOfferStatus ({ commit }, status) {
      commit('SET_OFFER_STATUS', status)
    },

    setLoanData ({ commit }, loan) {
      commit('SET_LOAN_DATA', loan)
    },

    setOfferFinished ({ commit }) {
      commit('SET_OFFER_FINISHED')
    },

    setOfferAgreementData ({ commit }, contract) {
      commit('SET_OFFER_AGREEMENT_DATA', contract)
    }
  }
}
