import Vue from 'vue'
import Vuex from 'vuex'
import application from './modules/application'
import loans from './modules/loans'
import mortgages from './modules/mortgages'
import location from './modules/location'
import customer from './modules/customer'
import employment from './modules/employment'
import tracking from './modules/tracking'
import documents from './modules/documents'
import returningCustomer from './modules/returning-customer'
import account from './modules/account'
import chat from './modules/chat'
import appShellButton from './modules/app-shell-button'
import offers from './modules/offers'
import evergreen from './modules/evergreen'
import foundation from './modules/foundation'
import validations from './modules/validations'
import abTest from './modules/abTest'
import '@/utilities/vue-config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    application,
    loans,
    mortgages,
    location,
    customer,
    employment,
    foundation,
    tracking,
    documents,
    returningCustomer,
    account,
    appShellButton,
    offers,
    evergreen,
    chat,
    validations,
    abTest
  }
})
