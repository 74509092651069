<template>
  <div
    class="app-button"
    role="button"
    :class="[computedStyles, computedBackgrounds, computedBorders, computedTextColor]"
    tabindex="0"
    :aria-disabled="disabled"
    @keydown.enter="$emit('click')"
    v-on="$listeners"
  >
    <div v-if="text && !loading">
      <p>{{ text }}</p>
    </div>

    <slot v-else-if="!loading" />

    <div
      v-if="loading"
      class="app-button__loading-wrapper"
    >
      <AppSpinner color="#fff" />
    </div>
  </div>
</template>

<script>
import theme from '@/mixins/theme'
import AppSpinner from '@/components/common/spinners/AppSpinner'

export default {
  name: 'AppButton',

  // Components
  components: {
    AppSpinner
  },

  // Mixins
  mixins: [theme],

  // Props
  props: {
    text: {
      type: String,
      default: ''
    },

    animated: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      validator (value) {
        return ['default', 'small', 'fab'].includes(value)
      },
      default: 'default'
    },

    outline: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    fullWidth: {
      type: Boolean,
      default: false
    },

    colorText: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  // Computed
  computed: {
    computedStyles () {
      return [{
        'app-button--animated': this.animated,
        'app-button--outline': this.outline,
        'app-button--loading': this.loading,
        'app-button--full': this.fullWidth
      }, `app-button--${this.size}`]
    },
    computedBackgrounds () {
      if (this.outline) {
        return this.loading ? this.bgColorClass(400) : `bg-white ${this.bgColorClass(400, 'hover')}`
      }
      return this.bgColorClass()
    },
    computedBorders () {
      return this.outline ? this.borderColorClass(400) : ''
    },
    computedTextColor () {
      return this.colorText ? this.textColorClass() : 'text-white'
    }
  }
}
</script>

<style scoped lang="scss">
.app-button {
  @apply relative rounded-full h-60 flex justify-center items-center;
  @apply leading-none text-center text-lg font-semibold;

  &:focus {
    outline: none;
  }

  &[aria-disabled] {
    @apply grayscale opacity-[0.38] pointer-events-none;
  }

  &--default {
    @apply px-40 max-w-500 text-base;

    @screen xxs {
      @apply text-lg px-50;
    }

    @screen lg {
      @apply max-w-400;
    }
  }

  &--small {
    @apply w-auto text-sm;

    @screen xxs {
      @apply text-base min-w-[140px];
    }

    @screen lg {
      width: auto;
    }
  }

  &--full {
    @apply w-full;
  }

  &--animated {
    @apply transition;

    &:hover,
    &:focus {
      @apply shadow-btn sm:translate-y-[-5px];
    }
  }

  &--outline {
    @apply text-black border-2;
    @apply flex justify-center;

    &:hover,
    &:focus {
      @apply text-white;
    }
  }

  &--loading {
    @apply text-white p-[11px];
  }

  &--fab {
    @apply text-base h-60 w-60;

    :deep() svg {
      @apply absolute left-[50%] top-[50%];

      transform: translate(-50%, -50%);
    }
  }

  &__loading-wrapper {
    @apply flex justify-center;
  }
}
</style>
