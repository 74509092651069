/**
 * Progress Mixin
 * @desc Responsible for handing the data needed for AppProgressBar
 * Import into the App Shell and add the computed properties as props to AppProgressBar
 */
export default {
  // Computed
  computed: {
    $_progress_currentPath () {
      return this.$route.path
    },

    $_progress_parentPath () {
      return this.$route.matched[0].path
    },

    $_progress_routes () {
      return this.$router.options.routes
    },

    $_progress_label () {
      return this.$route.meta.progressLabel
    },

    $_progress_routeChildren () {
      const route = this.$_progress_routes.find(route => route.path === this.$_progress_parentPath)
      return route ? route.children : null
    },

    $_progress_routeChildrenSteps () {
      if (!this.$_progress_routeChildren) return
      const steps = this.$_progress_routeChildren.filter((item) => {
        return item.meta.progress !== false
      })
      return steps
    },

    $_progress_totalSteps () {
      if (!this.$_progress_routeChildren) return
      return this.$_progress_routeChildrenSteps.length
    },

    $_progress_currentStepIndex () {
      const stepIndex = this.$_progress_routeChildrenSteps.findIndex(
        (route) => route.path
          ? `${this.$_progress_parentPath}/${route.path}` === this.$_progress_currentPath
          : this.$_progress_parentPath === this.$_progress_currentPath
      )
      return Math.max(stepIndex, 0) + 1
    },

    $_progress_visible () {
      return this.$route.meta.progress !== false || this.$route.meta.displayProgressBar === true
    }
  }
}
