import DataLayer from '@/utilities/data-layer'
import store from '@/store'

const cookieExpiryTime = process.env.VUE_APP_AB_TESTING_DURATION_DAYS * 86400 || 2592000 // env * days or 30 Days as default

export default class Cookies {
  static getCookie (cookieKey) {
    return decodeURIComponent(
      document.cookie.replace(
        new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(cookieKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'),
        '$1'
      )
    ) || undefined
  }

  static setCookie (cookieKey, cookieValue, maxAge = cookieExpiryTime) {
    // convert cookieValue to string
    cookieValue = JSON.stringify(cookieValue)
    document.cookie = `${cookieKey}=${cookieValue}; max-age=${maxAge}; path=/`
  }

  static async autoFillCookieData (cookieKey, path, module) {
    const cookieData = await this.getCookie(cookieKey)

    if (!cookieData) return false
    const { email, consent, timestamp, firstName, partnerId, lastName } = JSON.parse(cookieData)
    if (!email) return false
    let startDateInSecs = new Date(timestamp)
    startDateInSecs = (startDateInSecs.getTime() / 1000)
    const timediff = (Math.floor(Date.now() / 1000) - startDateInSecs).toFixed(2)
    store.dispatch('setEmail', email)
    store.dispatch('setFirstName', firstName)
    store.dispatch('setLastName', lastName)
    store.dispatch('setConsentCEM', +consent)
    store.dispatch('setShouldSkipEmailStep', true)

    const dataLayerEvent = !partnerId ? `spring_${module}_email_landing-auto-fill` : `spring_${module}_${partnerId}_landing-auto-fill`

    DataLayer.pushObject({
      event: dataLayerEvent,
      path: path,
      timestamp,
      seconds: timediff
    })

    return true
  }

  static getAbTestWhitelistRules (module) {
    // check if module is in whitelist from store and return true if it is
    // return store.state.abTest.ab_tests_enabled_modules.includes(module)
    // get enabled test object from store map
    const enabledModules = store.state.abTest.ab_tests_enabled_modules
    return enabledModules.get(module)
  }

  // checks if module cookie exists
  // if not it sets the module cookie
  // updates store data
  static async syncAbTestCookieToStore (module) {
    if (!store.state.abTest.ab_testing_enabled) return
    // get module rules from enabled modules list
    const moduleRules = this.getAbTestWhitelistRules(module)
    // if module is not in whitelist return
    if (!moduleRules) return

    const cookieKey = 'sf-ab-test_' + module
    const abTestCookie = await this.getCookie(cookieKey)

    let abTest = {}

    if (!abTestCookie) { // if no cookie or expired
      const testDistributionRate = moduleRules.rate
      const variant = Math.random() * 100 < testDistributionRate ? 'variant' : 'control'
      abTest = { variant, module, rate: testDistributionRate }
      await this.setCookie(cookieKey, abTest, cookieExpiryTime)
    } else {
      abTest = JSON.parse(abTestCookie)
    }

    store.dispatch('setActiveAbTest', abTest)
  }
}
