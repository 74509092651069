export default {
  namespaced: true,

  state: {
    is_foundation_customer: undefined
  },

  getters: {},

  mutations: {
    SET_IS_FOUNDATION_CUSTOMER (state, isFoundationCustomer) {
      state.is_foundation_customer = isFoundationCustomer
    }
  },

  actions: {
    setIsFoundationCustomer ({ commit }, isFoundationCustomer) {
      commit('SET_IS_FOUNDATION_CUSTOMER', isFoundationCustomer)
    }
  }
}
