<template>
  <div class="step-sticky-cta">
    <div class="step-sticky-cta__btns">
      <AppLink
        v-if="showBackButton"
        class="step-sticky-cta__back"
        icon="chevron-left"
        icon-position="left"
        :theme="theme"
        :to="backPath"
      >
        Back
      </AppLink>

      <AppButton
        v-if="showAppButton"
        full-width
        :theme="theme"
        :loading="loading || continueShortCircuit"
        @click="handleContinue"
      >
        {{ continueText }}
      </AppButton>
    </div>

    <StepSkip
      v-if="showSkipStep"
      :verification-step="skipStepProp"
      :theme="theme"
      @skip="handleSkip"
    />

    <a
      v-if="showContinueByText"
      :class="['step-sticky-cta__skip-link', textColorClass()]"
      :href="`sms:${continueByTextNumber}?&body=${continueByTextBody}`"
      @click="handleContinueByText"
    >
      Continue by Text Message
    </a>

    <button
      v-if="showSubmitNowButton"
      class="step-sticky-cta__skip-link"
      :class="textColorClass()"
      @click="handleSubmit"
    >
      {{ submitText }}

      <AppSpinner
        v-if="loading"
        class="mx-auto mt-20"
        color="#009DBD"
      />
    </button>

    <button
      v-if="showStickyAddressFallBack"
      class="step-sticky-cta__address-link"
      :class="textColorClass()"
      @click="handleStickyAddressFallBack"
    >
      Let Me Type in Manually
    </button>
    <p
      v-if="showBankSkip && showBankSkipStep"
      class="step-sticky-cta__skip-text"
    >
      <span v-if="!bankSkipBasic">
        In order to send you your money we will need to verify your banking.
        <br><br>
      </span>
      If you do not have access to your online banking please
      <strong
        :class="[textColorClass(), borderColorClass()]"
        @click="handleSkip"
      >
        click here
      </strong>
    </p>
  </div>
</template>

<script>
import theme from '@/mixins/theme'
import navigation from '@/mixins/navigation'
import confirmationNumber from '@/mixins/confirmation-number'
import submit from '@/mixins/submit'
import AppButton from '@/components/common/buttons/AppButton'
import AppLink from '@/components/common/buttons/AppLink'
import StepSkip from '@/components/common/step-layout/StepSkip'
import AppSpinner from '@/components/common/spinners/AppSpinner'

export default {
  // Name
  name: 'StepStickyCTA',

  components: {
    AppButton,
    AppLink,
    StepSkip,
    AppSpinner
  },

  mixins: [
    navigation,
    theme,
    submit,
    confirmationNumber
  ],

  props: {
    showBankSkip: {
      default: false,
      type: Boolean
    },

    continueShortCircuit: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      continueByTextNumber: process.env.VUE_APP_CONTINUE_BY_TEXT_NUMBER
    }
  },

  computed: {
    addressFallBack () {
      return this.$store.state.location.addressFallback
    },

    showStickyAddressFallBack () {
      return this.$route.meta.showStickyAddressFallback && !this.$store.state.location.addressFallback
    },

    showBackButton () {
      return this.$route.meta.showBackButton
    },

    backPath () {
      return this.$_navigation_backPath
    },

    showAppButton () {
      return this.$route.meta.showAppButton
    },

    showSkipStep () {
      return this.$route.meta.showSkipStep
    },

    skipStepProp () {
      return this.$route.meta.skipStepProp
    },

    showBankSkipStep () {
      return this.$route.meta.showBankSkipStep
    },

    bankSkipBasic () {
      return this.$route.meta.bankSkipBasic
    },

    showSubmitNowButton () {
      return this.$route.meta.showSubmitNowButton
    },

    showContinueByText () {
      return this.$route.meta.showContinueByText && process.env.VUE_APP_CONTINUE_BY_TEXT_ENABLED
    },

    isFoundationGold () {
      return this.$store.state.foundation.isFoundationGold
    },

    loading () {
      return this.$store.state.appShellButton.triggerContinue
    },

    dynamicFoundationContinueText () {
      return this.$route.meta.dynamicContinueText
    },

    continueText () {
      if (this.dynamicFoundationContinueText) {
        return this.isFoundationGold ? 'Choose Gold' : 'Choose Basic'
      }
      return this.$route.meta.continueText ? this.$route.meta.continueText : 'Continue'
    },

    submitIfReady () {
      const submitIfReady = this.$route.meta.submitIfReady
      return typeof submitIfReady === 'function' ? submitIfReady() : submitIfReady
    },

    submitText () {
      return this.submitIfReady ? 'Submit Now and Upload Later' : 'Upload Later'
    },

    continueByTextBody () {
      return process.env.VUE_APP_CONTINUE_BY_TEXT_MESSAGE + ' Confirmation Number: ' + this.confirmationNumber
    }
  },

  methods: {
    handleContinue () {
      if (this.continueShortCircuit) return
      this.$store.dispatch('setTriggerContinue', true)
    },

    handleSkip () {
      this.$store.dispatch('setTriggerSkipStep', true)
    },

    handleSubmit () {
      if (this.submitIfReady) return this.$store.dispatch('setTriggerSubmitNow', true)
      this.$store.dispatch('setUploadLater', true)
      this.$_navigation_handleNext()
    },

    handleStickyAddressFallBack () {
      this.$store.dispatch('setAddressFallback', true)
    },

    handleContinueByText () {
      this.$_submit_clickedContinueByText('personal-loans')
      this.handleSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
.step-sticky-cta {
  @apply w-full;

  &__btns {
    @apply flex items-center justify-center;
  }

  &__back {
    @apply mr-60;
  }

  &__skip-link {
    @apply block underline font-semibold mx-auto mt-15 mb-10 text-center text-sm;
  }

  &__address-link {
    @apply block underline font-semibold mx-auto mt-20 mb-10 text-center text-base;
  }

  &__skip-text {
    color: #9d9a9a;

    @apply text-left;

    @screen md {
      @apply text-center;
    }

    strong {
      @apply cursor-pointer font-semibold border-b;
    }
  }
}
</style>
