<template>
  <button
    v-if="showBtn"
    class="live-chat-btn"
    :class="liftContactButton"
    title="Existing Customer? Chat Now"
    @click="click"
  >
    <AppButton
      theme="purple"
      size="fab"
      :loading="isLoading"
    >
      <font-awesome-icon
        :icon="['fas', 'comments']"
        size="2x"
      />
    </AppButton>
  </button>
</template>

<script>
/**
 * Customize Embedded Chat Documentation:
 * https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_customize_chat.htm
 */

import AppButton from '@/components/common/buttons/AppButton'

const chatIconPurple = require('@/assets/img/icon-purple.svg')
const chatIconWhite = require('@/assets/img/icon-white.svg')

export default {
  name: 'LiveChat',

  components: {
    AppButton
  },

  data () {
    return {
      isChatStarted: false,
      isLoading: false,
      isAgentAvailable: false
    }
  },

  computed: {
    showBtn () {
      if (this.$route.meta.hideChatButton) return false
      return !this.isChatStarted
    },
    firstName () {
      return this.$store.state.customer.first_name
    },
    lastName () {
      return this.$store.state.customer.last_name
    },
    email () {
      return this.$store.state.customer.email
    },
    phone () {
      return this.$store.state.customer.home_phone
    },
    birthDate () {
      return this.$store.state.customer.birth_date
    },
    postalCode () {
      return this.$store.state.location.postal_code
    },
    cduid () {
      return this.$store.state.application.cduid
    },
    isChatOpen () {
      return this.$store.state.chat.is_chat_open
    },
    liftContactButton () {
      return this.$route.meta.liftContactButton ? 'live-chat-btn__raised' : ''
    }
  },

  watch: {
    isChatOpen (val) {
      if (val) {
        this.click()
        this.$store.dispatch('chat/setChatOpen', false)
      }
    }
  },

  mounted () {
    this.mountSalesforceScript()
  },

  methods: {
    click () {
      this.isLoading = true
      window.embedded_svc.bootstrapEmbeddedService()
    },

    addInputEventListeners () {
      const inputList = document.querySelectorAll('.uiInput--default input')
      inputList.forEach((input) => {
        input.addEventListener('input', (event) =>
          this.handleInputChange(input, event)
        )
      })
    },

    dateFieldInputFormat () {
      const dateInput = document.querySelector('.Birth_Date__c')
      if (!dateInput) return
      const datePlaceholderFormat = 'DD/MM/YYYY'
      dateInput.placeholder = datePlaceholderFormat
      dateInput.addEventListener('input', (event) => {
        dateInput.value = this.convertToDateFormat(event.target.value)
        dateInput.placeholder = datePlaceholderFormat
      })
      dateInput.addEventListener('change', (event) => {
        if (dateInput.value.length < 10) {
          dateInput.value = ''
          this.invalidWrapperClass(dateInput, true)
        } else {
          this.invalidWrapperClass(dateInput, false)
        }
        dateInput.placeholder = datePlaceholderFormat
      })
    },

    addressInputFormat () {
      const zipInput = document.querySelector('.Postal_Code__c')
      if (!zipInput) return
      const zipPlaceholder = 'A1A 1A1'
      zipInput.placeholder = zipPlaceholder
      zipInput.addEventListener('input', (event) => {
        zipInput.value = this.convertToZipFormat(event.target.value)
      })
      zipInput.addEventListener('change', (event) => {
        if (zipInput.value.length < 7) {
          zipInput.value = ''
          this.invalidWrapperClass(zipInput, true)
        } else {
          this.invalidWrapperClass(zipInput, false)
        }
        zipInput.placeholder = zipPlaceholder
      })
    },

    convertToDateFormat (string) {
      const clearStr = this.clearNonNumbers(string)
      let formatted = clearStr

      if (clearStr.length <= 2) {
        return formatted
      } else if (clearStr.length <= 4) {
        formatted = `${clearStr.slice(0, 2)}/${clearStr.slice(2, 4)}`
      } else {
        formatted = `${clearStr.slice(0, 2)}/${clearStr.slice(2, 4)}/${clearStr.slice(4)}`
      }

      return this.limitDigits(formatted, 10)
    },

    convertToZipFormat (string) {
      const clearStr = string
        .replace(/[^\w\s]/gi, '')
        .replace(' ', '')
        .toUpperCase()
      let formatted = clearStr
      if (clearStr.length <= 3) {
        return formatted
      } else {
        formatted = `${clearStr.slice(0, 3)} ${clearStr.slice(3)}`
      }
      return this.limitDigits(formatted, 7)
    },

    phoneFieldFormat () {
      const phoneInput = document.querySelector('.Phone__c')
      if (!phoneInput) return
      const phonePlaceholder = '9999999999'
      phoneInput.placeholder = phonePlaceholder
      phoneInput.addEventListener('input', (event) => {
        phoneInput.value = this.clearAndLimitNumber(event.target.value, 12)
      })
      phoneInput.addEventListener('change', (event) => {
        if (phoneInput.value.length < 10) {
          phoneInput.value = ''
          this.invalidWrapperClass(phoneInput, true)
        } else {
          this.invalidWrapperClass(phoneInput, false)
        }
        phoneInput.placeholder = phonePlaceholder
      })
    },

    handleInputChange (input, event) {
      const parentDiv = input.closest('.uiInput')
      const value = event.target.value
      if (!value) {
        parentDiv.classList.remove('filled')
        return
      }
      parentDiv.classList.add('filled')
    },

    clearNonNumbers (string) {
      return string.replace(/\D/g, '')
    },

    limitDigits (string, limit) {
      return string.substring(0, limit)
    },

    clearAndLimitNumber (string, limit) {
      const clearValue = string.replace(/\D/g, '')
      return clearValue.substring(0, limit)
    },

    invalidWrapperClass (input, isInvalid) {
      const wrapper = input.closest('.uiInput')
      if (isInvalid) {
        wrapper.classList.add('invalid')
        return
      }

      wrapper.classList.remove('invalid')
    },

    attachHeader () {
      // Prevents from re-attaching header when refreshing page with chat with agent active
      const messageArea = document.querySelector('.messageArea, .dialogState, .waitingGreetingContent')
      if (messageArea) return

      const formWrapper = document.querySelector('.stateBody')
      let headerWrapper = document.querySelector('.live-chat-header')

      if (headerWrapper) return

      headerWrapper = document.createElement('div')
      headerWrapper.classList.add('live-chat-header')
      formWrapper.insertAdjacentElement('afterbegin', headerWrapper)

      this.attachHeaderIcon()
      this.attachTitle()

      if (!this.isAgentAvailable) {
        this.updateSubtitleMessage(
          `Our agents are currently unavailable. Please fill out this form and we'll text you when our agents are back.`
        )
        this.phoneFieldFormat()
      }
    },

    attachHeaderIcon () {
      let iconNode = document.querySelector('.live-chat-header__icon')

      if (iconNode) return

      const headerWrapper = document.querySelector('.live-chat-header')

      iconNode = document.createElement('img')
      iconNode.src = chatIconPurple
      iconNode.classList.add('live-chat-header__icon')

      headerWrapper.insertAdjacentElement('afterbegin', iconNode)
    },

    attachTitle () {
      // Ensures no title attachment duplicity from SF's native title element on certain states
      let titleNode = document.querySelector('.waitingGreeting')
      if (titleNode) return

      titleNode = document.createElement('h1')
      titleNode.innerHTML = this.isAgentAvailable
        ? 'Welcome to the <br/>Spring Live Chat!'
        : 'Welcome to the <br/>Spring Chat!'
      titleNode.classList.add('live-chat-header__title')

      const headerWrapper = document.querySelector('.live-chat-header')
      headerWrapper.insertAdjacentElement('beforeend', titleNode)
    },

    updateSubtitleMessage (message = '') {
      // Ensures that a node is created in case it does not exist already, otherwise only updates inner text
      let subtitleNode = document.querySelector('.live-chat-header__subtitle')

      if (!subtitleNode) {
        subtitleNode = document.createElement('p')
        subtitleNode.classList.add('live-chat-header__subtitle')
      }

      const headerWrapper = document.querySelector('.live-chat-header')
      subtitleNode.innerText = message
      headerWrapper.insertAdjacentElement('beforeend', subtitleNode)
    },

    updateUserWelcomeMessage () {
      const welcomeMessage = document.querySelector('.waitingGreetingContent')
      welcomeMessage.innerHTML = `<h3>Hello, ${this.firstName || 'Guest'}!</h3>`
    },

    mountSalesforceScript () {
      const sfCDUrl = process.env.VUE_APP_LC_SF_CD_URL
      const sfCommunityUrl = process.env.VUE_APP_LC_SF_COMMUNITY_URL
      const embedHexCode = process.env.VUE_APP_LC_EMBED_HEX_CODE
      const chatName = process.env.VUE_APP_LC_CHAT_NAME
      const baseLiveAgentContentURL = process.env.VUE_APP_LC_BASE_LIVE_AGENT_CONTENT_URL
      const deploymentId = process.env.VUE_APP_LC_DEPLOYMENT_ID
      const buttonId = process.env.VUE_APP_LC_BUTTON_ID
      const baseLiveAgentURL = process.env.VUE_APP_LC_BASE_LIVE_AGENT_URL
      const eswLiveAgentDevName =
        process.env.VUE_APP_LC_ESW_LIVE_AGENT_DEV_NAME
      const scriptSrc = process.env.VUE_APP_LC_SCRIPT_SRC
      const isOfflineSupportEnabled =
        process.env.VUE_APP_LC_OFFLINE_SUPPORT_ENABLED

      const initESW = (gslbBaseURL) => {
        const embeddedSvc = window.embedded_svc

        const MINIMIZED_CHAT_DEFAULT_TEXT = 'Chat With Us'

        const { settings } = embeddedSvc

        settings.displayHelpButton = false
        settings.smallCompanyLogoImgURL = chatIconWhite
        settings.defaultMinimizedText = MINIMIZED_CHAT_DEFAULT_TEXT
        settings.disabledMinimizedText = MINIMIZED_CHAT_DEFAULT_TEXT
        settings.offlineSupportMinimizedText = MINIMIZED_CHAT_DEFAULT_TEXT
        settings.enabledFeatures = ['LiveAgent']
        settings.entryFeature = 'LiveAgent'
        settings.extraPrechatFormDetails = [
          {
            label: 'URL',
            value: window.location.href,
            transcriptFields: ['URL__c'],
            displayToAgent: true
          },
          {
            label: 'First Name',
            value: this.firstName,
            transcriptFields: ['First_Name__c'],
            displayToAgent: true
          },
          {
            label: 'Last Name',
            value: this.lastName,
            transcriptFields: ['Last_Name__c'],
            displayToAgent: true
          },
          {
            label: 'Email',
            value: this.email,
            transcriptFields: ['Email__c'],
            displayToAgent: true
          },
          {
            label: 'Phone',
            value: this.phone,
            transcriptFields: ['Phone__c'],
            displayToAgent: true
          },
          {
            label: 'CDUID',
            value: this.cduid,
            transcriptFields: ['CDUID__c'],
            displayToAgent: true
          },
          {
            label: 'Date of Birth',
            value: this.birthDate,
            transcriptFields: ['Birth_Date__c'],
            displayToAgent: true
          },
          {
            label: 'Postal Code',
            transcriptFields: ['Postal_Code__c'],
            value: this.postalCode,
            displayToAgent: true
          },
          {
            label: 'Product',
            value: 'Personal Loan',
            transcriptFields: ['Product__c'],
            displayToAgent: true
          }
        ]

        embeddedSvc.init(
          sfCDUrl,
          sfCommunityUrl,
          gslbBaseURL,
          embedHexCode,
          chatName,
          {
            baseLiveAgentContentURL,
            deploymentId,
            buttonId,
            baseLiveAgentURL,
            eswLiveAgentDevName,
            isOfflineSupportEnabled
          }
        )

        /**
         * Chat events
         * https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_chat_events.htm
         */

        embeddedSvc.addEventHandler('onAvailability', (data) => {
          this.isAgentAvailable = data.isAgentAvailable
        })

        embeddedSvc.addEventHandler('onSettingsCallCompleted', (data) => {
          this.isAgentAvailable = data.isAgentAvailable
        })

        embeddedSvc.addEventHandler('afterMinimize', () => {
          // Set to true in case page loads with already-minimized chat.

          this.isChatStarted = true
          this.isLoading = false

          this.$store.dispatch('chat/setChatOpen', false)
        })

        embeddedSvc.addEventHandler('afterMaximize', () => {
          this.isChatStarted = true
          this.isLoading = false
          this.addInputEventListeners()
          this.attachHeader()
          this.addressInputFormat()
          this.dateFieldInputFormat()
          this.isAgentAvailable ? this.updateUserWelcomeMessage() : this.phoneFieldFormat()
        })

        embeddedSvc.addEventHandler('afterDestroy', () => {
          this.isChatStarted = false
        })
      }

      if (!window.embedded_svc) {
        const s = document.createElement('script')
        s.setAttribute('src', scriptSrc)
        s.onload = () => {
          initESW(null)
        }
        document.body.appendChild(s)
      } else {
        initESW('https://service.force.com')
      }
    }
  }
}
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.embeddedServiceSidebarFeature {
  &.featureBody {
    @apply overflow-y-scroll #{!important};
  }
}

.embeddedServiceSidebarState {
  &.stateBody {
    @apply flex flex-col h-auto #{!important};
  }

  &.embeddedServiceSidebarDialogState,
  &.embeddedServiceLiveAgentStateChat {
    @apply h-full #{!important};
  }

  &.embeddedServiceLiveAgentStateWaiting {
    @apply h-full pt-60 #{!important};
  }
}

.live-chat-btn {
  @apply bottom-[15px] fixed right-[15px] z-max;

  &__raised {
    @apply bottom-[100px];
  }

  @screen sm {
    @apply bottom-[15px];
  }

  @screen lg {
    @apply bottom-[50px] right-[50px];
  }
}

// Hide chat button when route transitions
// to prevent it from floating up
.step-next-leave-active .live-chat-btn,
.step-next-enter-active .live-chat-btn,
.step-prev-leave-active .live-chat-btn,
.step-prev-enter-active .live-chat-btn {
  @apply hidden;
}

.live-chat-header {
  @apply pt-10 pb-20 px-15 max-w-500 mx-auto;

  &__icon {
    @apply max-h-40 mx-auto mt-10 mb-[23px];
  }

  &__title {
    @apply text-xl px-20;
  }

  &__subtitle {
    @apply text-base font-body mt-15 mb-0;
  }
}

.embeddedServiceSidebarMinimizedDefaultUI {
  @apply w-200 h-40 rounded-20 bg-purple-500 #{!important};

  .content {
    @apply px-0 #{!important};
  }

  &.minimizedContainer {
    @apply bottom-[15px] right-[15px] px-10 rounded-full shadow-none transition #{!important};
  }

  &.minimizedContainer:hover {
    @apply shadow-btn translate-y-[-5px] #{!important};
  }

  &.minimizedContainer:focus {
    @apply bottom-[15px] right-[15px] no-underline #{!important};
  }

  .minimizedImage img,
  .minimizedImage .uiImage {
    @apply h-20 rounded-none w-20 #{!important};
  }

  &.helpButton,
  &:not(.helpButton) {
    .messageContent {
      @apply px-20 py-10 m-0 flex-row items-center justify-between #{!important};
    }
  }

  .minimizedText {
    @apply w-full #{!important};

    .message {
      @apply font-bold text-base #{!important};
    }
  }
}

.embeddedServiceSidebarButton {
  @apply rounded-30 h-40;

  &.uiButton:not(.dialog-button-1) {
    @apply bg-purple-500 text-white font-bold #{!important};

    &.uiButton--inverse {
      &:hover {
        @apply shadow-none #{!important};
      }

      &.waitingCancelChat,
      &.dialogButton {
        @apply rounded-30 text-white #{!important};
      }

      &.saveTranscriptButton,
      &.dialog-button-1 {
        @apply bg-transparent text-purple-500 font-bold underline #{!important};

        .label {
          @apply bg-transparent text-purple-500 font-bold underline #{!important};
        }

        &:hover {
          @apply text-purple-400 no-underline shadow-none duration-500 #{!important};
        }
      }
    }

    .label {
      @apply text-white no-underline #{!important};
    }
  }

  &:not(.uiButton--inverse) {
    @apply bg-purple-500 font-bold p-10 rounded-full transition #{!important};
  }

  &:focus {
    @apply no-underline #{!important};
  }

  &:not(:disabled),
  &:not(.saveTranscriptButton),
  &:not(.dialog-button-1) {
    &:focus {
      @apply shadow-btn #{!important};
    }

    &:hover {
      @apply shadow-btn translate-y-[-5px] #{!important};
    }

    &:not(.uiButton--inverse) {
      &:focus {
        @apply shadow-btn #{!important};
      }

      &:hover {
        @apply shadow-btn translate-y-[-5px] #{!important};
      }
    }
  }

  &.uiButton--inverse .label,
  &.saveTranscriptButton,
  &.dialog-button-1 {
    @apply bg-transparent text-purple-500 font-bold underline #{!important};

    &:hover {
      @apply shadow-none #{!important};
    }
  }

  .label {
    @apply leading-none overflow-visible #{!important};
  }
}

.embeddedServiceLiveAgentStateChatAction {
  &.uiButton {
    svg {
      fill: theme("colors.purple.500") !important;
    }
  }
}

.embeddedServiceSidebar {
  .sidebarBody {
    @apply bg-transparent overflow-y-auto #{!important};
  }

  &.layout-docked .dockableContainer,
  &.layout-float .dockableContainer {
    @apply rounded-b-20 #{!important};

    @screen lg {
      @apply max-h-[630px];
    }
  }

  @screen sm {
    &.layout-docked .dockableContainer,
    &.layout-float .dockableContainer {
      @apply rounded-none #{!important};
    }
  }

  &.layout-docked .dockableContainer {
    background: linear-gradient(0deg, #fff 57%, #ededff 100%) !important;

    @apply border-4 border-solid border-white right-[15px] rounded-t-20 #{!important};
  }
}

.embeddedServiceSidebarForm {
  .backgroundImg {
    @apply bg-transparent #{!important};
  }

  &.formContent {
    @apply bg-transparent pt-5 mb-70 max-w-500 mx-auto #{!important};
  }

  &.buttonWrapper {
    @apply bg-white py-15 pr-25 pl-20 fixed #{!important};

    box-shadow: 0 -4px 8px #00000015;
  }

  .fieldList {
    @apply mt-0 mx-15 #{!important};
  }
}

.embeddedServiceSidebarFormField {
  @apply ml-0 w-full #{!important};

  .slds-style-inputtext,
  .slds-style-select {
    @apply border-2 border-purple-100 rounded-full shadow-none #{!important};
  }

  .slds-style-inputtext:focus,
  .slds-style-inputtext:focus-visible,
  .slds-style-select:focus-visible {
    @apply shadow-none #{!important};
  }

  .uiInput {
    @apply relative mb-0 #{!important};

    .input,
    .select {
      @apply text-black-500 border-[2.5px] border-purple-100 duration-300 #{!important};
      @apply px-[24px] py-10 max-h-[41px] mb-[12px] #{!important};

      &::placeholder {
        @apply text-transparent;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
      }
    }

    .required {
      @apply hidden;
    }

    &.filled {
      &.has-error,
      &.invalid {
        .input {
          @apply border-red #{!important};

          &::placeholder {
            @apply text-gray-300;
          }
        }
      }
    }

    .uiLabel {
      @apply truncate text-black-500 text-base pointer-events-none #{!important};
      @apply absolute left-[20px] top-[10px] duration-[200ms] #{!important};
    }

    &:focus-within {
      .input,
      .select {
        @apply border-purple-500 #{!important};

        &::placeholder {
          @apply text-gray-300;
        }
      }
    }

    &.filled,
    &:focus-within,
    &--select {
      .uiLabel {
        @apply left-[10px] top-[-5px] text-sm px-5 bg-white leading-none text-gray-350 #{!important};
      }
    }

    &.has-error {
      .input {
        @apply border-red #{!important};
      }

      &::placeholder {
        @apply text-gray-300;
      }
    }
  }

  .uiInputSelect {
    .select {
      @apply text-base px-20 py-0 #{!important};
    }

    &::after {
      top: calc(50% + -8px) !important;
      border-top-color: theme("colors.purple.500") !important;
    }
  }

  .uiInputDefaultError {
    @apply ml-10;
  }
}

.sidebarHeader {
  @apply shadow text-black #{!important};
}

.headerAnnouncement {
  @apply text-black #{!important};
}

.uiInputDefaultError .form-element__help {
  @apply mt-[-10px] mb-10 #{!important};
}

.embeddedServiceLiveAgentStateChat {
  .messageArea {
    @apply bg-purple-100 bg-opacity-20 #{!important};

    &:focus {
      @apply border-none #{!important};
    }
  }

  .chatSessionStartTime {
    @apply text-black-350 text-xxs #{!important};
  }
}

.embeddedServiceLiveAgentStateChatItem {
  &.chatMessage {
    @apply bg-transparent #{!important};
  }

  .avatar {
    @apply bg-purple-500 #{!important};
  }

  .nameAndTimeContent {
    @apply text-black-350 #{!important};
  }
}

.embeddedServiceLiveAgentStateWaiting {
  .waitingStateContent {
    @apply px-10 #{!important};
  }

  .waitingImage {
    @apply absolute bg-contain left-1/2 m-0 mt-30 top-0 -translate-x-1/2 #{!important};
  }

  .waitingGreetingContent {
    @apply mt-0 order-1 #{!important};

    .waitingGreeting {
      @apply font-display text-2xl #{!important};
    }
  }

  .embeddedServiceLoadingBalls {
    @apply mb-20 static order-3 transform-none #{!important};

    .loadingBall {
      @apply bg-purple-500 #{!important};
    }
  }
}

.embeddedServiceLiveAgentQueuePosition {
  @apply order-2 #{!important};

  .embeddedServiceIcon > svg {
    @apply hidden #{!important};
  }

  .youAreNextMessage {
    @apply font-bold text-base mb-30 #{!important};
  }

  .queuePositionCounter {
    @apply hidden #{!important};
  }

  .queuePositionChatIcon {
    @apply order-3;
  }

  .queuePositionContent {
    .embeddedServiceIcon {
      svg {
        @apply h-20 w-[24px] #{!important};
      }
    }
  }
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI {
  &.chasitor {
    &.plaintextContent {
      @apply max-w-[85%] bg-purple-500 #{!important};
    }
  }
}

.embeddedServiceLiveAgentStateChatEventMessage .eventMessage {
  @apply text-black-500 #{!important};

  &::before,
  &::after {
    @apply border-gray-350 #{!important};
  }
}

.embeddedServiceSidebarDialogState .dialogTextContainer {
  @apply pt-40 px-10 #{!important};
}

.embeddedServiceSidebarDialogState #dialogTextTitle {
  @apply font-display text-black-500 mb-20 text-2xl #{!important};
}

.embeddedServiceSidebarDialogState #dialogTextBody {
  @apply font-body text-base text-black-500 #{!important};
}

.embeddedServiceLiveAgentStateChatInputFooter {
  &.chasitorInputWrapper {
    @apply bg-white #{!important};
  }

  .footerMenuWrapper {
    .footer-menu {
      .slds-dropdown {
        @apply shadow-none #{!important};
      }

      .slds-button {
        &:hover,
        &:focus {
          @apply text-purple-500 shadow #{!important};
        }
      }

      .slds-dropdown-trigger {
        &.slds-is-open {
          .slds-button__icon {
            fill: theme("colors.purple.500") !important;
          }
        }

        .slds-button__icon {
          @apply duration-500;

          &:hover {
            fill: theme("colors.purple.500") !important;
          }
        }
      }
    }

    .footer-menu-items {
      .slds-dropdown__item {
        @apply max-w-[294px] border-none h-50;

        & > a {
          @apply text-purple-500 text-base font-bold mx-auto underline justify-center p-0 h-full duration-500 #{!important};
          @apply border-1 hover:border-purple-500 bg-white #{!important};

          &:focus {
            @apply border-purple-500 #{!important};
          }
        }
      }
    }
  }

  .chasitorText {
    @apply font-body py-[11px] text-base #{!important};

    &.textAreaIsFocused {
      @apply border-purple-500 #{!important};
    }
  }

  .chatActionButton.Send {
    @apply ml-[8px] #{!important};
  }
}

[embeddedservice-chatheader_chatheader-host] {
  @apply bg-transparent #{!important};
}

h2[embeddedService-chatHeader_chatHeader] {
  @apply font-body font-bold #{!important};
}

img[embeddedService-chatHeader_chatHeader] {
  @apply hidden #{!important};
}

p[embeddedService-chatHeaderAnnouncement_chatHeaderAnnouncement] {
  @apply bg-white #{!important};
}

embeddedservice-chat-header {
  lightning-icon,
  svg.slds-icon-text-default {
    @apply fill-current stroke-current stroke-[4px] text-purple-500 #{!important};
  }
}

button {
  &[embeddedService-chatHeader_chatHeader] {
    @apply h-15 min-h-[15px] min-w-[15px] ml-10 w-15 #{!important};
  }

  &:hover[embeddedService-chatHeader_chatHeader] {
    @apply opacity-80;
  }

  &:hover[embeddedService-chatHeader_chatHeader]::before {
    @apply hidden #{!important};
  }

  &.closeButton[embeddedService-chatHeader_chatHeader] {
    @apply pt-0 #{!important};
  }
}
</style>
