import Axios from 'axios'

export default class SlackNotification {
  static send (slackWebhookUrl, params) {
    const body = {
      ...params,
      'Time': new Date().toISOString()
    }

    const response = Axios.post(slackWebhookUrl, body, { headers: {
      'Content-Type': 'application/x-www-form-urlencoded' // Slack webhook requires this content type
    } }).catch(err => { throw err.response })

    return response
  }
}
