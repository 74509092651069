const initialState = () => {
  return {
    triggerContinue: false,
    triggerSkipStep: false,
    triggerSubmitNow: false
  }
}

export default {
  state: initialState(),

  mutations: {
    SET_TRIGGER_CONTINUE (state, val) {
      state.triggerContinue = val
    },

    SET_TRIGGER_SKIP_STEP (state, val) {
      state.triggerSkipStep = val
    },

    SET_TRIGGER_SUBMIT_NOW (state, val) {
      state.triggerSubmitNow = val
    }
  },

  actions: {
    setTriggerContinue ({ commit }, val) {
      commit('SET_TRIGGER_CONTINUE', val)
    },

    setTriggerSkipStep ({ commit }, val) {
      commit('SET_TRIGGER_SKIP_STEP', val)
    },

    setTriggerSubmitNow ({ commit }, val) {
      commit('SET_TRIGGER_SUBMIT_NOW', val)
    }
  }
}
