export default {
  state: {
    addressFallback: false,
    street_address: '',
    c_post_fullAddress: '',
    c_post_Company: '',
    c_post_BuildingName: '',
    c_post_BuildingNumber: '',
    c_post_Line1: '',
    c_post_Line2: '',
    c_post_Line3: '',
    c_post_Line4: '',
    c_post_Line5: '',
    c_post_District: '',
    c_post_SubBuilding: '',
    c_post_Street: '',
    city: '',
    c_post_City: '',
    c_post_Province: '',
    province: '',
    c_post_ProvinceName: '',
    c_post_ProvinceCode: '',
    country: '',
    c_post_CountryName: '',
    postal_code: '',
    c_post_PostalCode: '',
    c_post_verified: 0,
    c_post_error: 0,
    c_post_error_description: '',
    address_years: '',
    address_months: '',
    rent_own: '',
    rent_payment: ''
  },

  getters: {
    locationData (state) {
      return {
        verified: state.c_post_verified,
        line1: state.c_post_Line1,
        line2: state.c_post_Line2,
        city: state.c_post_City,
        province: state.c_post_Province,
        postalCode: state.c_post_PostalCode,
        fullAddress: state.c_post_fullAddress
      }
    },

    fallbackData (state) {
      return {
        street_address: state.street_address,
        city: state.city,
        province: state.province,
        postal_code: state.postal_code
      }
    },

    fallbackDataCompleted (state) {
      return (state.street_address &&
        state.city &&
        state.province &&
        state.postal_code)
    }
  },

  mutations: {
    SET_ADDRESS_FALLBACK (state, addressFallback) {
      state.addressFallback = addressFallback
    },

    SET_STREET_ADDRESS (state, streetAddress) {
      state.street_address = streetAddress
    },

    SET_CPOST_FULL_ADDRESS (state, cPostFullAddress) {
      state.c_post_fullAddress = cPostFullAddress
    },

    SET_CPOST_COMPANY (state, cPostCompany) {
      state.c_post_Company = cPostCompany
    },

    SET_CPOST_BUILDING_NAME (state, cPostBuildingName) {
      state.c_post_BuildingName = cPostBuildingName
    },

    SET_CPOST_BUILDING_NUMBER (state, cPostBuildingNumber) {
      state.c_post_BuildingNumber = cPostBuildingNumber
    },

    SET_CPOST_LINE1 (state, cPostLine1) {
      state.c_post_Line1 = cPostLine1
    },

    SET_CPOST_LINE2 (state, cPostLine2) {
      state.c_post_Line2 = cPostLine2
    },

    SET_CPOST_LINE3 (state, cPostLine3) {
      state.c_post_Line3 = cPostLine3
    },

    SET_CPOST_LINE4 (state, cPostLine4) {
      state.c_post_Line4 = cPostLine4
    },

    SET_CPOST_LINE5 (state, cPostLine5) {
      state.c_post_Line5 = cPostLine5
    },

    SET_CPOST_DISTRICT (state, cPostDistrict) {
      state.c_post_District = cPostDistrict
    },

    SET_CPOST_SUB_BUILDING (state, cPostSubBuilding) {
      state.c_post_SubBuilding = cPostSubBuilding
    },

    SET_CPOST_STREET (state, cPostStreet) {
      state.c_post_Street = cPostStreet
    },

    SET_CITY (state, city) {
      state.city = city
    },

    SET_CPOST_CITY (state, cPostCity) {
      state.c_post_City = cPostCity
    },

    SET_CPOST_PROVINCE (state, cPostProvince) {
      state.c_post_Province = cPostProvince
    },

    SET_PROVINCE (state, province) {
      state.province = province
    },

    SET_CPOST_PROVINCE_NAME (state, cPostProvinceName) {
      state.c_post_ProvinceName = cPostProvinceName
    },

    SET_CPOST_PROVINCE_CODE (state, cPostProvinceCode) {
      state.c_post_ProvinceCode = cPostProvinceCode
    },

    SET_COUNTRY (state, country) {
      state.country = country
    },

    SET_CPOST_COUNTRY_NAME (state, cPostCountryName) {
      state.c_post_CountryName = cPostCountryName
    },

    SET_POSTAL_CODE (state, postalCode) {
      state.postal_code = postalCode
    },

    SET_CPOST_POSTAL_CODE (state, cPostPostalCode) {
      state.c_post_PostalCode = cPostPostalCode
    },

    SET_CPOST_VERIFIED (state, CpostVerified) {
      state.c_post_verified = CpostVerified
    },

    SET_CPOST_ERROR (state, cPostError) {
      state.c_post_error = cPostError
    },

    SET_CPOST_ERROR_DESCRIPTION (state, cPostErrorDescription) {
      state.c_post_error_description = cPostErrorDescription
    },

    SET_ADDRESS_YEARS (state, addressYears) {
      state.address_years = addressYears
    },

    SET_ADDRESS_MONTHS (state, addrsesMonths) {
      state.address_months = addrsesMonths
    },

    SET_RENT_OWN (state, rentOwn) {
      state.rent_own = rentOwn
    },

    SET_RENT_PAYMENT (state, rentPayment) {
      state.rent_payment = rentPayment
    },

    CLEAR_CPOST_VALUES (state) {
      state.c_post_fullAddress = ''
      state.c_post_Company = ''
      state.c_post_BuildingName = ''
      state.c_post_BuildingNumber = ''
      state.c_post_Line1 = ''
      state.c_post_Line2 = ''
      state.c_post_Line3 = ''
      state.c_post_Line4 = ''
      state.c_post_Line5 = ''
      state.c_post_District = ''
      state.c_post_SubBuilding = ''
      state.c_post_Street = ''
      state.c_post_City = ''
      state.c_post_Province = ''
      state.c_post_ProvinceName = ''
      state.c_post_ProvinceCode = ''
      state.c_post_CountryName = ''
      state.c_post_PostalCode = ''
      state.c_post_verified = 0
      state.c_post_error = 0
      state.c_post_error_description = ''
    }
  },

  actions: {
    setAddressFallback ({ commit }, addressFallback) {
      commit('SET_ADDRESS_FALLBACK', addressFallback)
    },

    setStreetAddress ({ commit }, streetAddress) {
      commit('SET_STREET_ADDRESS', streetAddress)
    },

    setCPostFullAddress ({ commit }, cPostFullAddress) {
      commit('SET_CPOST_FULL_ADDRESS', cPostFullAddress)
    },

    setCPostCompany ({ commit }, cPostCompany) {
      commit('SET_CPOST_COMPANY', cPostCompany)
    },

    setCPostBuildingName ({ commit }, cPostBuildingName) {
      commit('SET_CPOST_BUILDING_NAME', cPostBuildingName)
    },

    setCPostBuildingNumber ({ commit }, cPostBuildingNumber) {
      commit('SET_CPOST_BUILDING_NUMBER', cPostBuildingNumber)
    },

    setCPostLine1 ({ commit }, cPostLine1) {
      commit('SET_CPOST_LINE1', cPostLine1)
    },

    setCPostLine2 ({ commit }, cPostLine2) {
      commit('SET_CPOST_LINE2', cPostLine2)
    },

    setCPostLine3 ({ commit }, cPostLine3) {
      commit('SET_CPOST_LINE3', cPostLine3)
    },

    setCPostLine4 ({ commit }, cPostLine4) {
      commit('SET_CPOST_LINE4', cPostLine4)
    },

    setCPostLine5 ({ commit }, cPostLine5) {
      commit('SET_CPOST_LINE5', cPostLine5)
    },

    setCPostDistrict ({ commit }, cPostDistrict) {
      commit('SET_CPOST_DISTRICT', cPostDistrict)
    },

    setCPostSubBuilding ({ commit }, cPostSubBuilding) {
      commit('SET_CPOST_SUB_BUILDING', cPostSubBuilding)
    },

    setCPostStreet ({ commit }, cPostStreet) {
      commit('SET_CPOST_STREET', cPostStreet)
    },

    setCity ({ commit }, city) {
      commit('SET_CITY', city)
    },

    setCPostCity ({ commit }, cPostCity) {
      commit('SET_CPOST_CITY', cPostCity)
    },

    setCPostProvince ({ commit }, cPostProvince) {
      commit('SET_CPOST_PROVINCE', cPostProvince)
    },

    setProvince ({ commit }, province) {
      commit('SET_PROVINCE', province)
    },

    setCPostProvinceName ({ commit }, cPostProvinceName) {
      commit('SET_CPOST_PROVINCE_NAME', cPostProvinceName)
    },

    setCPostProvinceCode ({ commit }, cPostProvinceCode) {
      commit('SET_CPOST_PROVINCE_CODE', cPostProvinceCode)
    },

    setCountry ({ commit }, country) {
      commit('SET_COUNTRY', country)
    },

    setCPostCountryName ({ commit }, cPostCountryName) {
      commit('SET_CPOST_COUNTRY_NAME', cPostCountryName)
    },

    setPostalCode ({ commit }, postalCode) {
      commit('SET_POSTAL_CODE', postalCode)
    },

    setCPostPostalCode ({ commit }, cPostPostalCode) {
      commit('SET_CPOST_POSTAL_CODE', cPostPostalCode)
    },

    setCpostVerified ({ commit }, CpostVerified) {
      commit('SET_CPOST_VERIFIED', CpostVerified)
    },

    setCPostError ({ commit }, cPostError) {
      commit('SET_CPOST_ERROR', cPostError)
    },

    setCPostErrorDescription ({ commit }, cPostErrorDescription) {
      commit('SET_CPOST_ERROR_DESCRIPTION', cPostErrorDescription)
    },

    setAddressYears ({ commit }, addrsesYears) {
      commit('SET_ADDRESS_YEARS', addrsesYears)
    },

    setAddressMonths ({ commit }, addressMonths) {
      commit('SET_ADDRESS_MONTHS', addressMonths)
    },

    setRentOwn ({ commit }, rentOwn) {
      commit('SET_RENT_OWN', rentOwn)
    },

    setRentPayment ({ commit }, rentPayment) {
      commit('SET_RENT_PAYMENT', rentPayment)
    },

    clearCPostValues ({ commit }) {
      commit('CLEAR_CPOST_VALUES')
    }
  }
}
