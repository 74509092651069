import router from '../router'
import store from '../store'
import * as ROUTE_PATHS from '../constants/route-paths'

window.spring = window.spring || {}

/**
 * Pushes route to application-success or complete skipped steps screen.
 */
window.spring.redirectPLApplicationSuccess = () => {
  const params = { skipValidation: true }
  let name = `pl-${ROUTE_PATHS.APPLICATION_SUCCESS}`

  if (!store.state.documents.id) {
    name = `pl-${ROUTE_PATHS.COMPLETE_SKIPPED_STEPS}`
  }

  router.push({ name, params })
}
