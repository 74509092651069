import applicationStore from '@/store/modules/application'

/**
* DataLayer / Google Tag Manager Directive
* @desc handles dataLayer events
*/

function pushCduid (object) {
  if (applicationStore.state.cduid) {
    object.cduid = applicationStore.state.cduid
  }
  return object
}

export default class DataLayer {
  static checkForDuplicates (key, value) {
    let found = false // Check if the event has already fired
    for (let i in dataLayer) {
      if (dataLayer[i][key] === value) {
        found = true
        return found
      }
    }
    return found
  }

  /**
  * Push Object
  * @desc Pushes a whole object to the datalayer.
  * @param {object} object
  */

  static pushObject (object, enableDuplicates = false) {
    if (!enableDuplicates && (!object || this.checkForDuplicates('event', object.event))) {
      return
    }
    dataLayer.push(pushCduid(object)) // Push to the dataLayer
  }
  /**
  * Push an event to the dataLayer
  * @desc push the event if it hasn't already been fired
  * @param {String} event - name of the event
  */
  static pushEvent (key, value) {
    if (!key || !value) {
      return
    }

    let found = false // Check if the event has already fired
    for (let i in dataLayer) {
      if (dataLayer[i][key] === value) {
        found = true
        return
      }
    }

    if (!found) { // If the event hasn't fired yet
      const object = { [key]: value }
      dataLayer.push(pushCduid(object)) // Push to the dataLayer
    }
  }

  static pushOptimizeTrigger (eventObject) {
    dataLayer.push(eventObject)
  }
}
