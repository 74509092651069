import AppShellFooterOnly from '@/components/application/AppShellFooterOnly'
import preventRefresh from '@/router/utils/prevent-refresh'

const DocumentsLanding = () => import(
  /* webpackChunkName: "documents-v1" */
  '@/components/steps/documents/DocumentsLandingPage'
)

const VerifyIdentity = () => import(
  /* webpackChunkName: "documents-v1" */
  '@/components/steps/document-upload/VerifyIdentity'
)

const VerifyIncome = () => import(
  /* webpackChunkName: "documents-v1" */
  '@/components/steps/document-upload/VerifyIncome'
)

const VerifyAddress = () => import(
  /* webpackChunkName: "documents-v1" */
  '@/components/steps/document-upload/VerifyAddress'
)

const VerifyBank = () => import(
  /* webpackChunkName: "documents-v1" */
  '@/components/steps/common/VerifyBank'
)

const OtherDocuments = () => import(
  /* webpackChunkName: "documents-v1" */
  '@/components/steps/document-upload/OtherDocuments'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */
const appName = 'springloan-usl'
const variation = 'v1'
const basePath = '/documents'
const disableAppLandedEvent = true

const routes = [
  {
    path: basePath,
    component: AppShellFooterOnly,
    meta: {
      basePath,
      appName,
      variation,
      disableAppLandedEvent
    },
    props: {
      theme: 'blue',
      module: 'personal-loans',
      canonicalModule: 'documents'
    },

    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: DocumentsLanding,
        meta: {
          index: 1
        }
      },
      {
        path: 'bank-details',
        component: VerifyBank,
        props: {
          docsStandAlone: true,
          subtitle: 'In order to get the fastest approval for the highest amount possible we need to verify your banking details'
        },
        meta: {
          index: 1,
          nextStep: '',
          showFooter: true,
          showSkipStep: false,
          showBankSkipStep: true,
          skipStepProp: ''
        }
      },
      {
        path: 'verify-identity',
        component: VerifyIdentity,
        props: {
          docsStandAlone: true
        },
        meta: {
          index: 1,
          nextStep: '',
          showFooter: true,
          showAppButton: true,
          showSkipStep: true,
          skipStepProp: 'ID'
        }
      },
      {
        path: 'verify-income',
        component: VerifyIncome,
        props: {
          docsStandAlone: true
        },
        meta: {
          index: 1,
          nextStep: '',
          showFooter: true,
          showAppButton: true,
          showSkipStep: true,
          skipStepProp: 'paystubs'
        }
      },
      {
        path: 'verify-address',
        component: VerifyAddress,
        props: {
          docsStandAlone: true
        },
        meta: {
          index: 1,
          nextStep: '',
          showFooter: true,
          showAppButton: true,
          showSkipStep: true,
          skipStepProp: 'address'
        }
      },
      {
        path: 'other-documents',
        component: OtherDocuments,
        props: {
          docsStandAlone: true
        },
        meta: {
          index: 1,
          nextStep: '',
          showFooter: true,
          showAppButton: true,
          showSkipStep: true,
          skipStepProp: 'other documents'
        }
      }
    ]
  }]

export default routes
