import AppShellLoans from '@/components/application/AppShellLoans'
import preventRefresh from '@/router/utils/prevent-refresh'
import nextEmploymentRoute from '@/router/utils/next-employment-route'
import store from '@/store'
import * as ROUTE_PATHS from '@/constants/route-paths'
import * as PROGRESS_LABELS from '@/constants/progress-label'
import * as LEAD_TYPE from '@/constants/lead-type'
import faq, { foundationBloomFAQ } from '@/static/faq/foundation'

const NameDetails = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/NameDetails'
)

const CreditGoals = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/foundation/CreditGoals'
)

const EmploymentStatus = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/EmploymentStatus'
)

const EmploymentStatusInsurance = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/EmploymentStatusInsurance'
)

const EmploymentStatusOther = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/EmploymentStatusOther'
)

const IncomeDetailsFrequency = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/IncomeDetailsFrequency'
)

const IncomeDuration = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/IncomeDuration'
)

const IncomeDetailsAmount = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/IncomeDetailsAmount'
)

const HomeAddress = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/HomeAddress'
)

const ContactPhone = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/ContactPhone'
)

const ContactCode = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/ContactCode'
)

const DateOfBirth = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/DateOfBirth'
)

const VerifyBank = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/VerifyBank'
)

const PlanSelection = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/foundation/PlanSelection'
)

const PaymentSummary = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/foundation/PaymentSummary'
)

const ApplicationSuccessful = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/foundation/ApplicationSuccessful'
)

const Sorry = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/foundation/Sorry'
)

const FinancialSelector = () => import(
  /* webpackChunkName: "foundation-v1" */
  '@/components/steps/common/FinancialSelector'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */
const appName = 'springfoundation'
const leadType = LEAD_TYPE.FOUNDATION
const variation = 'v1'
const basePath = '/foundation'

const nonDocStepSettings = {
  showFooter: true,
  showAppButton: true,
  showSkipStep: false
}

const props = {
  eventPrefix: 'foundation',
  basePath
}

const routes = [
  {
    path: basePath,
    component: AppShellLoans,
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    props: {
      theme: 'purple',
      module: 'foundation'
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: NameDetails,
        meta: {
          index: 1,
          nextStep: ROUTE_PATHS.CREDIT_GOALS,
          progressLabel: PROGRESS_LABELS.THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          title: 'Apply To Begin Improving Your Financial Health',
          enableInProgress: false,
          bloomEnabled: true,
          ...props
        }
      }, {
        path: ROUTE_PATHS.CREDIT_GOALS,
        component: CreditGoals,
        meta: {
          index: 2,
          nextStep: ROUTE_PATHS.EMPLOYMENT_STATUS,
          progressLabel: PROGRESS_LABELS.THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.EMPLOYMENT_STATUS,
        component: EmploymentStatus,
        meta: {
          index: 3,
          nextStep: () => nextEmploymentRoute(),
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.EMPLOYMENT_STATUS_INSURANCE,
        component: EmploymentStatusInsurance,
        meta: {
          index: 4,
          nextStep: () => {
            return nextEmploymentRoute()
          },
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.EMPLOYMENT_STATUS_OTHER,
        component: EmploymentStatusOther,
        meta: {
          index: 5,
          nextStep: () => {
            return nextEmploymentRoute()
          },
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.INCOME_DETAILS_FREQUENCY,
        component: IncomeDetailsFrequency,
        meta: {
          index: 6,
          nextStep: ROUTE_PATHS.INCOME_DETAILS_AMOUNT,
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.INCOME_DETAILS_AMOUNT,
        component: IncomeDetailsAmount,
        meta: {
          index: 7,
          nextStep: ROUTE_PATHS.INCOME_DURATION,
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.INCOME_DURATION,
        component: IncomeDuration,
        meta: {
          index: 8,
          nextStep: ROUTE_PATHS.HOME_ADDRESS,
          progressLabel: PROGRESS_LABELS.TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.HOME_ADDRESS,
        component: HomeAddress,
        meta: {
          index: 9,
          nextStep: ROUTE_PATHS.DATE_OF_BIRTH,
          progressLabel: PROGRESS_LABELS.TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          searchVersion: process.env.VUE_APP_CPOST_SEARCH_VERSION,
          ...props
        }
      }, {
        path: ROUTE_PATHS.DATE_OF_BIRTH,
        component: DateOfBirth,
        meta: {
          index: 10,
          nextStep: ROUTE_PATHS.CONTACT_PHONE,
          progressLabel: PROGRESS_LABELS.TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.CONTACT_PHONE,
        component: ContactPhone,
        meta: {
          index: 11,
          nextStep: () => {
            const SID = store.state.tracking.lead_source
            const flinksEnabled = process.env.VUE_APP_FLINKS_ENABLED && SID !== 'AA'
            const nextStep = flinksEnabled ? ROUTE_PATHS.FINANCIAL_SELECTOR : ROUTE_PATHS.PLAN_SELECTION
            return store.state.application.twilioFailed ? nextStep : ROUTE_PATHS.CONTACT_CODE
          },
          progressLabel: PROGRESS_LABELS.TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props,
          skipUpdateLead: true
        }
      }, {
        path: ROUTE_PATHS.CONTACT_CODE,
        component: ContactCode,
        meta: {
          index: 12,
          nextStep: () => {
            const SID = store.state.tracking.lead_source
            const flinksEnabled = process.env.VUE_APP_FLINKS_ENABLED && SID !== 'AA'
            const nextStep = process.env.VUE_APP_DISABLE_FI_SELECTOR ? ROUTE_PATHS.VERIFY_BANK : ROUTE_PATHS.FINANCIAL_SELECTOR
            return flinksEnabled ? nextStep : ROUTE_PATHS.PLAN_SELECTION
          },
          progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES
        },
        props: {
          ...props
        }
      },
      {
        path: ROUTE_PATHS.FINANCIAL_SELECTOR,
        component: FinancialSelector,
        meta: {
          index: 13,
          nextStep: ROUTE_PATHS.VERIFY_BANK,
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          showFooter: false
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.VERIFY_BANK,
        component: VerifyBank,
        meta: {
          index: 14,
          nextStep: ROUTE_PATHS.PLAN_SELECTION,
          progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
          showFooter: false,
          showSkipStep: false,
          showBankSkipStep: false

        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.PLAN_SELECTION,
        component: PlanSelection,
        meta: {
          index: 15,
          nextStep: ROUTE_PATHS.PAYMENT_SUMMARY,
          showFooter: true,
          showAppButton: true,
          disableGridLayout: true,
          dynamicContinueText: true
        },
        props: {
          ...props,
          title: 'The Foundation',
          faq,
          foundationBloomFAQ,
          skipUpdateLead: true
        }
      }, {
        path: ROUTE_PATHS.PAYMENT_SUMMARY,
        component: PaymentSummary,
        meta: {
          index: 16,
          nextStep: ROUTE_PATHS.APPLICATION_SUCCESS,
          backStep: ROUTE_PATHS.PLAN_SELECTION,
          showFooter: true,
          continueText: 'Finish',
          showBackButton: true,
          showAppButton: true
        },
        props: {
          ...props,
          faq,
          foundationBloomFAQ,
          skipUpdateLead: true
        }
      }, {
        path: ROUTE_PATHS.APPLICATION_SUCCESS,
        component: ApplicationSuccessful,
        meta: {
          index: 16,
          progressLabel: PROGRESS_LABELS.FINISHED,
          disableGridLayout: true,
          showFooter: false,
          showSkipStep: false
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.SORRY,
        component: Sorry,
        meta: {
          progress: false,
          disableGridLayout: true,
          showFooter: false,
          showSkipStep: false
        },
        props: {
          enableProvinceMessage: true,
          ...props
        }
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
