import TwilioVerify from '@/services/TwilioVerify'

export const initialState = () => {
  return {
    first_name: '',
    last_name: '',
    home_phone: '',
    email: '',
    dob_day: '',
    dob_month: '',
    dob_year: '',
    birth_date: '',
    credit_goals: '',
    credit_rating: '',
    bank: '',
    work_phone: '',
    eligibilityStatus: '',
    phoneVerificationStatus: '',
    phoneVerificationSid: '',
    phoneVerificationCode: '',
    phoneVerificationMethod: '',
    phoneVerificationResendCount: 0,
    failedVerificationAttempts: 0,
    out_of_province: false,
    payment_frequency: '',
    payment_date: '',
    smartAppProceed: false,
    seenPlWaitingScreen: false,
    variantSeenOnHarvestPage: '',
    interest_rate: '',
    loan_duration: '',
    approval_amount: ''
  }
}

export default {
  state: initialState(),

  getters: {
    birthData (state) {
      return {
        dob_day: state.dob_day,
        dob_month: state.dob_month,
        dob_year: state.dob_year,
        birth_date: state.birth_date
      }
    },

    collectAllPLDocs (state) {
      return (
        !state.eligibilityStatus ||
        state.eligibilityStatus === 'Easy'
      )
    },

    isPersonalLoansEligible (state) {
      return state.eligibilityStatus === 'CC' || state.eligibilityStatus === 'Easy'
    },

    isFoundationEligibleOnly (state) {
      return state.eligibilityStatus === 'Foundation'
    }
  },

  mutations: {
    SET_CREDIT_GOALS (state, creditGoals) {
      state.credit_goals = creditGoals
    },

    SET_CREDIT_RATING (state, creditRating) {
      state.credit_rating = creditRating
    },

    SET_FIRST_NAME (state, firstName) {
      state.first_name = firstName
    },

    SET_LAST_NAME (state, lastName) {
      state.last_name = lastName
    },

    SET_HOME_PHONE (state, homePhone) {
      state.home_phone = homePhone
    },

    SET_EMAIL (state, email) {
      state.email = email
    },

    SET_DOB_DAY (state, dobDay) {
      state.dob_day = dobDay
    },

    SET_DOB_MONTH (state, dobMonth) {
      state.dob_month = dobMonth
    },

    SET_DOB_YEAR (state, dobYear) {
      state.dob_year = dobYear
    },

    SET_BIRTH_DATE (state, birthDate) {
      state.birth_date = birthDate
    },

    SET_BANK (state, bankName) {
      state.bank = bankName
    },

    SET_WORK_PHONE (state, workPhone) {
      state.work_phone = workPhone
    },

    SET_MESSAGE_CHANNEL (state, messageChannel) {
      state.messageChannel = messageChannel
    },

    SET_PHONE_VERIFICATION_STATUS (state, phoneVerificationStatus) {
      state.phoneVerificationStatus = phoneVerificationStatus
    },

    SET_VERIFICATION_SID (state, phoneVerificationSid) {
      state.phoneVerificationSid = phoneVerificationSid
    },

    SET_VERIFICATION_CODE (state, phoneVerificationCode) {
      state.phoneVerificationCode = phoneVerificationCode
    },

    SET_VERIFICATION_METHOD (state, phoneVerificationMethod) {
      state.phoneVerificationMethod = phoneVerificationMethod
    },

    INCREMENT_VERIFICATION_RESEND_COUNT (state) {
      state.phoneVerificationResendCount += 1
    },

    INCREMENT_FAILED_VERIFY_ATTEMPTS (state) {
      state.failedVerificationAttempts += 1
    },

    RESET_CUSTOMER_STATE (state, initialState) {
      Object.keys(initialState).forEach(key => (state[key] = initialState[key]))
    },

    SET_CUSTOMER_ELIGIBILITY (state, eligibility) {
      state.eligibilityStatus = eligibility
    },

    SET_CUSTOMER_OUT_OF_PROVINCE (state, outOfProvince) {
      state.out_of_province = outOfProvince
    },

    SET_PAYMENT_FREQUENCY (state, paymentFrequency) {
      state.payment_frequency = paymentFrequency
    },

    SET_PAYMENT_DATE (state, paymentDate) {
      state.payment_date = paymentDate
    },

    SET_SMART_APP_PROCEED (state, smartAppProceed) {
      state.smartAppProceed = smartAppProceed
    },
    SET_PL_WAITING_SCREEN_SEEN (state, seenWaitingScreen) {
      state.seenPlWaitingScreen = seenWaitingScreen
    },
    SET_SEEN_VARIANT_HARVEST_PAGE (state, variantSeen) {
      state.variantSeenOnHarvestPage = variantSeen
    },
    SET_CLEAR_SCORE_LOAN_DATA (state, loanData) {
      state.interest_rate = loanData.interest_rate
      state.approval_amount = loanData.approval_amount
      state.loan_duration = loanData.loan_duration
    }
  },

  actions: {
    setCreditGoals ({ commit }, creditGoals) {
      commit('SET_CREDIT_GOALS', creditGoals)
    },

    setCreditRating ({ commit }, creditRating) {
      commit('SET_CREDIT_RATING', creditRating)
    },

    setFirstName ({ commit }, firstName) {
      commit('SET_FIRST_NAME', firstName)
    },

    setLastName ({ commit }, lastName) {
      commit('SET_LAST_NAME', lastName)
    },

    setHomePhone ({ commit }, homePhone) {
      commit('SET_HOME_PHONE', homePhone)
    },

    setEmail ({ commit }, email) {
      commit('SET_EMAIL', email)
    },

    setDobDay ({ commit }, dobDay) {
      commit('SET_DOB_DAY', dobDay)
    },

    setDobMonth ({ commit }, dobMonth) {
      commit('SET_DOB_MONTH', dobMonth)
    },

    setDobYear ({ commit }, dobYear) {
      commit('SET_DOB_YEAR', dobYear)
    },

    setBirthDate ({ commit }, birthDate) {
      commit('SET_BIRTH_DATE', birthDate)
    },

    setBank ({ commit }, bankName) {
      commit('SET_BANK', bankName)
    },

    setWorkPhone ({ commit }, workPhone) {
      commit('SET_WORK_PHONE', workPhone)
    },

    setMessageChannel ({ commit }, messageChannel) {
      commit('SET_MESSAGE_CHANNEL', messageChannel)
    },

    async requestVerification ({ commit }, { phone, channel }) {
      const { data } = await TwilioVerify.requestVerification(phone, channel)
      commit('SET_VERIFICATION_SID', data.verificationSid)
      return data
    },

    async checkVerification ({ commit }, { verificationSid, code }) {
      const { data } = await TwilioVerify.checkVerificationCode(verificationSid, code)
      commit('SET_PHONE_VERIFICATION_STATUS', data.status)
      return data.status
    },

    setVerificationCode ({ commit }, phoneVerificationCode) {
      commit('SET_VERIFICATION_CODE', phoneVerificationCode)
    },

    setVerificationMethod ({ commit }, verificationMethod) {
      commit('SET_VERIFICATION_METHOD', verificationMethod)
    },

    incrementVerificationAttempts ({ commit }) {
      commit('INCREMENT_VERIFICATION_RESEND_COUNT')
    },

    incrementFailedVerifyAttempts ({ commit }) {
      commit('INCREMENT_FAILED_VERIFY_ATTEMPTS')
    },

    resetCustomerState ({ commit }) {
      commit('RESET_CUSTOMER_STATE', initialState())
    },

    setCustomerEligibility ({ commit }, eligibility) {
      commit('SET_CUSTOMER_ELIGIBILITY', eligibility)
    },

    setCustomerOutOfProvince ({ commit }, outOfProvince) {
      commit('SET_CUSTOMER_OUT_OF_PROVINCE', outOfProvince)
    },

    setPaymentFrequency ({ commit }, paymentFrequency) {
      commit('SET_PAYMENT_FREQUENCY', paymentFrequency)
    },

    setPaymentDate ({ commit }, paymentDate) {
      commit('SET_PAYMENT_DATE', paymentDate)
    },

    setSmartAppProceed ({ commit }, smartAppProceed) {
      commit('SET_SMART_APP_PROCEED', smartAppProceed)
    },
    setSeenPlWaitingScreen ({ commit }, seenWaitingScreen) {
      commit('SET_PL_WAITING_SCREEN_SEEN', seenWaitingScreen)
    },
    setVariantSeenOnHarvestPage ({ commit }, variantSeen) {
      commit('SET_SEEN_VARIANT_HARVEST_PAGE', variantSeen)
    }
  }
}
