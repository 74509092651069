/**
 * Sticky CTA Mixin
 * @desc Handles logic for managing the sticky CTA on mobile views
 */
export default {
  // Data
  data () {
    return {
      bankSkip: false,
      preventContinue: false
    }
  },

  // Computed
  computed: {
    $_stickyCTA_showFooter () {
      return this.$route.meta.showFooter
    }
  },

  // Methods
  methods: {
    $_stickyCTA_showBankSkip (val) {
      this.bankSkip = val
    },

    $_stickyCTA_afterEnter () {
      this.$store.dispatch('setTriggerContinue', false)
      this.preventContinue = false
    },

    $_stickyCTA_beforeLeave () {
      this.preventContinue = true
    }
  }
}
