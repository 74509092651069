module.exports = {
  screens: {
    // => @media (max-width: 374px)
    'xxs-only': { max: '374px' },

    // => @media (min-width: 375px)
    xxs: '375px',

    // => @media (min-width: 395px)
    xs: '395px',

    // => @media (max-width: 599px)
    'xs-only': { max: '599px' },

    // => @media (min-width: 600px)
    sm: '600px',

    // => @media (min-width: 900px)
    md: '900px',

    // => @media (min-width: 1200px)
    lg: '1200px',

    // => @media (min-width: 1500px)
    xl: '1500px',

    // => @media print
    print: { raw: 'print' },
    portrait: { raw: '(orientation: portrait)' }
  }
}
