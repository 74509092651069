export default {
  namespaced: true,

  state: {
    loan_amount: '',
    loan_purpose: ''
  },

  getters: {
    isLoan (state, getters, rootState) {
      const { tracking } = rootState
      const appSources = ['springloan-usl', 'springfoundation']
      return appSources.some(src => tracking.app_source.includes(src))
    },

    isPersonalLoan (state, getters, rootState) {
      const { tracking } = rootState
      return tracking.app_source.includes('springloan-usl')
    },

    isFoundation (state, getters, rootState) {
      const { tracking } = rootState
      return tracking.app_source.includes('springfoundation')
    },

    isMortgage (state, getters, rootState) {
      const { tracking } = rootState
      return tracking.app_source.includes('springmortgage')
    },

    isLoanSubmitted (state, getters, rootState) {
      const { application } = rootState
      return getters.isLoan && application.leadSubmitted
    },

    trackingData (state, getters, rootState) {
      return trackingObjBuilder(rootState)
    },

    leadSubmissionData (state, getters, rootState) {
      const tracking = trackingObjBuilder(rootState)

      const {
        application,
        customer,
        location,
        loans,
        employment
      } = rootState

      return {
        ...application,
        ...tracking,
        ...customer,
        ...location,
        ...loans,
        ...employment
      }
    },

    leadCreationData (state, getters, rootState) {
      const tracking = trackingObjBuilder(rootState)

      const {
        application,
        customer
      } = rootState

      return {
        ...application,
        ...tracking,
        ...customer
      }
    }
  },

  mutations: {
    SET_LOAN_AMOUNT (state, loanAmount) {
      state.loan_amount = loanAmount
    },

    SET_CREDIT_RATING (state, creditRating) {
      state.credit_rating = creditRating
    },

    SET_LOAN_PURPOSE (state, loanPurpose) {
      state.loan_purpose = loanPurpose
    }
  },

  actions: {
    setLoanAmount ({ commit }, loanAmount) {
      commit('SET_LOAN_AMOUNT', loanAmount)
    },

    setCreditRating ({ commit }, creditRating) {
      commit('SET_CREDIT_RATING', creditRating)
    },

    setLoanPurpose ({ commit }, loanPurpose) {
      commit('SET_LOAN_PURPOSE', loanPurpose)
    }
  }
}

const trackingObjBuilder = (rootState) => {
  const trackingValue = rootState.tracking['SID2'] || ''
  const SID2 = trackingValue.split(',').pop()
  return { ...rootState.tracking, SID2 }
}
