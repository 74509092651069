<template>
  <div class="app-loans-background">
    <img
      :src="shapes.blob_green_left"
      alt=""
      class="shape app-loans-background__blob--green"
    >
    <img
      :src="shapes.lines_green_left"
      alt=""
      class="shape app-loans-background__line--green"
    >
    <img
      :src="shapes.blob_purple_right"
      alt=""
      class="shape app-loans-background__blob--purple"
    >
    <img
      :src="shapes.lines_purple_right"
      alt=""
      class="shape app-loans-background__line--purple"
    >
  </div>
</template>

<script>
export default {
  name: 'AppBackgroundFoundation',
  data () {
    return {
      shapes: {
        lines_green_left: require('@/assets/svg/backgrounds/lines_green_left.svg'),
        blob_green_left: require('@/assets/svg/backgrounds/blob_green_left.svg'),
        lines_purple_right: require('@/assets/svg/backgrounds/lines_purple_right.svg'),
        blob_purple_right: require('@/assets/svg/backgrounds/blob_purple_right.svg')

      }
    }
  }
}
</script>

<style scoped lang="scss">
.app-loans-background {
  @apply relative w-full h-full;

  &__blob {
    &--purple {
      @apply top-[550px] w-[9%] right-0 scale-y-[-1];

      @screen sm {
        @apply top-[400px] w-[10%];
      }

      @screen md {
        @apply top-[660px] w-[11%];
      }

      @screen lg {
        @apply w-[20%] top-[650px] scale-y-[1];
      }

      @screen xl {
        @apply w-[13%];
      }
    }

    &--green {
      @apply top-[88px] w-[10%] left-0;

      @screen sm {
        @apply top-[100px] w-[13%];
      }

      @screen md {
        @apply top-[30px] w-[13%];
      }
    }
  }

  &__line {
    &--purple {
      @apply top-[535px] w-[13%] -right-5;

      @screen sm {
        @apply top-[405px] w-[12%] right-[-5%];
      }

      @screen md {
        @apply top-[500px] w-[10%] right-0;
      }

      @screen lg {
        @apply top-[350px] w-[12%] right-0;
      }

      @screen xl {
        @apply w-[10%];
      }
    }

    &--green {
      @apply top-[75px] w-[7%] left-0;

      @screen sm {
        @apply top-[35px] w-[12%];
      }

      @screen md {
        @apply top-[70px] w-[13%];
      }

      @screen lg {
        @apply top-[140px];
      }
    }
  }
}
</style>
