<template>
  <div class="app-evergreen-background">
    <img
      :src="shapes.blob_yellow_left"
      alt=""
      class="shape app-evergreen-background__blob--yellow"
    >
    <img
      :src="shapes.lines_yellow_left"
      alt=""
      class="shape app-evergreen-background__line--yellow"
    >
    <img
      :src="shapes.blob_green_right"
      alt=""
      class="shape app-evergreen-background__blob--green"
    >
    <img
      :src="shapes.lines_green_right"
      alt=""
      class="shape app-evergreen-background__line--green"
    >
    <img
      :src="shapes.blob_yellow_left"
      alt=""
      class="shape app-evergreen-background__blob--yellow2"
    >
    <img
      :src="shapes.lines_yellow_left"
      alt=""
      class="shape app-evergreen-background__line--yellow2"
    >
    <img
      :src="shapes.blob_green_right"
      alt=""
      class="shape app-evergreen-background__blob--green2"
    >
    <img
      :src="shapes.lines_green_right"
      alt=""
      class="shape app-evergreen-background__line--green2"
    >
  </div>
</template>

<script>
export default {
  name: 'AppBackgroundEvergreen',
  data () {
    return {
      shapes: {
        lines_yellow_left: require('@/assets/svg/backgrounds/lines_yellow_left.svg'),
        blob_yellow_left: require('@/assets/svg/backgrounds/blob_yellow_left.svg'),
        lines_green_right: require('@/assets/svg/backgrounds/lines_green_right.svg'),
        blob_green_right: require('@/assets/svg/backgrounds/blob_green_right.svg')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.app-evergreen-background {
  @apply relative w-full h-full;

  &__blob {
    &--green {
      @apply top-[520px] w-[12%] right-0;

      @screen sm {
        @apply w-[10%];
      }

      @screen lg {
        @apply w-[13%];
      }
    }

    &--green2 {
      @apply top-[1200px] w-[12%] right-0;

      @screen sm {
        @apply hidden;
      }
    }

    &--yellow {
      @apply top-[180px] w-[9%] left-0;

      @screen sm {
        @apply top-[150px];
      }

      @screen lg {
        @apply w-[11%];
      }
    }

    &--yellow2 {
      @apply top-[1000px] w-[9%] left-0;

      @screen sm {
        @apply hidden;
      }
    }
  }

  &__line {
    &--green {
      @apply top-[500px] w-[10%] right-0;

      @screen lg {
        @apply w-[12%];
      }
    }

    &--green2 {
      @apply top-[1180px] w-[10%] right-0;

      @screen sm {
        @apply hidden;
      }
    }

    &--yellow {
      @apply top-[200px] w-[10%] left-0;

      @screen sm {
        @apply top-[160px] w-[8%];
      }

      @screen lg {
        @apply w-[10%];
      }
    }

    &--yellow2 {
      @apply top-[1050px] w-[10%] left-0;

      @screen sm {
        @apply hidden;
      }
    }
  }
}
</style>
