export const NAME = 'name'
export const LOAN_AMOUNT = 'loan-amount'
export const CREDIT_GOALS = 'credit-goals'
export const CREDIT_RATING = 'credit-rating'
export const EMPLOYMENT_STATUS = 'employment-status'
export const EMPLOYMENT_STATUS_INSURANCE = 'employment-status-insurance'
export const EMPLOYMENT_STATUS_OTHER = 'employment-status-other'
export const INCOME_DETAILS_FREQUENCY = 'income-details-frequency'
export const INCOME_DETAILS_AMOUNT = 'income-details-amount'
export const INCOME_DURATION = 'income-duration'
export const HOME_ADDRESS = 'home-address'
export const DATE_OF_BIRTH = 'date-of-birth'
export const CONTACT_PHONE = 'contact-phone'
export const CONTACT_CODE = 'contact-code'
export const VERIFY_BANK = 'verify-bank'
export const VERIFY_IDENTITY = 'verify-identity'
export const VERIFY_INCOME = 'verify-income'
export const VERIFY_ADDRESS = 'verify-address'
export const COMPLETE_SKIPPED_STEPS = 'complete-skipped-steps'
export const WELCOME_BACK = 'welcome-back'
export const APPLICATION_SUCCESS = 'application-success'
export const SORRY = 'sorry'
export const HOME_VALUE = 'home-value'
export const AMOUNT_OWING = 'amount-owing'
export const CURRENT_INTEREST_RATE = 'current-interest-rate'
export const HOME_OWNERSHIP_DURATION = 'home-ownership-duration'
export const NAME_DETAILS = 'name-details'
export const CONTACT_DETAILS = 'contact-details'
export const PAYMENT_FREQUENCY = 'payment-frequency'
export const PAYMENT_DATE = 'payment-date'
export const PAYMENT_SUMMARY = 'payment-summary'
export const LEGAL_AGREEMENT = 'legal-agreement'
export const PL_DECLINED = '/try-foundation'
export const BUILD_CREDIT = 'build-credit'
export const SAVE_MONEY = 'save-money'
export const OUR_PROMISE = 'our-promise'
export const APPLY = 'apply'
export const QUALIFIED = 'qualified'
export const EXPIRED = 'expired'
export const REFUSED = 'refused'
export const ANALYZING_APPLICATION = 'analyzing-application'
export const PLAN_SELECTION = 'plan-selection'
export const FINANCIAL_SELECTOR = 'financial-selector'
