export default {
  computed: {
    uid () {
      return this.$store.state.application.cduid || this.$route.query.cduid
    },

    confirmationNumber () {
      if (!this.uid) return '######'
      return this.uid.substring(0, 6).toUpperCase()
    }
  }
}
