<template>
  <div class="app-shell">
    <AppBackground
      :module="module"
    />
    <!-- Hidden Fields (SID, AID, app_source, etc) -->
    <AppHiddenFields />

    <div>
      <router-link
        to="/documents"
        class="w-full"
      >
        <img
          class="app-shell__logo"
          :src="require('@/assets/img/nav/logo.svg')"
          alt="Spring Logo"
        >
      </router-link>
    </div>

    <!-- Router View -->
    <AppRouterViewContainer>
      <transition
        :name="transitionName"
        mode="out-in"
        @after-enter="$_stickyCTA_afterEnter"
        @before-leave="$_stickyCTA_beforeLeave"
      >
        <router-view
          :theme="theme"
          @show-bank-skip="$_stickyCTA_showBankSkip($event)"
        />
      </transition>
      <StepFooter
        v-if="$_stickyCTA_showFooter"
        fixed
      >
        <StepStickyCTA
          :theme="theme"
          :show-bank-skip="bankSkip"
          :continue-short-circuit="preventContinue"
        />
      </StepFooter>
    </AppRouterViewContainer>
    <!-- App Footer -->
    <AppFooter
      :theme="theme"
    />
  </div>
</template>

<script>
import AppFooter from '@/components/layout/AppFooter'
import AppHiddenFields from '@/components/common/fields/AppHiddenFields'
import transitions from '@/mixins/transitions'
import AppBackground from '@/components/common/background/AppBackground'
import AppRouterViewContainer from '@/components/common/AppRouterViewContainer'
import stickyCTA from '@/mixins/sticky-cta'
import StepFooter from '@/components/common/step-layout/StepFooter'
import theme from '@/mixins/theme'
import tracking from '@/mixins/tracking'
import StepStickyCTA from '@/components/common/step-layout/StepStickyCTA'

export default {
  // Name
  name: 'AppShellFooterOnly',

  // Components
  components: {
    AppFooter,
    AppHiddenFields,
    AppBackground,
    AppRouterViewContainer,
    StepFooter,
    StepStickyCTA
  },

  // Mixins
  mixins: [
    transitions,
    stickyCTA,
    theme,
    tracking
  ],

  props: {
    module: {
      type: String,
      default: undefined
    },
    canonicalModule: {
      type: String,
      default: ''
    }
  },

  head () {
    return {
      link: [
        { rel: 'canonical', href: this.$_tracking_canonicalURL(this.canonicalModule || this.module) }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/step-transitions';

.app-shell {
  @apply overflow-x-hidden pt-70 flex flex-col min-h-[100vh];

  &__logo {
    @apply h-50 mb-65 mx-auto w-auto;
  }
}
</style>
