<template>
  <div class="step-skip">
    <div>
      If you do not have your {{ verificationStep }} with you, you can upload it later and
      <button
        class="step-skip__link"
        :class="textColorClass()"
        @click="$emit('skip')"
      >
        skip for now
      </button>.
    </div>
  </div>
</template>

<script>
import theme from '@/mixins/theme'

export default {
  // Name
  name: 'StepSkip',

  mixins: [theme],

  // Props
  props: {
    verificationStep: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.step-skip {
  @apply m-auto max-w-400 mt-25 text-center;

  &__link {
    @apply font-semibold underline;
  }
}
</style>
