import Axios from 'axios'
import navigation from '@/mixins/navigation'
import DataLayer from '@/utilities/data-layer'

export default {
  // Mixins
  mixins: [navigation],

  // Data
  data () {
    return {
      instantSubmitRoute: process.env.VUE_APP_LEAD_API_INSTANT_SUBMIT_ROUTE,
      submitting: false
    }
  },

  // Computed
  computed: {
    $_submit_cduid () {
      return this.$store.state.application.cduid
    },

    $_submit_leadSubmitted () {
      return this.$store.state.application.leadSubmitted
    },

    $_submit_leadCreated () {
      return this.$store.state.application.leadCreated
    },

    $_submit_returningCustomerSubmitted () {
      return this.$store.state.application.returningCustomerSubmitted
    },

    $_submit_additionalLeadInfoSubmitted () {
      return this.$store.state.application.additionalLeadInfoSubmitted
    },

    $_submit_leadSubmittedToSF () {
      return this.$store.state.application.leadSubmittedToSF
    },

    $_submit_instantSubmitUrl () {
      return `${this.leadAPIUrl}${this.instantSubmitRoute}/${this.$_submit_cduid}`
    },

    $_submit_isLoanSubmitted () {
      return this.$store.getters['loans/isLoanSubmitted']
    }
  },

  // Methods
  methods: {
    /**
     * Submit Additional Lead Info
     * @desc Handles submitting additional data to the leadApi via the user's cduid
     * @method PATCH
     * @param {string} - submitUrl
     * @param {object} - additionalLeadData
    */
    async $_submit_additionalLeadInfo (submitUrl, additionalLeadData, submitFoundationPlus) {
      if ((
        !additionalLeadData ||
        !this.$_submit_cduid ||
        this.$_submit_additionalLeadInfoSubmitted) &&
        !submitFoundationPlus
      ) return

      try {
        await Axios({
          method: 'PATCH',
          url: `${submitUrl}/${this.$_submit_cduid}`,
          data: additionalLeadData
        })
        this.$store.dispatch('setAdditionalLeadInfoSubmitted', true)
      } catch (error) {
        console.warn(error)
      }
    },

    /**
     * Instantly convert the lead in Salesforce
     * @returns {promise}
    */
    async $_submit_instantLeadSubmission () {
      if (this.$_submit_leadSubmittedToSF) return
      try {
        await Axios({
          method: 'PATCH',
          url: this.$_submit_instantSubmitUrl
        })
        this.$store.dispatch('setLeadSubmittedToSF', true)
      } catch (error) {
        console.warn(error)
      }
    },

    /**
     * @desc Error Handling Helper Function
     */
    $_submit_handleErrors () {
      if (this.$_submit_isLoanSubmitted) {
        this.$_navigation_handleNext()
      } else {
        this.$router.push({
          path: '/error',
          query: { ...this.$route.query }
        })
      }
    },

    $_trigger_ezbot (phone, mockDecision) {
      const cduid = this.$store.state.application.cduid
      let url = mockDecision ? 'mock-ez-bot' : 'trigger-ez-bot'

      try {
        return Axios({
          method: 'POST',
          url: `${this.leadAPIUrl}${url}`,
          data: {
            cduid,
            home_phone: phone,
            credit_decision: mockDecision
          }
        })
      } catch (e) {
        console.warn('Failed to submit phone number')
      }
    },

    $_submit_storeTempEmail (email, trackingData) {
      try {
        return Axios({
          method: 'POST',
          url: `${this.leadAPIUrl}landing-email`,
          data: {
            email,
            ...trackingData,
            temp_lead_source: trackingData.lead_source,
            lead_source: ''
          }
        })
      } catch (e) {
        console.warn('Failed to submit temp')
      }
    },

    $_submit_clickedContinueByText (module) {
      const cduid = this.$store.state.application.cduid
      const eventData = {
        event: `spring_${module}_continue_by_text`,
        cduid,
        path: this.$route.path,
        timestamp: new Date().toISOString()
      }

      DataLayer.pushObject(eventData, true)
    }
  }
}
