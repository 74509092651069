import axios from 'axios'

const {
  VUE_APP_TWILIO_VERIFY_API_KEY,
  VUE_APP_TWILIO_SEND_CODE,
  VUE_APP_TWILIO_CHECK_CODE
} = process.env

export default class TwilioVerify {
  /**
   * Axios wrapper with X-API-KEY header for calls to our lambda Twilio middleware.
   * @param {string} url - lambda middleware endpoint
   * @param {any} data - user data to POST to lambda
   * @returns {promise}
   */
  static post (url, data) {
    return axios.post(url, data, {
      headers: { 'X-API-KEY': VUE_APP_TWILIO_VERIFY_API_KEY }
    }).catch(error => {
      throw error.response
    })
  }

  /**
   * Send the user's phone number and contact method preference to our lambda middleware
   * which returns a `verificationSid` and sends the user a Twilio code to their phone.
   * @method POST
   * @param {string} phone - the users phone number
   * @param {string} channel - message channel contact preference (sms|call)
   * @returns {promise}
   */
  static requestVerification (phone, channel) {
    return TwilioVerify.post(VUE_APP_TWILIO_SEND_CODE, { phone, channel })
  }

  /**
   * Send the Twilio verification code and verificationSid
   * value to confirm the user's verification process.
   * @method POST
   * @param {string} verificationSid - ID received from successful requestVerification API call
   * @param {string} code - the verification code sent to the users device to validate
   * @returns {promise}
   */
  static checkVerificationCode (verificationSid, code) {
    return TwilioVerify.post(VUE_APP_TWILIO_CHECK_CODE, { verificationSid, code })
  }
}
