import { extend } from 'vee-validate'
import {
  required,
  email,
  digits,
  numeric,
  max,
  max_value as maxValue,
  min,
  min_value as minValue
} from 'vee-validate/dist/rules'
import dayjs from 'dayjs'
import isEmail from 'validator/lib/isEmail'
// import en from 'vee-validate/dist/locale/en'

const ageOfMajority = {
  AB: 18,
  BC: 19,
  MB: 18,
  NB: 19,
  NL: 19,
  NS: 19,
  NT: 19,
  NU: 19,
  ON: 18,
  PE: 18,
  QC: 18,
  SK: 18,
  YT: 19
}

// Add the required rule
extend('required',
  {
    ...required,
    params: ['label'],
    message: (value, { label }) => {
      if (label) return `${label} is required.`
      return 'This field is required.'
    }
  }
)

// Add the email rule
extend('email', {
  ...email,
  message: 'Enter a valid email address.'
})

// Only english chars email rule
extend('englishEmail', {
  ...email,
  validate: (value) => isEmail(value, { allow_utf8_local_part: false }),
  message: 'Please enter an email address without non-English characters.'
})

// Length Rule
extend('digits', {
  ...digits,
  message: 'Please enter {length} digits.'
})

// Numeric Rule
extend('numeric', {
  ...numeric,
  message: 'Invalid number.'
})

// Max Rule
extend('max', {
  ...max,
  params: ['length'],
  message: 'Please enter at most {length} characters.'
})

// Max Digit Rule
extend('max_digits', {
  params: ['length'],
  message: 'Please enter at most {length} digits.',
  validate: (value, { length }) => {
    const cleanNumber = value.replace(/,|_/g, '').length
    return cleanNumber <= +length
  }
})

// Max Value Rule
extend('max_value', {
  ...maxValue,
  params: ['max'],
  message: 'Cannot exceed {max}.'
})

// Min Rule
extend('min', {
  ...min,
  params: ['length'],
  message: 'Please enter at least {length} characters.'
})

// Min Value Rule
extend('min_value', {
  ...minValue,
  params: ['min'],
  message: 'Please enter a value greater than or equal to {min}.'
})

// Currency Rule
extend('currency', {
  message: 'Invalid number.',
  validate: (value) => {
    return /^\d+(?:\.\d{0,2})?$/.test(value)
  }
})

// Minimum Currency Rule
extend('min_currency', {
  message: 'Please enter a value above {min}',
  params: ['min'],
  validate: (value, { min }) => {
    const cleanNumber = +value.replace(/,|_/g, '')
    if (typeof min === 'string') return cleanNumber >= +min
    return cleanNumber >= +min.join('')
  }
})

// Maximum Currency Rule
extend('max_currency', {
  message: 'Please enter a value below {max}',
  params: ['max'],
  validate: (value, { max }) => {
    const cleanNumber = +value.replace(/,|_/g, '')
    return cleanNumber <= +max.join('')
  }
})

/**
 * Required Years Rule
 */
extend('requiredYears', {
  computesRequired: true,
  validate: (value, { months }) => {
    return +value > 0 || +months > 0
  },
  message: 'Entry cannot be 0 years and 0 months.',
  params: [{ name: 'months', isTarget: true }]
})

/**
 * Required Months
 */
extend('requiredMonths', {
  computesRequired: true,
  validate: (value, { years }) => {
    return +value > 0 || +years > 0
  },
  message: 'Enter \'1\' month if you just {postfix}.',
  params: [{ name: 'years', isTarget: true }, 'postfix']
})

/**
 * cpostVerified Rule
 */
extend('cpostVerified', {
  computesRequired: true,
  validate: (value, { verified }) => verified === 1,
  params: [{ name: 'verified', isTarget: true }]
})

/**
 * Postal Code Rule
 */
extend('postalCode', {
  validate: (value) => (/^[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]$/).test(value),
  message: 'Please specify a valid postal code.'
})

/**
 * Is Valid Date
 */
extend('isValidDate', {
  validate: (value) => {
    const rawDate = value.replace(/_| /g, '')
    const parsedDate = dayjs(rawDate)
    const formattedDate = parsedDate.format('MM/DD/YYYY')
    return parsedDate.isValid() && rawDate === formattedDate && rawDate.match(/\d\d\/\d\d\/\d\d\d\d/)
  },
  message: 'Invalid Date.'
})

/**
 * Is the customer of-age?
 */
extend('isOfAge', {
  validate: (value, { province }) => {
    const minAge = province ? ageOfMajority[province] : 18

    const minDate = dayjs().subtract(100, 'year')
    const maxDate = dayjs().subtract(minAge, 'year')
    return value.isAfter(minDate) && value.isBefore(maxDate)
  },
  castValue: (value) => dayjs(value.trim().replace(/ /g, '')), // casts the value
  params: ['province'],
  message: (_, { province }) => {
    return `Must be at least ${province ? ageOfMajority[province] : 18} years of age.`
  }
})

/**
 * Phone Number
 */
extend('phone', {
  validate: (value) => {
    const cleanValue = value.trim().replace(/ /g, '')
    return /^(\+?1\s?-?)?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4}$/.test(cleanValue)
  },
  message: 'Please specify a valid phone number.'
})

/**
 * Max Year (Current Year + 1)
 */
extend('maxYear', {
  validate: (value) => {
    return (value <= new Date().getFullYear() + 1)
  },
  message: `Cannot exceed ${new Date().getFullYear() + 1}.`
})

/**
 * Validate Twilio code
 */
extend('isVerified', {
  validate: (value, [isVerified]) => {
    return isVerified === 'true'
  },
  message: 'The code you entered is invalid. Please try again.'
})

/**
 * Validate Expired Twilio Code
 */
extend('verificationExpired', {
  validate: (value, [verificationExpired]) => {
    return verificationExpired === 'true'
  },
  message: 'Your verification code has expired. Please click Resend to receive a new one.'
})

/**
 * Validate File Type
 */
extend('fileTypeValid', {
  validate: (value) => {
    return /image\/jpg|image\/jpeg|image\/png|image\/gif|application\/pdf/
      .test(value.type.toLowerCase())
  },
  message: 'Invalid image format. Accepted formats: jpg, jpeg, png, gif, pdf.'
})

/**
 * Validate File Extension
 */
extend('fileExtensionValid', {
  validate: (value) => {
    const extension = value.name.split('.').pop()
    return /jpg|jpeg|png|gif|pdf/.test(extension.toLowerCase())
  },
  message: 'Invalid image format. Accepted formats: jpg, jpeg, png, gif, pdf.'
})

/**
 * Validate File Size
 */
extend('fileSizeValid', {
  validate: (value) => {
    const maxFileSize = 40000000
    return value.size.toFixed(2) < maxFileSize
  },
  message: 'File size must be less than 40mb.'
})

/**
 * Validate File Upload
 */
extend('fileUploadSuccess', {
  params: ['uploadSuccess'],
  validate: (value, { uploadSuccess }) => {
    return uploadSuccess === true
  },
  message: 'The file failed to upload, please try again.'
})

/**
 * Validate File Uploading
 */
extend('fileUploading', {
  params: ['uploading'],
  validate: (value, { uploading }) => {
    return uploading === false
  },
  message: 'The file is being uploaded, please wait'
})

/**
 * Validate File Required
 */
extend('fileRequired',
  {
    ...required,
    message: 'Please upload a file and then Continue'
  }
)
