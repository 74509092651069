import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utilities/vue-config'
import '@/utilities/window'
import store from './store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/assets/scss/main.scss'
import '@/plugins/vee-validate'
import '@/plugins/v-click-outside'
import '@/plugins/vue-pincode-input'
import '@/plugins/observe-visibility'
import Vue2TouchEvents from 'vue2-touch-events'
import Meta from 'vue-meta'
import * as rudderanalytics from 'rudder-sdk-js'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
/**
 * Dynamically import the FontAwesomeIcon component
 */
Vue.component(
  'FontAwesomeIcon',
  () => import(/* webpackChunkName: "font-awesome" */ '@/plugins/font-awesome')
)

Vue.use(Meta, {
  keyName: 'head'
})
window.rudderanalytics = rudderanalytics
rudderanalytics.load(process.env.VUE_APP_RUDDERSTACK_WRITE_KEY, process.env.VUE_APP_RUDDERSTACK_DATA_PLANE_URL)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(Vue2TouchEvents)
