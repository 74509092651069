<template>
  <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="a"
        x1="8.042%"
        y1="0%"
        x2="65.682%"
        y2="23.865%"
      >
        <stop
          :stop-color="color"
          stop-opacity="0"
          offset="0%"
        />
        <stop
          :stop-color="color"
          stop-opacity=".631"
          offset="63.146%"
        />
        <stop
          :stop-color="color"
          offset="100%"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(1 1)">
        <path
          id="Oval-2"
          d="M36 18c0-9.94-8.06-18-18-18"
          stroke="url(#a)"
          stroke-width="2"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
        <circle
          :fill="color"
          cx="36"
          cy="18"
          r="1"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  // Name
  name: 'AppSpinner',

  // Props
  props: {
    color: {
      type: String,
      default: '#fff',
      requried: false
    },
    size: {
      type: Number,
      default: 38,
      requried: false
    }
  },

  computed: {
    viewBox () {
      return `0 0 ${this.size} ${this.size}`
    }
  }
}
</script>

<style scoped lang="scss">
</style>
