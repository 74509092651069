export default {
  namespaced: true,

  state: {
    application_in_progress: false,
    living_in_provinces: '',
    province_name: '',
    amount_owing: '',
    home_ownership_duration: '',
    home_value: '',
    current_interest_rate: ''
  },

  getters: {
    leadSubmissionData (state, getters, rootState) {
      const trackingValue = rootState.tracking['SID2'] || ''
      const SID2 = trackingValue.split(',').pop()
      const tracking = { ...rootState.tracking, SID2 }

      const {
        application,
        customer,
        location,
        mortgages
      } = rootState

      return {
        ...application,
        ...tracking,
        ...customer,
        ...location,
        ...mortgages
      }
    }
  },

  mutations: {
    SET_LIVING_IN_PROVINCES (state, livingInProvinces) {
      state.living_in_provinces = livingInProvinces
    },

    SET_PROVINCE_NAME (state, provinceName) {
      state.province_name = provinceName
    },

    SET_AMOUNT_OWING (state, amountOwing) {
      state.amount_owing = amountOwing
    },

    SET_HOME_OWNERSHIP_DURATION (state, duration) {
      state.home_ownership_duration = duration
    },

    SET_HOME_VALUE (state, homeValue) {
      state.home_value = homeValue
    },

    SET_CURRENT_INTEREST_RATE (state, interestRate) {
      state.current_interest_rate = interestRate
    },

    SET_APPLICATION_IN_PROGRESS (state, inProgress) {
      state.application_in_progress = inProgress
    }
  },

  actions: {
    setLivingInProvinces ({ commit }, livingInProvinces) {
      commit('SET_LIVING_IN_PROVINCES', livingInProvinces)
    },

    setProvinceName ({ commit }, provinceName) {
      commit('SET_PROVINCE_NAME', provinceName)
    },

    setAmountOwing ({ commit }, amountOwing) {
      commit('SET_AMOUNT_OWING', amountOwing)
    },

    setHomeOwnershipDuration ({ commit }, duration) {
      commit('SET_HOME_OWNERSHIP_DURATION', duration)
    },

    setHomeValue ({ commit }, homeValue) {
      commit('SET_HOME_VALUE', homeValue)
    },

    setCurrentInterestRate ({ commit }, interestRate) {
      commit('SET_CURRENT_INTEREST_RATE', interestRate)
    },

    setApplicationInProgress ({ commit }, inProgress) {
      commit('SET_APPLICATION_IN_PROGRESS', inProgress)
    }
  }
}
