import localForage from 'localforage'

export default class LocalStorage {
  static async setItem (key, value) {
    return localForage.setItem(key, value)
  }

  static async getItem (key) {
    return localForage.getItem(key)
  }

  static async removeItem (key) {
    return localForage.removeItem(key)
  }

  static async clear () {
    return localForage.clear()
  }
}
