export const initialState = () => {
  return {
    id: false,
    idFile: undefined,
    proofOfIncome: false,
    proofOfIncomeFile1: undefined,
    proofOfIncomeFile2: undefined,
    poa: false,
    poaFile: undefined,
    bankStatements: false,
    otherDocuments: false,
    otherDocumentsFile: undefined,
    submitIfReady: false,
    poaRequired: true,
    poiRequired: true
  }
}

export default {
  state: initialState(),

  getters: {
    isReadyToSubmit (state, getters, rootState) {
      if (!state.submitIfReady) return false
      if (rootState.account.accountId && rootState.account.oppId) return false
      if (!getters.collectAllPLDocs) return (state.id && state.bankStatements)
      const bankStatement = state.bankStatements || !process.env.VUE_APP_FLINKS_ENABLED
      // if backend determined we don't need to collect poa
      // check if we also need to collect poi
      if (!state.poaRequired) return state.poiRequired ? (state.id && state.proofOfIncome && bankStatement) : (state.id && bankStatement)
      return state.poiRequired ? (state.id && state.proofOfIncome && state.poa && bankStatement) : (state.id && state.poa && bankStatement)
    },

    docsUploadCount (state) {
      const documents = [state.bankStatements, state.id, state.proofOfIncome, state.poa]
      return documents.filter(Boolean).length
    },

    docsUploadedDataLayerString (state) {
      const documents = [
        {
          exist: state.bankStatements,
          name: 'flinks'
        },
        {
          exist: state.id,
          name: 'id'
        },
        {
          exist: state.proofOfIncome,
          name: 'income'
        },
        {
          exist: state.poa,
          name: 'address'
        }
      ]

      return documents
        .reduce((a, document) => {
          return document.exist ? [...a, document.name] : a
        }, [])
        .join('-')
    }
  },

  mutations: {
    SET_ID (state, photoId) {
      state.id = photoId
    },

    SET_ID_FILE (state, photoIdFile) {
      state.idFile = photoIdFile
    },

    SET_PROOF_OF_INCOME (state, proofOfIncome) {
      state.proofOfIncome = proofOfIncome
    },

    SET_PROOF_OF_INCOME_1_FILE (state, proofOfIncomeFile1) {
      state.proofOfIncomeFile1 = proofOfIncomeFile1
    },

    SET_PROOF_OF_INCOME_2_FILE (state, proofOfIncomeFile2) {
      state.proofOfIncomeFile2 = proofOfIncomeFile2
    },

    SET_POA (state, proofOfAddress) {
      state.poa = proofOfAddress
    },

    SET_POA_REQUIRED (state, poaRequired) {
      state.poaRequired = poaRequired
    },

    SET_POA_FILE (state, proofOfAddressFile) {
      state.poaFile = proofOfAddressFile
    },

    SET_OTHER_DOCUMENTS (state, otherDocuments) {
      state.otherDocuments = otherDocuments
    },

    SET_OTHER_DOCUMENTS_FILE (state, otherDocumentsFile) {
      state.otherDocumentsFile = otherDocumentsFile
    },

    SET_BANK_STATEMENTS (state, bankStatement) {
      state.bankStatements = bankStatement
    },

    SET_SUBMIT_IF_READY (state, submitIfReady) {
      state.submitIfReady = submitIfReady
    },

    RESET_DOCUMENTS_STATE (state, initialState) {
      Object.keys(initialState).forEach(key => (state[key] = initialState[key]))
    },

    SET_POI_REQUIRED (state, poiRequired) {
      state.poiRequired = poiRequired
    }
  },

  actions: {
    setId ({ commit }, photoId) {
      commit('SET_ID', photoId)
    },

    setIdFile ({ commit }, photoIdFile) {
      commit('SET_ID_FILE', photoIdFile)
    },

    setProofOfIncome ({ commit }, proofOfIncome) {
      commit('SET_PROOF_OF_INCOME', proofOfIncome)
    },

    setProofOfIncomeFile1 ({ commit }, proofOfIncomeFile1) {
      commit('SET_PROOF_OF_INCOME_1_FILE', proofOfIncomeFile1)
    },

    setProofOfIncomeFile2 ({ commit }, proofOfIncomeFile2) {
      commit('SET_PROOF_OF_INCOME_2_FILE', proofOfIncomeFile2)
    },

    setPOA ({ commit }, proofOfAddress) {
      commit('SET_POA', proofOfAddress)
    },

    setPOARequired ({ commit }, poaRequired) {
      commit('SET_POA_REQUIRED', poaRequired)
    },

    setPOAFile ({ commit }, proofOfAddressFile) {
      commit('SET_POA_FILE', proofOfAddressFile)
    },

    setBankStatements ({ commit }, bankStatement) {
      commit('SET_BANK_STATEMENTS', bankStatement)
    },

    setOtherDocuments ({ commit }, otherDocuments) {
      commit('SET_OTHER_DOCUMENTS', otherDocuments)
    },

    setOtherDocumentsFile ({ commit }, otherDocumentsFile) {
      commit('SET_OTHER_DOCUMENTS_FILE', otherDocumentsFile)
    },

    setSubmitIfReady ({ commit }, submitIfReady) {
      commit('SET_SUBMIT_IF_READY', submitIfReady)
    },

    resetDocumentsState ({ commit }) {
      commit('RESET_DOCUMENTS_STATE', initialState())
    },

    setPOIRequired ({ commit }, poiRequired) {
      commit('SET_POI_REQUIRED', poiRequired)
    }

  }
}
