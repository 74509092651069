<template>
  <div class="app-background">
    <AppBackgroundPersonalLoans v-if="module === 'personal-loans'" />
    <AppBackgroundMortgages v-else-if="module === 'mortgages'" />
    <AppBackgroundFoundation v-else-if="module === 'foundation'" />
    <AppBackgroundEvergreen v-else-if="module === 'evergreen-loan'" />
  </div>
</template>

<script>
import AppBackgroundPersonalLoans from './AppBackgroundPersonalLoans.vue'
import AppBackgroundMortgages from './AppBackgroundMortgages.vue'
import AppBackgroundFoundation from './AppBackgroundFoundation.vue'
import AppBackgroundEvergreen from './AppBackgroundEvergreen.vue'

export default {
  name: 'AppBackground',
  components: {
    AppBackgroundPersonalLoans,
    AppBackgroundMortgages,
    AppBackgroundFoundation,
    AppBackgroundEvergreen
  },
  props: {
    module: {
      type: String,
      validator (value) {
        return ['personal-loans', 'mortgages', 'foundation', 'evergreen-loan'].includes(value)
      },
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.app-background {
  @apply z-n1 top-0 h-full min-h-screen min-w-full absolute overflow-hidden;

  background: linear-gradient(0deg, #fff 57%, #ededff 100%);

  :deep() {
    .shape {
      @apply absolute;
    }

    .flip-vertical {
      @apply scale-y-[-1];
    }

    .flip-horizontal {
      @apply scale-x-[-1];
    }
  }
}
</style>
