import preventRefresh from '@/router/utils/prevent-refresh'
import AppShellQuiz from '@/components/application/AppShellQuiz'
import DataLayer from '@/utilities/data-layer'

// AppName / Variation
// Used to build the app_source field
const appName = 'springquiz'
const variation = 'v1'
const basePath = '/quiz'
const leadType = 'Personal Loan'

const props = {
  eventPrefix: 'quiz'
}

const steps = [
  'Biggest money headache? 🤔',
  'How do you handle unexpected expenses? 💸',
  'What would you do with 5k? 🤑',
  'Ready to know your financial preparedness? 🤓',
  'You’re financially ready!'
]

const stepPaths = () => {
  return steps.map((step, index) => {
    return {
      path: `${index + 1}`,
      component: () => import(
        `@/components/steps/quiz/components/steps/QuizStep${index + 1}`
      ),
      props: {
        ...props,
        step: index + 1,
        title: step
      },
      meta: {
        index: index + 1,
        nextStep: `${index + 2}`
      },
      beforeEnter: (to, from, next) => {
        DataLayer.pushEvent('spring_quiz_step', `step-${index + 1}`)
        return next()
      }
    }
  })
}

const routes = [
  { path: basePath,
    component: AppShellQuiz,
    props: {
      ...props,
      leadType
    },
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: () => import(
          '@/components/steps/quiz/QuizLanding'
        ),
        props: {
          ...props
        },
        meta: {
          index: 0,
          nextStep: '1'
        },
        beforeEnter: (to, from, next) => {
          DataLayer.pushEvent('spring_quiz', `landed`)
          return next()
        }
      },
      ...stepPaths()
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
