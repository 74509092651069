import AppShellOffers from '@/components/application/AppShellOffers'
import preventRefresh from '@/router/utils/prevent-refresh'
import * as ROUTE_PATHS from '../../constants/route-paths'
import OFFER_STATUS from '../../constants/offer-status'
import store from '@/store'

const FoundationAgreementLanding = () => import(
  /* webpackChunkName: "foundation-agreement-v1" */
  '@/components/steps/foundation-agreement/FoundationAgreementLanding'
)

const LegalAgreement = () => import(
  /* webpackChunkName: "foundation-agreement-v1" */
  '@/components/steps/foundation-agreement/LegalAgreement'
)
const ApplicationSuccessful = () => import(
  /* webpackChunkName: "foundation-agreement-v1" */
  '@/components/steps/foundation-agreement/ApplicationSuccessful'
)

const Expired = () => import(
  /* webpackChunkName: "foundation-agreement-v1" */
  '@/components/steps/foundation-agreement/Expired'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */

const appName = 'spring-foundation-agreement'
const variation = 'v1'
const basePath = '/foundation-agreement'
const leadType = 'Foundation Agreement'

const props = {
  eventPrefix: 'foundation-agreement',
  lang: 'en' // default value
}

const nextStepStatusResolver = (nextStep, status) => {
  switch (status) {
    case OFFER_STATUS.EXPIRED: return ROUTE_PATHS.EXPIRED
    case OFFER_STATUS.COMPLETED: return ROUTE_PATHS.APPLICATION_SUCCESS
    default: return nextStep
  }
}

const getLangFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('lang') || 'en'
}

const progressLabel = (step) => {
  const labels = {
    step_1: {
      en: 'Step 1 of 2',
      fr: 'Étape 1 sur 2'
    },
    final_step: {
      en: 'Final Step',
      fr: 'Dernière Étape'
    }
  }

  const lang = getLangFromUrl()
  return labels[step][lang] || labels[step].en
}

const routes = [
  {
    path: basePath,
    component: AppShellOffers,
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    props: route => {
      return {
        theme: 'purple',
        hideMenu: true,
        module: 'foundation-agreement',
        lang: route.query.lang === 'fr' ? 'fr' : 'en'
      }
    },
    beforeEnter: (to, from, next) => {
      preventRefresh(to, from, next, basePath)
    },
    children: [
      {
        path: '',
        component: FoundationAgreementLanding,
        beforeEnter: (to, from, next) => {
          const eContract = to.query.id
          const token = to.query.token
          if (!eContract && !token) return next({ path: '/error' })
          next()
        },
        meta: {
          nextStep: () => nextStepStatusResolver(ROUTE_PATHS.LEGAL_AGREEMENT, store.state.offers.opportunity.status),
          index: 1,
          progressLabel: progressLabel('step_1')
        },
        props: route => ({ ...props, lang: route.query.lang === 'fr' ? 'fr' : 'en' })
      },
      {
        path: ROUTE_PATHS.LEGAL_AGREEMENT,
        component: LegalAgreement,
        meta: {
          nextStep: ROUTE_PATHS.APPLICATION_SUCCESS,
          index: 4,
          progressLabel: progressLabel('final_step')
        },
        props: route => ({ ...props, lang: route.query.lang === 'fr' ? 'fr' : 'en' })
      },
      {
        path: ROUTE_PATHS.APPLICATION_SUCCESS,
        component: ApplicationSuccessful,
        meta: {
          index: 5,
          progress: false
        },
        props: route => ({ ...props, lang: route.query.lang === 'fr' ? 'fr' : 'en' })
      },
      {
        path: ROUTE_PATHS.EXPIRED,
        component: Expired,
        meta: {
          index: 2,
          progress: false
        },
        props: route => ({ ...props, lang: route.query.lang === 'fr' ? 'fr' : 'en' })
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
