export default [{
  title: 'Why am I not approved for a Personal Loan?',
  answer: `There could be a number of reasons why you may not be approved for a loan today. 
  These may include your credit score, the amount of debt you currently have, your income or current insolvency status. 
  The Foundation can help you improve your credit and with our Gold option, you are guaranteed a $1500 loan offer upon completion.`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'How does The Foundation build credit?',
  answer: `The Foundation helps you build credit by reporting payment history to the major credit bureaus in Canada. 
  Making all your payments on time will prove to banks and lenders that you are able to 
  manage your credit and increase your chances of getting an approval.`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'How is this going to help me when I need money right now?',
  answer: `We understand that you may have an immediate cash need, and while The Foundation cannot give you money up front, 
  our Gold plan helps you save money and guarantees you a personal loan in 12 months. 
  Our goal is to help all customers avoid a situation where they need cash and cannot access it.`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'What are my payment options?',
  answer: `Upon completing the sign-up process, our team will select one of the following approximate payment options for you and notify you by email: <br> - Bi-Weekly (Gold $66 / Basic $37 ) <br> - Semi-Monthly (Gold $71 / Basic $40)<br> - Monthly (Gold $142 / Basic $80)`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'What happens when I cancel?',
  answer: `You can cancel at any time with no extra fees. When you cancel, your payments will stop and any savings you have will be credited to you (after any missed payments are covered).`,
  showAnswer: false,
  sideArrow: true
}]

export const foundationBloomFAQ = [{
  title: 'Why am I not approved for a Personal Loan?',
  answer: `There could be a number of reasons why you may not be approved for a loan today. 
  These may include your credit score, the amount of debt you currently have, your income or current insolvency status. 
  The Foundation can help you improve your credit and with our Gold option, you are guaranteed a $1500 loan offer upon completion.`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'How does The Foundation build credit?',
  answer: `The Foundation helps you build credit by reporting payment history to the major credit bureaus in Canada. 
  Making all your payments on time will prove to banks and lenders that you are able to 
  manage your credit and increase your chances of getting an approval.`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'How is this going to help me when I need money right now?',
  answer: `We understand that you may have an immediate cash need, and while The Foundation cannot give you money up front, 
  our Gold plan helps you save money and guarantees you a personal loan in 12 months. 
  Our goal is to help all customers avoid a situation where they need cash and cannot access it.`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'What are my payment options?',
  answer: `Upon completing the sign-up process, our team will select one of the following approximate payment options for you and notify you by email: <br> - Bi-Weekly (Gold $71 / Basic $37 ) <br> - Semi-Monthly (Gold $77 / Basic $40)<br> - Monthly (Gold $153 / Basic $80)`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'What happens when I cancel?',
  answer: `You can cancel at any time with no extra fees. When you cancel, your payments will stop and any savings you have will be credited to you (after any missed payments are covered).`,
  showAnswer: false,
  sideArrow: true
},
{
  title: 'What is Bloom?',
  answer: `Bloom is the ultimate financial assistant, revolutionizing financial management with a seamless platform for crafting flexible budgets tailored to individual goals. It simplifies expense tracking by connecting accounts, revealing spending patterns, and pinpointing savings opportunities. Bloom empowers you to take control of your finances, facilitating easy management of expenses, discovery of potential savings, and monitoring of cost-reduction progress in a user-friendly setup. Bloom also allows you to report your rent monthly for an additional boost in your credit history`,
  showAnswer: false,
  sideArrow: true
}
]
