<template>
  <div>
    <div class="app-footer">
      <div class="app-footer__wrapper">
        <div class="app-footer__inner">
          <slot />

          <p class="mb-30 text-xs">
            <span v-if="showLicense">
              <small>
                {{ licenseLabel }}
                <span
                  class="app-footer__license"
                  @click="toggleModal"
                >
                  {{ licenseNumber }}
                </span>
              </small>
              <SpringPopup
                v-if="showModal"
                :closable="true"
                :back-drop-closable="true"
                padding="px-15 py-30"
                @hide="toggleModal"
              >
                <img :src="licenseImage">
              </SpringPopup>
            </span>
            {{ loanInfoList.partOne }}
            <br><br>
            {{ loanInfoList.partTwo }}
            <br><br>
            {{ finePrint }}
            <br><br>
            {{ address }}
            <br><br>
            {{ copyrightList.partOne }} <br class="block md:hidden"> {{ copyrightList.partTwo }}
          </p>

          <div>
            <a
              v-for="(legalLink, index) in legalLinks"
              :key="index"
              :href="legalLink.link + `?lang=${lang}`"
              class="app-footer__link"
              @click="pushFooterEvent(legalLink.name)"
            >
              <small
                class="hover:underline"
                v-text="legalLink.name[lang] || legalLink.name"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SpringPopup } from 'spring-ui'
import theme from '@/mixins/theme'
import tracking from '@/mixins/tracking'

export default {
  // Name
  name: 'AppFooter',

  // Components
  components: {
    SpringPopup
  },

  // Mixins
  mixins: [
    theme,
    tracking
  ],

  // Props
  props: {
    showLicense: {
      type: Boolean,
      default: true
    },

    lang: {
      type: String,
      default: 'en'
    }
  },

  // Data
  data () {
    return {
      showModal: false,
      links: [
        {
          text: 'Privacy Policy',
          url: `${process.env.VUE_APP_MINISITE_URL}privacy-policy`
        },
        {
          text: 'General Terms',
          url: `${process.env.VUE_APP_MINISITE_URL}general-terms-of-service`
        },
        {
          text: 'Website Terms of Use',
          url: `${process.env.VUE_APP_MINISITE_URL}website-terms-of-use`
        },
        {
          text: 'Help Centre',
          url: `${process.env.VUE_APP_MINISITE_URL}help-centre`
        }
      ]
    }
  },

  computed: {
    routePath () {
      return this.$route.path
    },
    licenseLabel () {
      return this.lang === 'fr' ? 'License' : 'Licence'
    },
    licenseNumber () {
      return this.lang === 'fr' ? 'n° 17453-1' : '#349123'
    },
    licenseImage () {
      if (this.lang === 'fr') {
        return require('@/assets/img/license2024_fr.png')
      }
      return require('@/assets/img/license2024.png')
    },
    loanInfoList () {
      let loanInfo = {}
      if (this.lang === 'fr') {
        loanInfo = {
          partOne: `* Spring Financial Inc. propose des prêts allant jusqu'à 35 000 $ avec des taux d'intérêt variant de 9,99 % à 46,99 %, et des durées de 6 à 60 mois. Les TAEG sur ces prêts varient entre 10,8 % et 46,99 %. Votre taux réel dépendra de divers facteurs tels que votre cote de crédit et le montant du prêt`,
          partTwo: `Un exemple des coûts d'emprunt payés sur un prêt de 5 000 $ d'une durée de 60 mois à un TAEG de 10,87 % est un paiement hebdomadaire de 24,43 $, le montant total du remboursement du principal et des intérêts étant de 6 352,33 $ et le coût total du prêt (intérêts seulement) étant de 1 352,33 $.`
        }
      } else {
        loanInfo = {
          partOne: `* Spring Financial Inc. provides loans up to $35,000 with interest rates ranging from 9.99% to 46.99%, and terms from 6 to 60 months. APRs on such loans range between 10.8% to 46.99%. Your actual rate will depend on a variety of factors such as your credit score and the loan amount`,
          partTwo: `An example of borrowing costs paid on a $5,000 loan with a 60 month term at 10.87% APR is a Weekly payment of $24.43, with the total repayment amount of principal and interest being $6,352.33 and the total cost of the loan (interest only) being $1,352.33.`
        }
      }
      return loanInfo
    },
    finePrint () {
      if (this.lang === 'fr') {
        return "Pour être admissible à la Fondation, vous devez posséder un compte actif auprès d'une banque ou d'une institution financière, approuvé par Spring Financial, ainsi qu'une pièce d'identité canadienne valide délivrée par le gouvernement. La Fondation est disponible dans toutes les provinces à l'exception de Saskatchewan et du Nouveau-Brunswick. Les résultats de la Fondation varient d'une personne à l'autre. Toutes les références aux garanties sont faites en rapport avec le prêt Evergreen. Pour être éligible au prêt Evergreen, vous devez d'abord terminer avec succès les 12 mois de la Fondation et épargner 750 $, avoir un compte actif auprès d'une banque ou d'une institution financière, approuvé par Spring Financial, et une pièce d'identité canadienne valide émise par le gouvernement."
      } else {
        return 'To qualify for The Foundation, you must have an active account with a bank or financial institution, as approved by Spring Financial, and a valid government-issued Canadian ID. The Foundation is available in all provinces except Saskatchewan and New Brunswick. Results from The Foundation depend on the individual. All guarantee references are made in connection to the Evergreen Loan. To qualify for the Evergreen Loan, you must first successfully complete 12 months on The Foundation and save $750, have an active account with a bank or financial institution, as approved by Spring Financial, and a valid government-issued Canadian ID.'
      }
    },
    address () {
      if (this.lang === 'fr') {
        return "Le siège social de Spring Financial Inc. se trouve à l'adresse suivante : Suite 600, 555 Rue Burrard, Two Bentall Centre, Vancouver, Colombie-Britannique, Canada, V7X 1M8."
      } else {
        return 'Spring Financial Inc. is headquartered at Suite 600, Two Bentall Centre, 555 Burrard Street, Vancouver, British Columbia, Canada, V7X 1M8.'
      }
    },
    copyrightList () {
      let copyright = {}
      if (this.lang === 'fr') {
        copyright = {
          partOne: `Copyright © ${(new Date()).getFullYear()} Spring Financial Inc. Tous droits réservés.`,
          partTwo: `Spring Financial Inc. et ses prêts ne sont affiliés à aucune agence d'évaluation du crédit.`
        }
      } else {
        copyright = {
          partOne: `Copyright © ${(new Date()).getFullYear()} Spring Financial Inc. All rights reserved.`,
          partTwo: `Spring Financial Inc. and its loans are not affiliated with any credit bureaus.`
        }
      }
      return copyright
    },

    legalLinks () {
      const baseLinks = [
        {
          name: {
            en: 'Privacy Policy',
            fr: 'Politique de confidentialité'
          },
          link: '/privacy-policy'
        },
        {
          name: {
            en: 'General Terms',
            fr: 'Conditions générales'
          },
          link: '/general-terms-of-service'
        },
        {
          name: {
            en: 'Website Terms of Use',
            fr: 'Conditions d\'utilisation du site web'
          },
          link: '/website-terms-of-use'
        }
      ]
      if (this.lang === 'en') {
        baseLinks.push({
          name: 'Help Centre',
          link: '/help-centre'
        })
      }
      return baseLinks
    }
  },

  watch: {
    routePath (to, from) {
      this.showModal = false
    }
  },

  // Methods
  methods: {
    toggleModal () {
      this.showModal = !this.showModal
    }
  }
}
</script>

<style scoped lang="scss">
.app-footer {
  @apply container mb-90 pb-30 text-black-500 w-full pt-50;

  @screen sm {
    @apply mb-15;
  }

  @screen md {
    @apply mb-0;
  }

  small {
    @apply block text-xs leading-3 mb-30;
  }

  &__wrapper {
    @apply border-t border-solid border-black-200 flex justify-center;
  }

  &__inner {
    @apply max-w-500 pt-50;

    @screen md {
      @apply max-w-none;
    }
  }

  &__link {
    @apply text-xs relative inline-block mr-20;

    &::after {
      @apply block absolute pointer-events-none -top-px -right-3;

      content: '|';
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  &__license {
    @apply underline cursor-pointer;
  }
}

.footer-container {
  @apply flex flex-row items-center justify-center border-1 p-5 rounded-10 font-display;

  &__middle {
    @apply justify-between w-full grid gap-y-5 grid-rows-3 mb-20;

    @screen sm {
      @apply gap-y-0 gap-x-5 grid-cols-3 grid-rows-1;
    }
  }

  &__title {
    @apply font-bold text-2xl;
  }

  img {
    @apply my-10 mx-20;
  }

  &.hidden {
    @apply hidden;
  }
}
</style>
