<template>
  <div
    class="app-background"
  />
</template>

<script>

export default {
  name: 'AppGradientBackground'
}
</script>

<style scoped lang="scss">
.app-background {
  @apply z-n1 top-0 h-full min-h-screen min-w-full absolute;

  background: radial-gradient(130% 60% at 10% -5%, #e2e7f7 0%, #f5faff 51.56%, #fff 100%);

  @screen lg {
    background: radial-gradient(50% 50% at 70% 30%, #e2e7f7 0%, #f5faff 51.56%, #fff 100%);
  }
}
</style>
