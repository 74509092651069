import AppShellLoans from '@/components/application/AppShellLoans'
import preventRefresh from '@/router/utils/prevent-refresh'
import DataLayer from '@/utilities/data-layer'
import store from '@/store'
import * as ROUTE_PATHS from '@/constants/route-paths'
import * as PROGRESS_LABELS from '@/constants/progress-label'
import * as LEAD_TYPE from '@/constants/lead-type'
import faq, { foundationBloomFAQ } from '@/static/faq/foundation'

const EducationIntro = () => import(
  /* webpackChunkName: "foundation-declined-pl-v1" */
  '@/components/steps/foundation/EducationIntro'
)

const PlanSelection = () => import(
  /* webpackChunkName: "foundation-declined-pl-v1" */
  '@/components/steps/foundation/PlanSelection'
)

const PaymentSummary = () => import(
  /* webpackChunkName: "foundation-declined-pl-v1" */
  '@/components/steps/foundation/PaymentSummary'
)

const ApplicationSuccessful = () => import(
  /* webpackChunkName: "foundation-declined-pl-v1" */
  '@/components/steps/foundation/ApplicationSuccessful'
)

const Sorry = () => import(
  /* webpackChunkName: "foundation-declined-pl-v1" */
  '@/components/steps/common/Sorry'
)

const WelcomeBack = () => import(
  /* webpackChunkName: "foundation-declined-pl-v1" */
  '@/components/steps/foundation/WelcomeBack'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */
const appName = 'springloan-usl'
const leadType = LEAD_TYPE.PL_DECLINED
const variation = 'v1'
const basePath = ROUTE_PATHS.PL_DECLINED

const props = {
  eventPrefix: 'declined-pl',
  canonicalModule: 'try-foundation'
}

const routes = [
  {
    path: basePath,
    component: AppShellLoans,
    meta: {
      basePath,
      appName,
      leadType,
      variation
    },
    props: {
      ...props,
      theme: 'blue',
      module: 'personal-loans'

    },
    beforeEnter: async (to, from, next) => {
      const nextPath = store.getters.isFoundationEligibleOnly ? basePath : '/foundation'

      // Push the event if the user is only eligible for Foundation.
      if (nextPath === basePath) {
        DataLayer.pushObject({ event: 'try-foundation-flow-entered' }, true)
        window.rudderanalytics.track('try-foundation-flow-entered')
      }

      const whitelist = [ROUTE_PATHS.APPLICATION_SUCCESS, ROUTE_PATHS.WELCOME_BACK]
      preventRefresh(to, from, next, nextPath, whitelist)
      store.dispatch('setSubmitIfReady', false)
    },
    children: [
      {
        path: '',
        component: EducationIntro,
        meta: {
          index: 1,
          nextStep: ROUTE_PATHS.PLAN_SELECTION,
          progress: false,
          disableGridLayout: true
        },
        props: {
          skipUpdateLead: true,
          faq,
          foundationBloomFAQ,
          ...props
        }
      }, {
        path: ROUTE_PATHS.PLAN_SELECTION,
        component: PlanSelection,
        meta: {
          index: 2,
          nextStep: ROUTE_PATHS.PAYMENT_SUMMARY,
          progressLabel: 'Step 1 of 3',
          showFooter: true,
          showAppButton: true,
          disableGridLayout: true,
          dynamicContinueText: true
        },
        props: {
          ...props,
          title: 'The Foundation',
          faq,
          foundationBloomFAQ,
          skipUpdateLead: true,
          foundationDeclined: true
        }
      }, {
        path: ROUTE_PATHS.PAYMENT_SUMMARY,
        component: PaymentSummary,
        meta: {
          index: 3,
          nextStep: ROUTE_PATHS.APPLICATION_SUCCESS,
          backStep: ROUTE_PATHS.PLAN_SELECTION,
          progressLabel: 'Step 2 of 3',
          showFooter: true,
          continueText: 'Finish',
          showBackButton: true,
          showAppButton: true
        },
        props: {
          ...props,
          faq,
          foundationBloomFAQ,
          skipUpdateLead: true,
          foundationDeclined: true
        }
      }, {
        path: ROUTE_PATHS.APPLICATION_SUCCESS,
        component: ApplicationSuccessful,
        meta: {
          index: 11,
          progressLabel: PROGRESS_LABELS.FINISHED,
          disableGridLayout: true,
          showFooter: false,
          showSkipStep: false,
          foundationDeclined: true
        }
      }, {
        path: ROUTE_PATHS.SORRY,
        component: Sorry,
        meta: {
          progress: false
        },
        props: {
          ...props,
          sorryMessage: 'Currently The Foundation is not available in your province.',
          displaySorry: true
        }
      }, {
        path: ROUTE_PATHS.WELCOME_BACK,
        component: WelcomeBack,
        meta: {
          progress: false
        },
        props: {
          ...props
        }
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
