// for preventing Cx from navigating directly to a certain path, unless that path is whitelisted
const preventRefresh = (to, from, next, basePath, whitelist = []) => {
  if (!process.env.VUE_APP_DISABLE_PREVENT_REFRESH) {
    const wasRefreshed = window.performance && window.performance.navigation.type === 1
    const isBasePath = to.path === basePath
    const isWhitelisted = Array.isArray(whitelist) &&
      whitelist.some((route) => to.path === `${basePath}/${route}`)
    if (!isBasePath && !isValidFollowUp(from) && (wasRefreshed || !isWhitelisted)) {
      next({ path: basePath, query: { ...to.query } })
    }
  }
  next({ query: { ...to.query } })
}

const isValidFollowUp = (from) => {
  const isValidPath = (from.path === '/followup' && from.query?.id && from.query?.product) || (from.path === '/quiz/5' || (from.path === '/pre-populate') || (from.path === '/personal-loans/contact-code') || (from.path === '/try-foundation') || (from.path === '/try-foundation/welcome-back') || (from.path === '/try-foundation/application-success'))
  return isValidPath
}

export default preventRefresh
