export default {
  namespaced: true,

  state: {
    is_chat_open: false
  },

  getters: {},

  mutations: {
    SET_CHAT_OPEN (state, isChatOpen) {
      state.is_chat_open = isChatOpen
    }
  },

  actions: {
    setChatOpen ({ commit }, isChatOpen) {
      commit('SET_CHAT_OPEN', isChatOpen)
    }
  }
}
