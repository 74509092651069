/**
 * Navigation Mixin
 * @desc Handles logic for navigating between steps of an application
 */
import scroll from '@/mixins/scroll'
import DataLayer from '@/utilities/data-layer'
import * as ROUTE_PATHS from '@/constants/route-paths'
import validations from '@/mixins/validations'

export default {
  // Mixins
  mixins: [scroll, validations],

  // Data
  data () {
    return {
      submitted: false,
      skipStep: false
    }
  },

  props: {
    eventPrefix: {
      type: String,
      validator (value) {
        return ['quiz', 'ploan', 'foundation', 'mortgage', 'declined-pl', 'foundation-grad', 'evergreen-agreement', 'foundation-agreement'].includes(value)
      },
      default: 'ploan'
    }
  },

  // Computed
  computed: {
    $_navigation_basePath () {
      return this.$route.matched[0].meta.basePath
    },

    $_navigation_nextPath () {
      const nextStep = typeof this.$route.meta.nextStep === 'function'
        ? this.$route.meta.nextStep()
        : this.$route.meta.nextStep
      return `${this.$_navigation_basePath}/${nextStep}`
    },

    $_navigation_backPath () {
      const backStep = typeof this.$route.meta.backStep === 'function'
        ? this.$route.meta.backStep()
        : this.$route.meta.backStep
      return `${this.$_navigation_basePath}/${backStep}`
    },

    $_navigation_docsUploadedQuery () {
      return {
        du: this.$store.getters.docsUploadCount
      }
    },

    $_navigation_isValidNextStep () {
      return (!this.$route?.meta?.nextStep &&
        this.$route?.meta?.nextStep !== '')
    },

    $_navigation_triggerContinue () {
      return this.$store.state.appShellButton.triggerContinue
    },

    $_navigation_triggerSkip () {
      return this.$store.state.appShellButton.triggerSkipStep
    },

    $_navigation_triggerSubmitNow () {
      return this.$store.state.appShellButton.triggerSubmitNow
    },

    $_navigation_isMortgage () {
      return this.$store.getters['loans/isMortgage']
    },

    $_navigation_shouldRedirectToPLDeclined () {
      const isBankVerified = this.$store.state.documents.bankStatements
      const currentPath = this.$route.matched[0].path

      if (
        currentPath.includes(ROUTE_PATHS.PL_DECLINED) ||
        !isBankVerified
      ) {
        return false
      }

      return this.$store.getters.isFoundationEligibleOnly
    },

    financingType () {
      return this.$store.state.tracking.financing_type
    },

    leadAPIUrl () {
      return this.$_navigation_isMortgage ? process.env.VUE_APP_LEAD_API_BASE_URL : process.env.VUE_APP_LEAD_API_PERSONAL_LOANS_BASE_URL
    },

    returningCustomer () {
      return this.$store.state.returningCustomer.is_in_progress
    },

    cduid () {
      return this.$store.state.application.cduid
    },

    dateTime () {
      return this.$store.state.application.dateTime
    },

    sessionid () {
      return this.$store.state.application.sessionId
    },

    skippedStep () {
      return this.$store.state.application.skippedStep
    },

    incomeFrequency () {
      return this.$store.state.employment.income_frequency
    },

    locationState () {
      return this.$store.state.location
    },

    addressFallback () {
      return this.locationState.addressFallback
    }
  },

  // Methods
  methods: {
    /**
     * Handle Next
     */
    async $_navigation_handleNext (dataLayerObject, skipStep = false) {
      try {
        this.submitted = true
        this.skipStep = skipStep

        const isValid = await this.$_navigation_validateStep()
        if (!isValid) throw new Error('Validation failed.')

        if (this.$_navigation_isValidNextStep) throw new Error('\'nextPath\' not set in route.')
        if (dataLayerObject) this.$_navigation_isMortgage ? DataLayer.pushObject({ ...dataLayerObject, mortgage_type: this.financingType }) : DataLayer.pushObject(dataLayerObject)

        const { query } = this.$route
        if (query) delete query.financing_type
        this.handleScreenTimeDataLayer()
        this.$router.push({
          path: this.$_navigation_nextPath,
          query: { ...query, ...this.$_navigation_docsUploadedQuery }
        })
        return true
      } catch (error) {
        console.warn(error)
      }
    },

    /**
     * Validate Step
     */
    async $_navigation_validateStep () {
      const path = this.$route.path
      if (this.skipStep) return true
      switch (path) {
        case '/personal-loans':
        case '/personal-loans/':
          return this.validateEmail()
        case '/personal-loans/name':
          return this.validateFirstName() && this.validateLastName()
        case '/personal-loans/income-details-amount':
        case '/foundation/income-details-amount':
          if (this.incomeFrequency === 'Monthly') {
            return this.validateMonthlyIncome()
          } else if (this.incomeFrequency === 'Annual') {
            return this.validateAnnualIncome()
          } else if (this.incomeFrequency === 'Hourly') {
            return this.validateHourlyIncome() && this.validateHoursPerWeek()
          } else {
            return false
          }
        case '/personal-loans/income-duration':
        case '/foundation/income-duration':
          return this.validateEmployerYears() && this.validateEmployerMonths()
        case '/personal-loans/home-address':
        case '/foundation/home-address':
        case '/mortgage/home-address':
          return this.addressFallback ? this.validateAddressFallback() : this.validateCpost()
        case '/personal-loans/date-of-birth':
        case '/foundation/date-of-birth':
        case '/mortgage/date-of-birth':
          return this.validateDateOfBirth()
        case '/personal-loans/contact-phone':
        case '/foundation/contact-phone':
          return this.validatePhoneNumber()
        case '/personal-loans/contact-code':
        case '/foundation/contact-code':
          return this.validatePhoneCode()
        case '/personal-loans/verify-identity':
          return this.validateIdentityFile()
        case '/personal-loans/verify-address':
          return this.validateAddressFile()
        case '/personal-loans/verify-income':
          return this.validateIncomeFile1() || this.validateIncomeFile2()
        case '/foundation':
        case '/foundation/':
        case '/quiz/4':
          return this.validateFirstName() && this.validateLastName() && this.validateEmail()

        case '/mortgage/home-value':
          return this.validateHomeValue()

        case '/mortgage/amount-owing':
          return this.validateAmountOwing()
        default:
          return true
      }
    },

    $_navigation_handlePLDeclined () {
      this.handleScreenTimeDataLayer()
      this.$store.dispatch('setIsBloomFlow')

      this.$router.push({
        path: ROUTE_PATHS.PL_DECLINED
      })
    },

    $_navigation_handleSorry () {
      this.handleScreenTimeDataLayer()
      this.$router.push({
        path: ROUTE_PATHS.SORRY
      })
    },

    handleScreenTimeDataLayer () {
      const date = new Date()
      const seconds = Math.round(date / 1000) - Math.round(this.dateTime / 1000)
      const timestamp =
      `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}.${date.getUTCMilliseconds()}`
      const dataLayerObject = {
        event: 'spring_screen_time',
        cduid: this.cduid,
        path: this.$route.path,
        seconds,
        returning_customer: this.returningCustomer,
        skipped_step: this.skippedStep,
        session_id: this.sessionid,
        timestamp
      }
      DataLayer.pushObject(dataLayerObject, true)
      this.$store.dispatch('setSkippedStep', false)
    },

    confirmPageBounce (event) {
      if (this.applicationStarted && process.env.VUE_APP_ENABLE_BOUNCE_CONFIRMATION) {
        event.preventDefault(); // required for firefox/safari desktop
        (event || window.event).returnValue = ''
      }
    }
  }
}
