<template>
  <div>
    <div class="app-simple-footer container">
      <p class="app-simple-footer__address">
        Spring Financial <br>
        Suite 600, Two Bentall Center, 555 Burrard Street, Vancouver, British
        Columbia, Canada
      </p>
      <div>
        <a
          v-for="(item) in social"
          :key="item.title"
          :href="item.link"
          target="_blank"
        >
          <font-awesome-icon
            v-if="item.icon"
            :icon="['fab', item.icon]"
            class="text-gray-350 mr-20 sm:mr-50 hover:text-purple-500 duration-300 cursor-pointer"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import theme from '@/mixins/theme'
import tracking from '@/mixins/tracking'

export default {
  // Name
  name: 'AppSimpleFooter',

  // Mixins
  mixins: [
    theme,
    tracking
  ],

  data () {
    return {
      social: [
        {
          title: 'Facebook',
          icon: 'facebook-f',
          link: '//facebook.com/springfinancial/'
        },
        {
          title: 'Instagram',
          icon: 'instagram',
          link: '//instagram.com/springfinancial.ca/'
        },
        {
          title: 'LinkedIn',
          icon: 'linkedin-in',
          link: '//linkedin.com/company/spring-financial'
        },
        {
          title: 'TikTok',
          icon: 'tiktok',
          link: '//tiktok.com/@springfinancial/'
        }
      ]
    }
  }

}
</script>

<style scoped lang="scss">
.app-simple-footer {
  @apply pb-60;

  color: #121212;

  @screen sm {
    @apply flex flex-row justify-between items-center;
  }

  &__address {
    @apply text-gray-350 leading-1 mb-20 font-light max-w-350;

    opacity: 0.25;

    @screen sm {
      @apply mb-0 text-xs;
    }
  }
}
</style>
